/**
 * Jini.Guru Technologies : BuzzBox ReST API
 * This is a a ReSTful web service using JSON as the data transfer model. The service covers all the functionality used by BuzzBox itself as well as some intended for external parties to integrate.
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Network } from '../model/network';
import { NetworkCatalog } from '../model/networkCatalog';
import { Recharge } from '../model/recharge';
import { RechargeHistoryRecord } from '../model/rechargeHistoryRecord';
import { RechargeResult } from '../model/rechargeResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MobileRechargeService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * This call preforms a recharge.
     * If provide valid recipientMsisdn, voucherId and amount it will perform a recharge
     * @param e164 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createRecharge(e164: number, body?: Recharge, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<RechargeResult>;
    public createRecharge(e164: number, body?: Recharge, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<RechargeResult>>;
    public createRecharge(e164: number, body?: Recharge, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<RechargeResult>>;
    public createRecharge(e164: number, body?: Recharge, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (e164 === null || e164 === undefined) {
            throw new Error('Required parameter e164 was null or undefined when calling createRecharge.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<RechargeResult>(`${this.basePath}/rest/v1/mobile-recharges/${encodeURIComponent(String(e164))}/recharges`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve network catalogs.
     * This call returns all the networks and their voucher catalogs.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCatalogs(customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<NetworkCatalog>>;
    public getCatalogs(customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<NetworkCatalog>>>;
    public getCatalogs(customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<NetworkCatalog>>>;
    public getCatalogs(customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<NetworkCatalog>>(`${this.basePath}/rest/v1/mobile-recharges/catalogs`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve network information for a msisdn in e164 format.
     * This call returns the network and network id that will be used in getting the voucher type and vouchers available.
     * @param e164 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNetwork(e164: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Network>>;
    public getNetwork(e164: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Network>>>;
    public getNetwork(e164: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Network>>>;
    public getNetwork(e164: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (e164 === null || e164 === undefined) {
            throw new Error('Required parameter e164 was null or undefined when calling getNetwork.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Network>>(`${this.basePath}/rest/v1/mobile-recharges/${encodeURIComponent(String(e164))}/network`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve recharge history.
     * This call returns the history for a phone number.
     * @param e164 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRechargeHistoryForE164(e164: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<RechargeHistoryRecord>>;
    public getRechargeHistoryForE164(e164: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<RechargeHistoryRecord>>>;
    public getRechargeHistoryForE164(e164: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<RechargeHistoryRecord>>>;
    public getRechargeHistoryForE164(e164: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (e164 === null || e164 === undefined) {
            throw new Error('Required parameter e164 was null or undefined when calling getRechargeHistoryForE164.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<RechargeHistoryRecord>>(`${this.basePath}/rest/v1/mobile-recharges/${encodeURIComponent(String(e164))}/recharges`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

 }
