/**
 * Jini.Guru Technologies : BuzzBox ReST API
 * This is a a ReSTful web service using JSON as the data transfer model. The service covers all the functionality used by BuzzBox itself as well as some intended for external parties to integrate.
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type CreditNoteStatus = 'DRAFT' | 'PROCESSED';

export const CreditNoteStatus = {
    DRAFT: 'DRAFT' as CreditNoteStatus,
    PROCESSED: 'PROCESSED' as CreditNoteStatus
};