
import {take, filter,  switchMap ,  catchError, finalize } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable ,  BehaviorSubject, throwError } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { LoggerService } from '../services/logger/logger.service';
import { PropertyService } from '../services/property/property.service';
import { environment } from '../../environments/environment';
import { Store } from '@ngxs/store';
import { RefreshAuthToken, Signout } from '../app-state-model';
import { Router } from '@angular/router';

@Injectable( {providedIn: 'root'} )
export class JGJWTInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  // isRefreshingToken: boolean = false;
  // tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  urlsToIgnoreJwt: string = environment.urlsToIgnoreJwt;

  constructor(
    private _store: Store,
    private _router: Router,
    private logger: LoggerService,
    private propertyService: PropertyService
    ) {
    logger.info('starting interceptor');
    this.propertyService.propertiesChanged$.subscribe((updated: boolean) => {
      if (updated === true) {
        this.propertyService.getBootstrapProperties().subscribe((data: any) => {
          logger.info('received props', data);
          if (data.urlsToIgnoreJwt) {
            logger.info('updating urls to ignore for jwt to', data.urlsToIgnoreJwt);
            this.urlsToIgnoreJwt = this.urlsToIgnoreJwt + '|' + data.urlsToIgnoreJwt;
          }
        })
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.search(this.urlsToIgnoreJwt) > 0) {
      this.logger.info('can ignore jwt for url', req.url);
      return next.handle(req);
    } else {
      let modifiedRequest = this.addAuthenticationToken(req);
      return next.handle(modifiedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              this.logger.info('Received 401 - Too Late for refreshing token', error);
              this.refreshTokenInProgress = false;
              this._router.navigateByUrl('/auth/login');
              return throwError(error);
            }else{
                if (error.error) {
                    let errors: any[] = error.error;
                    if (errors.length > 0) {
                        let ukhesheError: any = errors[0];
                        this.logger.info("error is: ", ukhesheError);
                        if (ukhesheError && ukhesheError.code == "IAE001" && (ukhesheError.description === 'JWT has expired' || ukhesheError.description === 'JWT verification failed')) {
                          this.logger.info('JWT has expired');
                            if (this.refreshTokenInProgress) {
                                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                                // which means the new token is ready and we can retry the request again
                                return this.refreshTokenSubject.pipe(
                                    filter(result => result !== null),
                                    take(1),
                                    switchMap(() => next.handle(this.addAuthenticationToken(modifiedRequest)))
                                );
                            } else {
                                this.refreshTokenInProgress = true;

                                // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                                this.refreshTokenSubject.next(null);

                                // Call auth.refreshAccessToken(this is an Observable that will be returned)

                                return this._store.dispatch(new RefreshAuthToken(this._store.selectSnapshot(state => state.global.token))).pipe(
                                    switchMap((token: any) => {
                                        //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                                        // for the next time the token needs to be refreshed
                                        this.refreshTokenInProgress = false;
                                        this.refreshTokenSubject.next(token);

                                        return next.handle(this.addAuthenticationToken(modifiedRequest));
                                    }),
                                    catchError((error) => {
                                        this.refreshTokenInProgress = false;
                                        this._router.navigateByUrl('/auth/login');
                                        return Observable.throw(error);
                                    }));
                            }
                        }
                    }
                }
                return throwError(error);
            }
        })
    );
      // return this.authService.getToken()
      //   .pipe(
      //     switchMap((token: JGAuthJWTToken) => {
      //       if (token && token.token) {
      //         // this.logger.info('token is', token);
      //         req = this.addToken(req, token.getValue());
      //       }
      //       return next.handle(req).pipe(

      //         catchError(error => {
      //           if (error instanceof HttpErrorResponse) {
      //             this.logger.error('Received error', error);
      //             switch ((<HttpErrorResponse>error).status) {
      //               case 401:
      //                 this.logger.info('received 401');
      //                 // this.toasterService.error('UnAuthorized , Contact your administrator.');
      //                 return this.handle401Error(req, next, token.getValue());
      //               case 404:
      //                 this.logger.info('received 404');
      //                 return next.handle(req);
      //             }
      //             return ErrorObservable.create('error');
      //           } else {
      //             // this.toasterService.error('Contact your administrator.');
      //             return ErrorObservable.create('error');
      //           }
      //         }),
      //       )
      //     }),
      // );
    }
  }

  addAuthenticationToken(request: any): HttpRequest<any> {
    const token = this._store.selectSnapshot(state => state.global.token);

    // Clone the request to add the new header.
    const modifiedRequest = request.clone({
        headers: request.headers.set('authorization', token ? token : '')
    });

    return modifiedRequest;
}

  // addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
  //   const JWT = `Bearer ${token}`;
  //   return req.clone({
  //     setHeaders: {
  //       Authorization: JWT,
  //     },
  //   });
  // }

  // protected get authService(): AuthService {
  //   return this.injector.get(AuthService);
  // }

  logoutUser() {
    // Route to the login page (implementation up to you)
    this.logger.info('TODO: need to log user out');
    return ErrorObservable.create('');
  }

  // handle401Error(req: HttpRequest<any>, next: HttpHandler, currentToken: string): Observable<HttpEvent<any>> {
  //   if (!this.isRefreshingToken) {
  //     this.isRefreshingToken = true;
  //     this.logger.info('Going to try and refresh token');
  //     return this.authService.refreshToken(currentToken).pipe(
  //       switchMap((newToken: string) => {
  //         this.logger.info('newToken is :', newToken);
  //         if (newToken) {
  //           this.tokenSubject.next(newToken);
  //           return next.handle(this.addToken(req, newToken));
  //         }

  //         this.logger.info('new token must be null');
  //         // If we don't get a new token, we are in trouble so logout.
  //         this.logoutUser();
  //         return next.handle(req);

  //       }),
  //       catchError(error => {
  //         // If there is an exception calling 'refreshToken', bad news so logout.
  //         this.logger.error('received error trying to refresh token', error);
  //         // this.toasterService.error('Error trying to refresh token');
  //         this.logoutUser();
  //         return next.handle(req);
  //       }),
  //       finalize(() => {
  //         this.isRefreshingToken = false;
  //       }),
  //     );
  //   } else {
  //     this.logger.info('already refreshing token');
  //     return this.tokenSubject.pipe(
  //       filter(token => token != null),
  //       take(1),
  //       switchMap(token => {
  //         return next.handle(this.addToken(req, token));
  //       }),);
  //   }
  // }
}
