import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { tap ,  catchError } from 'rxjs/operators';
import { of as observableOf ,  Observable } from 'rxjs';
import { LoggerService } from '../services/logger/logger.service';
import { Store } from '@ngxs/store';
import { Signout } from '../app-state-model';

@Injectable()
export class CleanGuard implements CanActivate {

  constructor(
    private logger: LoggerService,
    private store: Store,
    ) {
  }

  canActivate(): Observable<boolean> {
    this.logger.info('clean guard called');
    this.store.dispatch(new Signout());
    return observableOf(true);
  }
}
