/**
 * Jini.Guru Technologies : BuzzBox ReST API
 * This is a a ReSTful web service using JSON as the data transfer model. The service covers all the functionality used by BuzzBox itself as well as some intended for external parties to integrate.
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TopUpStatus = 'ERROR_PERM' | 'ERROR_TEMP' | 'INCOMING' | 'INVOICED' | 'PENDING' | 'PUBLISHED' | 'QUOTE' | 'SUCCESSFUL' | 'TIMEOUT' | 'UPDATING';

export const TopUpStatus = {
    ERRORPERM: 'ERROR_PERM' as TopUpStatus,
    ERRORTEMP: 'ERROR_TEMP' as TopUpStatus,
    INCOMING: 'INCOMING' as TopUpStatus,
    INVOICED: 'INVOICED' as TopUpStatus,
    PENDING: 'PENDING' as TopUpStatus,
    PUBLISHED: 'PUBLISHED' as TopUpStatus,
    QUOTE: 'QUOTE' as TopUpStatus,
    SUCCESSFUL: 'SUCCESSFUL' as TopUpStatus,
    TIMEOUT: 'TIMEOUT' as TopUpStatus,
    UPDATING: 'UPDATING' as TopUpStatus
};