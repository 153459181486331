import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { Store, Select, Actions, ofActionDispatched } from '@ngxs/store';
import { takeUntil, tap, takeWhile, switchMap, concatMap, catchError, filter, retryWhen, combineLatest, withLatestFrom, map } from 'rxjs/operators';
import { Subject, Observable, Subscription, of, interval, timer } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ConnectWebSocket, DisconnectWebSocket, WebsocketMessageError } from '@ngxs/websocket-plugin';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnDestroy {
  private currentSessionId: string = null;
  private destroy$: Subject<void> = new Subject<void>();
  private stopRetry$: Subject<void> = new Subject<void>();
  private mustDoWebsocketConnect$: Subject<void> = new Subject<void>();
  private websocketSessionIdSub: Subscription;
  private websocketConnectedSub: Subscription;

  @Select(state => state.global.wsSessionId) websocketSessionId$: Observable<string>;
  @Select(state => state.global.websocketConnected) websocketConnected$: Observable<boolean>;
  
  constructor(
    private logger: LoggerService,
    private store: Store,
    private actions$: Actions
  ) {
    this.logger.info('Websocket service instantiated');
    // this.websocketSessionIdSub = this.websocketSessionId$.pipe(
    //   takeUntil(this.destroy$),
    //   concatMap(sessionId => {
    //     this.disconnectWebsocketConnection().pipe(
    //       catchError(error => error)
    //     );
    //     if (sessionId === null) {
    //       this.stopRetry$.next();
    //     }
    //     return of(sessionId);
    //   }),
    //   filter(sessionId => (!isNullOrUndefined(sessionId) && sessionId.length > 0)),
    //   switchMap(sessionId => {
    //     return timer(0, 10000).pipe(
    //       tap(() => this.logger.info('websocket timer fired')),
    //       takeUntil(this.stopRetry$),
    //       withLatestFrom(this.websocketConnected$, this.websocketConnected$),
    //       concatMap(([_, connected, latestState]) => {
    //         if (latestState === true) {
    //           this.stopRetry$.next();
    //         }
    //         return of(connected);
    //       }),
    //       filter((connected:boolean) => connected !== true),
    //       tap(() => this.logger.info('asked to make a websocket connection')),
    //       switchMap(() => this.initiateWebsocketConnection(sessionId)),
    //     );
    //   }),
    // ).subscribe(() => {
    //   this.logger.info('websocket in subscribe');
    // }, (error) => {
    //   this.logger.info('error on websocket subscription');
    // }, () => {
    //   this.logger.info('websocker observer complete');
    // });

    // // this.websocketConnectedSub = this.websocketConnected$.pipe(
    // //   takeUntil(this.destroy$),
    // //   tap((connected) => {
    // //     this.logger.info("websocket connected state is", connected);
    // //   })
    // // ).subscribe();

    // this.actions$
    //   .pipe(ofActionDispatched(WebsocketMessageError))
    //   .subscribe(({ payload }) => {
    //     console.log('Got websocket message error: ', payload);
    //   });
  }

  ngOnDestroy(): void {
    this.logger.info('Websocket service destroyed');
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initiateWebsocketConnection(sessionId: string) {
    this.logger.info('Initiating websocket connection');
    return this.store.dispatch(new ConnectWebSocket({
      url: 'ws://localhost:8000/buzzbox-conductor/ws/' + sessionId,
    }));
  }

  private disconnectWebsocketConnection() {
    return this.store.dispatch(new DisconnectWebSocket());
  }

}
