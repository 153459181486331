import { AsyncValidatorFn, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS, AsyncValidator } from "@angular/forms";
import { Observable, of } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { Directive } from "@angular/core";
import { UserService } from '../../services/service-modules/buzzbox-services';

export function existingOrganisationValidator(userService: UserService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return userService.getOrganisations(null, null, null, control.value).pipe(
      switchMap(organisations => {
        let ret: any = {};
        ret.organisationTaken = false;
        if (organisations && organisations.length > 0) {
          ret.organisationTaken = true;
          return of(ret);
        }
        return of(null);
      }),
      catchError((err) => { return of({ "organisationTaken": true }) })
    );
  };
}

@Directive({
  selector: '[orgExists][formControlName],[orgExists][formControl],[orgExists][ngModel]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: ExistingOrganisationValidatorDirective, multi: true }]
})
export class ExistingOrganisationValidatorDirective implements AsyncValidator {
  constructor(private userService: UserService) { }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return existingOrganisationValidator(this.userService)(control);
  }
}
