/**
 * Jini.Guru Technologies : BuzzBox ReST API
 * This is a a ReSTful web service using JSON as the data transfer model. The service covers all the functionality used by BuzzBox itself as well as some intended for external parties to integrate.
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActiveCall } from '../model/activeCall';
import { AudioFile } from '../model/audioFile';
import { CallBlock } from '../model/callBlock';
import { CallRecording } from '../model/callRecording';
import { CallSetup } from '../model/callSetup';
import { Callcentre } from '../model/callcentre';
import { CallcentreAgent } from '../model/callcentreAgent';
import { CallcentreAgentGroup } from '../model/callcentreAgentGroup';
import { CallcentreAgentLoginData } from '../model/callcentreAgentLoginData';
import { CallcentreLiveAgent } from '../model/callcentreLiveAgent';
import { Campaign } from '../model/campaign';
import { CampaignAgent } from '../model/campaignAgent';
import { CampaignAgentEvent } from '../model/campaignAgentEvent';
import { CampaignCallSetup } from '../model/campaignCallSetup';
import { CampaignDialStatusRule } from '../model/campaignDialStatusRule';
import { CampaignEvent } from '../model/campaignEvent';
import { CampaignLead } from '../model/campaignLead';
import { CampaignLeadCallback } from '../model/campaignLeadCallback';
import { CampaignLeadDispositionInfo } from '../model/campaignLeadDispositionInfo';
import { CampaignLeadEvent } from '../model/campaignLeadEvent';
import { CampaignList } from '../model/campaignList';
import { CampaignListEvent } from '../model/campaignListEvent';
import { CampaignListStats } from '../model/campaignListStats';
import { CampaignStats } from '../model/campaignStats';
import { Conference } from '../model/conference';
import { Contact } from '../model/contact';
import { DialPlan } from '../model/dialPlan';
import { DomainSetting } from '../model/domainSetting';
import { EavesDropSetup } from '../model/eavesDropSetup';
import { ExceptionData } from '../model/exceptionData';
import { IvrMenu } from '../model/ivrMenu';
import { LeadStatus } from '../model/leadStatus';
import { PabxContact } from '../model/pabxContact';
import { PabxContactStats } from '../model/pabxContactStats';
import { PabxContactStatus } from '../model/pabxContactStatus';
import { PabxOrganisation } from '../model/pabxOrganisation';
import { PinNumber } from '../model/pinNumber';
import { PstnContact } from '../model/pstnContact';
import { PusherAuthResponseData } from '../model/pusherAuthResponseData';
import { RingGroup } from '../model/ringGroup';
import { RouteableApp } from '../model/routeableApp';
import { TimeSchedule } from '../model/timeSchedule';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PabxService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Login to a campaign by an agent.
     * This API will allow an agent to login to this campaign.
     * @param organisationId 
     * @param campaignId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public agentLogin(organisationId: number, campaignId: string, body?: CallcentreAgentLoginData, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public agentLogin(organisationId: number, campaignId: string, body?: CallcentreAgentLoginData, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public agentLogin(organisationId: number, campaignId: string, body?: CallcentreAgentLoginData, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public agentLogin(organisationId: number, campaignId: string, body?: CallcentreAgentLoginData, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling agentLogin.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling agentLogin.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/agent-login`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logout of a campaign by an agent.
     * This API will allow an agent to logout of the campaign.
     * @param organisationId 
     * @param campaignId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public agentLogout(organisationId: number, campaignId: string, body?: CallcentreAgentLoginData, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public agentLogout(organisationId: number, campaignId: string, body?: CallcentreAgentLoginData, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public agentLogout(organisationId: number, campaignId: string, body?: CallcentreAgentLoginData, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public agentLogout(organisationId: number, campaignId: string, body?: CallcentreAgentLoginData, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling agentLogout.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling agentLogout.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/agent-logoff`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Assign an agent group to a campaign.
     * This API will assign an agent group to a campaign.
     * @param organisationId 
     * @param campaignId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignAgentGroupToCampaign(organisationId: number, campaignId: string, body?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public assignAgentGroupToCampaign(organisationId: number, campaignId: string, body?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public assignAgentGroupToCampaign(organisationId: number, campaignId: string, body?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public assignAgentGroupToCampaign(organisationId: number, campaignId: string, body?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling assignAgentGroupToCampaign.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling assignAgentGroupToCampaign.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/assign-group`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Assign a list to a campaign.
     * This API will assign a list to a campaign.
     * @param organisationId 
     * @param campaignId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignListToCampaign(organisationId: number, campaignId: string, body?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public assignListToCampaign(organisationId: number, campaignId: string, body?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public assignListToCampaign(organisationId: number, campaignId: string, body?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public assignListToCampaign(organisationId: number, campaignId: string, body?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling assignListToCampaign.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling assignListToCampaign.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/assign-list`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add AudioFile to the Organisation
     * This API will add a AudioFile.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAudioFile(organisationId: number, body?: AudioFile, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<AudioFile>;
    public createAudioFile(organisationId: number, body?: AudioFile, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<AudioFile>>;
    public createAudioFile(organisationId: number, body?: AudioFile, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<AudioFile>>;
    public createAudioFile(organisationId: number, body?: AudioFile, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createAudioFile.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<AudioFile>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/audio-files`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new Lead
     * This API will Add a new Campaign agent.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCallcenterAgent(organisationId: number, body?: CampaignAgent, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CallcentreAgent>;
    public createCallcenterAgent(organisationId: number, body?: CampaignAgent, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CallcentreAgent>>;
    public createCallcenterAgent(organisationId: number, body?: CampaignAgent, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CallcentreAgent>>;
    public createCallcenterAgent(organisationId: number, body?: CampaignAgent, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createCallcenterAgent.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<CallcentreAgent>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcenter-agents`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new agent group
     * This API will Add a new Campaign agent group.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCallcenterAgentGroup(organisationId: number, body?: CallcentreAgentGroup, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CallcentreAgentGroup>;
    public createCallcenterAgentGroup(organisationId: number, body?: CallcentreAgentGroup, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CallcentreAgentGroup>>;
    public createCallcenterAgentGroup(organisationId: number, body?: CallcentreAgentGroup, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CallcentreAgentGroup>>;
    public createCallcenterAgentGroup(organisationId: number, body?: CallcentreAgentGroup, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createCallcenterAgentGroup.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<CallcentreAgentGroup>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcenter-groups`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new Campaigns
     * This API will Add a new Campaigns.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCampaign(organisationId: number, body?: Campaign, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Campaign>;
    public createCampaign(organisationId: number, body?: Campaign, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Campaign>>;
    public createCampaign(organisationId: number, body?: Campaign, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Campaign>>;
    public createCampaign(organisationId: number, body?: Campaign, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createCampaign.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Campaign>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new Lead
     * This API will Add a new CampaignLead.
     * @param organisationId 
     * @param campaignListId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCampaignListLead(organisationId: number, campaignListId: string, body?: CampaignLead, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<CampaignLead>>;
    public createCampaignListLead(organisationId: number, campaignListId: string, body?: CampaignLead, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<CampaignLead>>>;
    public createCampaignListLead(organisationId: number, campaignListId: string, body?: CampaignLead, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<CampaignLead>>>;
    public createCampaignListLead(organisationId: number, campaignListId: string, body?: CampaignLead, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createCampaignListLead.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling createCampaignListLead.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Array<CampaignLead>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}/leads`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new conference
     * This API will Add a new conference.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createConference(organisationId: number, body?: Conference, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Conference>;
    public createConference(organisationId: number, body?: Conference, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Conference>>;
    public createConference(organisationId: number, body?: Conference, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Conference>>;
    public createConference(organisationId: number, body?: Conference, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createConference.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Conference>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/conferences`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Domain Setting
     * This API will create a new domain setting.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDomainSetting(organisationId: number, body?: DomainSetting, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<DomainSetting>;
    public createDomainSetting(organisationId: number, body?: DomainSetting, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<DomainSetting>>;
    public createDomainSetting(organisationId: number, body?: DomainSetting, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<DomainSetting>>;
    public createDomainSetting(organisationId: number, body?: DomainSetting, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createDomainSetting.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<DomainSetting>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/settings`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Lead Status
     * This API will create a new lead status.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createLeadStatus(organisationId: number, body?: LeadStatus, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<LeadStatus>;
    public createLeadStatus(organisationId: number, body?: LeadStatus, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<LeadStatus>>;
    public createLeadStatus(organisationId: number, body?: LeadStatus, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<LeadStatus>>;
    public createLeadStatus(organisationId: number, body?: LeadStatus, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createLeadStatus.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<LeadStatus>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/lead-statuses`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Lead Status Dial Rule
     * This API will create a new lead status dial rule.
     * @param organisationId 
     * @param campaignId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createLeadStatusDialRule(organisationId: number, campaignId: string, body?: CampaignDialStatusRule, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignDialStatusRule>;
    public createLeadStatusDialRule(organisationId: number, campaignId: string, body?: CampaignDialStatusRule, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignDialStatusRule>>;
    public createLeadStatusDialRule(organisationId: number, campaignId: string, body?: CampaignDialStatusRule, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignDialStatusRule>>;
    public createLeadStatusDialRule(organisationId: number, campaignId: string, body?: CampaignDialStatusRule, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createLeadStatusDialRule.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling createLeadStatusDialRule.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<CampaignDialStatusRule>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/leadstatus-dial-rules`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create an Organisation
     * This API will create a new organisation.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisation(body?: PabxOrganisation, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxOrganisation>;
    public createOrganisation(body?: PabxOrganisation, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxOrganisation>>;
    public createOrganisation(body?: PabxOrganisation, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxOrganisation>>;
    public createOrganisation(body?: PabxOrganisation, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<PabxOrganisation>(`${this.basePath}/rest/v1/pabx-organisations`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new Call Block
     * This API will Add a new Call Block.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationCallBlock(organisationId: number, body?: CallBlock, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CallBlock>;
    public createOrganisationCallBlock(organisationId: number, body?: CallBlock, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CallBlock>>;
    public createOrganisationCallBlock(organisationId: number, body?: CallBlock, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CallBlock>>;
    public createOrganisationCallBlock(organisationId: number, body?: CallBlock, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createOrganisationCallBlock.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<CallBlock>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/call-blocking`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new Call Centre
     * This API will Add a new Call Centre.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationCallcentre(organisationId: number, body?: Callcentre, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Callcentre>;
    public createOrganisationCallcentre(organisationId: number, body?: Callcentre, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Callcentre>>;
    public createOrganisationCallcentre(organisationId: number, body?: Callcentre, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Callcentre>>;
    public createOrganisationCallcentre(organisationId: number, body?: Callcentre, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createOrganisationCallcentre.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Callcentre>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcentres`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new Campign List
     * This API will Add a new Campaign List.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationCampaignList(organisationId: number, body?: CampaignList, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignList>;
    public createOrganisationCampaignList(organisationId: number, body?: CampaignList, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignList>>;
    public createOrganisationCampaignList(organisationId: number, body?: CampaignList, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignList>>;
    public createOrganisationCampaignList(organisationId: number, body?: CampaignList, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createOrganisationCampaignList.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<CampaignList>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new Contact
     * This API will Add a new Contact.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationContact(organisationId: number, body?: Contact, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Contact>>;
    public createOrganisationContact(organisationId: number, body?: Contact, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Contact>>>;
    public createOrganisationContact(organisationId: number, body?: Contact, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Contact>>>;
    public createOrganisationContact(organisationId: number, body?: Contact, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createOrganisationContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Array<Contact>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/contacts`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add/Change a DialPlan
     * This API will Add a DialPlan. Can also be used for updating as an existing dialplan for the org will be removed first
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationDialPlan(organisationId: number, body?: DialPlan, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<DialPlan>>;
    public createOrganisationDialPlan(organisationId: number, body?: DialPlan, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<DialPlan>>>;
    public createOrganisationDialPlan(organisationId: number, body?: DialPlan, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<DialPlan>>>;
    public createOrganisationDialPlan(organisationId: number, body?: DialPlan, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createOrganisationDialPlan.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Array<DialPlan>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/dial-plans`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new IVR Menu
     * This API will Add a new IVR Menu.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationIvrMenu(organisationId: number, body?: IvrMenu, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<IvrMenu>>;
    public createOrganisationIvrMenu(organisationId: number, body?: IvrMenu, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<IvrMenu>>>;
    public createOrganisationIvrMenu(organisationId: number, body?: IvrMenu, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<IvrMenu>>>;
    public createOrganisationIvrMenu(organisationId: number, body?: IvrMenu, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createOrganisationIvrMenu.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Array<IvrMenu>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/ivr-menus`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new call recordings encryption keypair
     * This API will create a new keypair and email the private key and put the public key in S3.
     * @param organisationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationKeyPair(organisationId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public createOrganisationKeyPair(organisationId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public createOrganisationKeyPair(organisationId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public createOrganisationKeyPair(organisationId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createOrganisationKeyPair.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/call-recordings/keys`,
            null,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new pin number
     * This API will Add a new pin number.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationPinNumber(organisationId: number, body?: PinNumber, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PinNumber>;
    public createOrganisationPinNumber(organisationId: number, body?: PinNumber, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PinNumber>>;
    public createOrganisationPinNumber(organisationId: number, body?: PinNumber, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PinNumber>>;
    public createOrganisationPinNumber(organisationId: number, body?: PinNumber, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createOrganisationPinNumber.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<PinNumber>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pin-numbers`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add PstnContact to the Organisation
     * This API will add a PstnContact.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationPstnContact(organisationId: number, body?: PstnContact, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<PstnContact>>;
    public createOrganisationPstnContact(organisationId: number, body?: PstnContact, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<PstnContact>>>;
    public createOrganisationPstnContact(organisationId: number, body?: PstnContact, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<PstnContact>>>;
    public createOrganisationPstnContact(organisationId: number, body?: PstnContact, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createOrganisationPstnContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Array<PstnContact>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pstn-contacts`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new Ring Group
     * This API will Add a new Ring Group.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationRingGroup(organisationId: number, body?: RingGroup, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<RingGroup>;
    public createOrganisationRingGroup(organisationId: number, body?: RingGroup, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<RingGroup>>;
    public createOrganisationRingGroup(organisationId: number, body?: RingGroup, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<RingGroup>>;
    public createOrganisationRingGroup(organisationId: number, body?: RingGroup, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createOrganisationRingGroup.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<RingGroup>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/ring-groups`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add Schedule to the Organisation
     * This API will add a Schedule.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrganisationTimeSchedule(organisationId: number, body?: TimeSchedule, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<TimeSchedule>;
    public createOrganisationTimeSchedule(organisationId: number, body?: TimeSchedule, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<TimeSchedule>>;
    public createOrganisationTimeSchedule(organisationId: number, body?: TimeSchedule, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<TimeSchedule>>;
    public createOrganisationTimeSchedule(organisationId: number, body?: TimeSchedule, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createOrganisationTimeSchedule.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<TimeSchedule>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/schedules`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a PabxContact to the User
     * This API will Add a PabxContact.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPabxContact(organisationId: number, body?: PabxContact, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxContact>;
    public createPabxContact(organisationId: number, body?: PabxContact, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxContact>>;
    public createPabxContact(organisationId: number, body?: PabxContact, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxContact>>;
    public createPabxContact(organisationId: number, body?: PabxContact, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling createPabxContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<PabxContact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add bulk list of numbers
     * Add a bulk list of numbers.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPstnContacts(body?: Array<PstnContact>, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public createPstnContacts(body?: Array<PstnContact>, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public createPstnContacts(body?: Array<PstnContact>, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public createPstnContacts(body?: Array<PstnContact>, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/numbers/bulk`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the AudioFile
     * This API will delete a AudioFile.
     * @param organisationId 
     * @param uuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAudioFile(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<AudioFile>;
    public deleteAudioFile(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<AudioFile>>;
    public deleteAudioFile(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<AudioFile>>;
    public deleteAudioFile(organisationId: number, uuid: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteAudioFile.');
        }
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling deleteAudioFile.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<AudioFile>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/audio-files/${encodeURIComponent(String(uuid))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the call by uuid
     * This API will delete a call (hangup).
     * @param organisationId 
     * @param callId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCall(organisationId: number, callId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteCall(organisationId: number, callId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteCall(organisationId: number, callId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteCall(organisationId: number, callId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteCall.');
        }
        if (callId === null || callId === undefined) {
            throw new Error('Required parameter callId was null or undefined when calling deleteCall.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/calls/${encodeURIComponent(String(callId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the lead by uuid
     * This API will delete a campaign agent.
     * @param organisationId 
     * @param campaignAgentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCallcenterAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteCallcenterAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteCallcenterAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteCallcenterAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteCallcenterAgent.');
        }
        if (campaignAgentId === null || campaignAgentId === undefined) {
            throw new Error('Required parameter campaignAgentId was null or undefined when calling deleteCallcenterAgent.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcenter-agents/${encodeURIComponent(String(campaignAgentId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the lead by uuid
     * This API will delete a campaign agent group.
     * @param organisationId 
     * @param agentGroupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCallcenterAgentGroup(organisationId: number, agentGroupId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteCallcenterAgentGroup(organisationId: number, agentGroupId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteCallcenterAgentGroup(organisationId: number, agentGroupId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteCallcenterAgentGroup(organisationId: number, agentGroupId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteCallcenterAgentGroup.');
        }
        if (agentGroupId === null || agentGroupId === undefined) {
            throw new Error('Required parameter agentGroupId was null or undefined when calling deleteCallcenterAgentGroup.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcenter-groups/${encodeURIComponent(String(agentGroupId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Campaign
     * This API will delete Campaign.
     * @param organisationId 
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCampaign(organisationId: number, campaignId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteCampaign(organisationId: number, campaignId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteCampaign(organisationId: number, campaignId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteCampaign(organisationId: number, campaignId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteCampaign.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling deleteCampaign.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete CampaignList
     * This API will delete CampaignList.
     * @param organisationId 
     * @param campaignListId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCampaignList(organisationId: number, campaignListId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteCampaignList(organisationId: number, campaignListId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteCampaignList(organisationId: number, campaignListId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteCampaignList(organisationId: number, campaignListId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteCampaignList.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling deleteCampaignList.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the lead by uuid
     * This API will delete a lead.
     * @param organisationId 
     * @param campaignListId 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCampaignListLead(organisationId: number, campaignListId: string, leadId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteCampaignListLead(organisationId: number, campaignListId: string, leadId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteCampaignListLead(organisationId: number, campaignListId: string, leadId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteCampaignListLead(organisationId: number, campaignListId: string, leadId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteCampaignListLead.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling deleteCampaignListLead.');
        }
        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling deleteCampaignListLead.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}/leads/${encodeURIComponent(String(leadId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Contact
     * This API will delete Contact.
     * @param organisationId 
     * @param contactId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteContact(organisationId: number, contactId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteContact(organisationId: number, contactId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteContact(organisationId: number, contactId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteContact(organisationId: number, contactId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteContact.');
        }
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling deleteContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/contacts/${encodeURIComponent(String(contactId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Contact
     * This API will delete Contact.
     * @param organisationId 
     * @param pinNumberId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteContact_1(organisationId: number, pinNumberId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteContact_1(organisationId: number, pinNumberId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteContact_1(organisationId: number, pinNumberId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteContact_1(organisationId: number, pinNumberId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteContact_1.');
        }
        if (pinNumberId === null || pinNumberId === undefined) {
            throw new Error('Required parameter pinNumberId was null or undefined when calling deleteContact_1.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pin-numbers/${encodeURIComponent(String(pinNumberId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a domain setting by id
     * This API will delete a domain setting by id.
     * @param organisationId 
     * @param domainSettingId 
     * @param absolutelySure 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDomainSetting(organisationId: number, domainSettingId: string, absolutelySure?: boolean, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteDomainSetting(organisationId: number, domainSettingId: string, absolutelySure?: boolean, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteDomainSetting(organisationId: number, domainSettingId: string, absolutelySure?: boolean, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteDomainSetting(organisationId: number, domainSettingId: string, absolutelySure?: boolean, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteDomainSetting.');
        }
        if (domainSettingId === null || domainSettingId === undefined) {
            throw new Error('Required parameter domainSettingId was null or undefined when calling deleteDomainSetting.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (absolutelySure !== undefined && absolutelySure !== null) {
            queryParameters = queryParameters.set('absolutelySure', <any>absolutelySure);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/settings/${encodeURIComponent(String(domainSettingId))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a lead status by id
     * This API will delete a lead status by organisation id.
     * @param organisationId 
     * @param leadStatusId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteLeadStatus(organisationId: number, leadStatusId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteLeadStatus(organisationId: number, leadStatusId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteLeadStatus(organisationId: number, leadStatusId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteLeadStatus(organisationId: number, leadStatusId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteLeadStatus.');
        }
        if (leadStatusId === null || leadStatusId === undefined) {
            throw new Error('Required parameter leadStatusId was null or undefined when calling deleteLeadStatus.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/lead-statuses/${encodeURIComponent(String(leadStatusId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a lead status dial rule by id
     * This API will delete a lead status dial rule by id.
     * @param organisationId 
     * @param campaignId 
     * @param leadStatusDialRuleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteLeadStatusDialRule.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling deleteLeadStatusDialRule.');
        }
        if (leadStatusDialRuleId === null || leadStatusDialRuleId === undefined) {
            throw new Error('Required parameter leadStatusDialRuleId was null or undefined when calling deleteLeadStatusDialRule.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/leadstatus-dial-rules/${encodeURIComponent(String(leadStatusDialRuleId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an organisation by id
     * This API will delete an organisation by organisation id.
     * @param organisationId 
     * @param absolutelySure 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisation(organisationId: number, absolutelySure?: boolean, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteOrganisation(organisationId: number, absolutelySure?: boolean, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteOrganisation(organisationId: number, absolutelySure?: boolean, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteOrganisation(organisationId: number, absolutelySure?: boolean, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteOrganisation.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (absolutelySure !== undefined && absolutelySure !== null) {
            queryParameters = queryParameters.set('absolutelySure', <any>absolutelySure);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Call Block
     * This API will delete Call BLock.
     * @param organisationId 
     * @param callBlockId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisationsCallBlock(organisationId: number, callBlockId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteOrganisationsCallBlock(organisationId: number, callBlockId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteOrganisationsCallBlock(organisationId: number, callBlockId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteOrganisationsCallBlock(organisationId: number, callBlockId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteOrganisationsCallBlock.');
        }
        if (callBlockId === null || callBlockId === undefined) {
            throw new Error('Required parameter callBlockId was null or undefined when calling deleteOrganisationsCallBlock.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/call-blocking/${encodeURIComponent(String(callBlockId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Callcentre
     * This API will delete call centre.
     * @param organisationId 
     * @param callCentreId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisationsCallCentre(organisationId: number, callCentreId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteOrganisationsCallCentre(organisationId: number, callCentreId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteOrganisationsCallCentre(organisationId: number, callCentreId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteOrganisationsCallCentre(organisationId: number, callCentreId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteOrganisationsCallCentre.');
        }
        if (callCentreId === null || callCentreId === undefined) {
            throw new Error('Required parameter callCentreId was null or undefined when calling deleteOrganisationsCallCentre.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcentres/${encodeURIComponent(String(callCentreId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Callcentre
     * This API will delete call centre.
     * @param organisationId 
     * @param conferenceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisationsConference(organisationId: number, conferenceId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteOrganisationsConference(organisationId: number, conferenceId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteOrganisationsConference(organisationId: number, conferenceId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteOrganisationsConference(organisationId: number, conferenceId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteOrganisationsConference.');
        }
        if (conferenceId === null || conferenceId === undefined) {
            throw new Error('Required parameter conferenceId was null or undefined when calling deleteOrganisationsConference.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/conferences/${encodeURIComponent(String(conferenceId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the DialPlan by id
     * This API will delete a DialPlan.
     * @param organisationId 
     * @param dialPlanId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisationsDialPlan(organisationId: number, dialPlanId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteOrganisationsDialPlan(organisationId: number, dialPlanId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteOrganisationsDialPlan(organisationId: number, dialPlanId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteOrganisationsDialPlan(organisationId: number, dialPlanId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteOrganisationsDialPlan.');
        }
        if (dialPlanId === null || dialPlanId === undefined) {
            throw new Error('Required parameter dialPlanId was null or undefined when calling deleteOrganisationsDialPlan.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/dial-plans/${encodeURIComponent(String(dialPlanId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Ivr Menu
     * This API will delete Ivr Menu.
     * @param organisationId 
     * @param menuId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisationsIvrMenu(organisationId: number, menuId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteOrganisationsIvrMenu(organisationId: number, menuId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteOrganisationsIvrMenu(organisationId: number, menuId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteOrganisationsIvrMenu(organisationId: number, menuId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteOrganisationsIvrMenu.');
        }
        if (menuId === null || menuId === undefined) {
            throw new Error('Required parameter menuId was null or undefined when calling deleteOrganisationsIvrMenu.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/ivr-menus/${encodeURIComponent(String(menuId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the PstnContact
     * This API will delete a PstnContact.
     * @param organisationId 
     * @param e164 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisationsPstnContact(organisationId: number, e164: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PstnContact>;
    public deleteOrganisationsPstnContact(organisationId: number, e164: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PstnContact>>;
    public deleteOrganisationsPstnContact(organisationId: number, e164: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PstnContact>>;
    public deleteOrganisationsPstnContact(organisationId: number, e164: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteOrganisationsPstnContact.');
        }
        if (e164 === null || e164 === undefined) {
            throw new Error('Required parameter e164 was null or undefined when calling deleteOrganisationsPstnContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<PstnContact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pstn-contacts/${encodeURIComponent(String(e164))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Ring Group
     * This API will delete Ring Group.
     * @param organisationId 
     * @param ringGroupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisationsRingGroup(organisationId: number, ringGroupId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteOrganisationsRingGroup(organisationId: number, ringGroupId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteOrganisationsRingGroup(organisationId: number, ringGroupId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteOrganisationsRingGroup(organisationId: number, ringGroupId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteOrganisationsRingGroup.');
        }
        if (ringGroupId === null || ringGroupId === undefined) {
            throw new Error('Required parameter ringGroupId was null or undefined when calling deleteOrganisationsRingGroup.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/ring-groups/${encodeURIComponent(String(ringGroupId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the Schedule
     * This API will delete a Schedule.
     * @param organisationId 
     * @param scheduleUuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrganisationsSchedule(organisationId: number, scheduleUuid: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<TimeSchedule>>;
    public deleteOrganisationsSchedule(organisationId: number, scheduleUuid: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<TimeSchedule>>>;
    public deleteOrganisationsSchedule(organisationId: number, scheduleUuid: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<TimeSchedule>>>;
    public deleteOrganisationsSchedule(organisationId: number, scheduleUuid: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deleteOrganisationsSchedule.');
        }
        if (scheduleUuid === null || scheduleUuid === undefined) {
            throw new Error('Required parameter scheduleUuid was null or undefined when calling deleteOrganisationsSchedule.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<Array<TimeSchedule>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/schedules/${encodeURIComponent(String(scheduleUuid))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the PabxContact by extension for the User
     * This API will delete a PabxContact.
     * @param organisationId 
     * @param extension 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePabxContact(organisationId: number, extension: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deletePabxContact(organisationId: number, extension: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deletePabxContact(organisationId: number, extension: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deletePabxContact(organisationId: number, extension: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deletePabxContact.');
        }
        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling deletePabxContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(extension))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the PabxContact by extension for the User
     * This API will delete a PabxContact.
     * @param organisationId 
     * @param uuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePabxContact_2(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deletePabxContact_2(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deletePabxContact_2(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deletePabxContact_2(organisationId: number, uuid: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling deletePabxContact_2.');
        }
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling deletePabxContact_2.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(uuid))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initiate a call to a lead.
     * This API will initiate a call to a lead .
     * @param organisationId 
     * @param campaignId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dialLead(organisationId: number, campaignId: string, body?: CampaignCallSetup, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<CampaignCallSetup>>;
    public dialLead(organisationId: number, campaignId: string, body?: CampaignCallSetup, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<CampaignCallSetup>>>;
    public dialLead(organisationId: number, campaignId: string, body?: CampaignCallSetup, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<CampaignCallSetup>>>;
    public dialLead(organisationId: number, campaignId: string, body?: CampaignCallSetup, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling dialLead.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling dialLead.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Array<CampaignCallSetup>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/calls`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Process a campaign lead disposition
     * This API will process a campaign lead disposition.
     * @param organisationId 
     * @param campaignListId 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dispositionLead(organisationId: number, campaignListId: string, leadId: string, body?: CampaignLeadDispositionInfo, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignLead>;
    public dispositionLead(organisationId: number, campaignListId: string, leadId: string, body?: CampaignLeadDispositionInfo, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignLead>>;
    public dispositionLead(organisationId: number, campaignListId: string, leadId: string, body?: CampaignLeadDispositionInfo, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignLead>>;
    public dispositionLead(organisationId: number, campaignListId: string, leadId: string, body?: CampaignLeadDispositionInfo, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling dispositionLead.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling dispositionLead.');
        }
        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling dispositionLead.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<CampaignLead>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}/leads/${encodeURIComponent(String(leadId))}/disposition-lead`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get active calls for organisation
     * This API will return active calls for org.
     * @param organisationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveCalls(organisationId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<ActiveCall>>;
    public getActiveCalls(organisationId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<ActiveCall>>>;
    public getActiveCalls(organisationId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<ActiveCall>>>;
    public getActiveCalls(organisationId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getActiveCalls.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<ActiveCall>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/calls`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a AudioFile for the Organisation
     * This API will give AudioFile detail by id.
     * @param organisationId 
     * @param uuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAudioFile(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<AudioFile>;
    public getAudioFile(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<AudioFile>>;
    public getAudioFile(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<AudioFile>>;
    public getAudioFile(organisationId: number, uuid: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getAudioFile.');
        }
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getAudioFile.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<AudioFile>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/audio-files/${encodeURIComponent(String(uuid))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all AudioFiles for an Organisation
     * This API will give all AudioFiles according to limit, offset and organisation id. If offset is 0 and limit is 10 then It will give initial 10 users.
     * @param organisationId 
     * @param limit 
     * @param metaOnly 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAudioFiles(organisationId: number, limit?: number, metaOnly?: boolean, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<AudioFile>>;
    public getAudioFiles(organisationId: number, limit?: number, metaOnly?: boolean, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<AudioFile>>>;
    public getAudioFiles(organisationId: number, limit?: number, metaOnly?: boolean, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<AudioFile>>>;
    public getAudioFiles(organisationId: number, limit?: number, metaOnly?: boolean, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getAudioFiles.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (metaOnly !== undefined && metaOnly !== null) {
            queryParameters = queryParameters.set('metaOnly', <any>metaOnly);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<AudioFile>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/audio-files`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get call recording
     * This API will return the recording.
     * @param organisationId 
     * @param callRecordingUuid 
     * @param email 
     * @param transcribe 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCallRecording(organisationId: number, callRecordingUuid: string, email?: string, transcribe?: boolean, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CallRecording>;
    public getCallRecording(organisationId: number, callRecordingUuid: string, email?: string, transcribe?: boolean, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CallRecording>>;
    public getCallRecording(organisationId: number, callRecordingUuid: string, email?: string, transcribe?: boolean, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CallRecording>>;
    public getCallRecording(organisationId: number, callRecordingUuid: string, email?: string, transcribe?: boolean, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCallRecording.');
        }
        if (callRecordingUuid === null || callRecordingUuid === undefined) {
            throw new Error('Required parameter callRecordingUuid was null or undefined when calling getCallRecording.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (transcribe !== undefined && transcribe !== null) {
            queryParameters = queryParameters.set('transcribe', <any>transcribe);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<CallRecording>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/call-recordings/${encodeURIComponent(String(callRecordingUuid))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Agent group
     * This API will return an agent group by UUID
     * @param organisationId 
     * @param agentGroupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCallcenterAgentGroup(organisationId: number, agentGroupId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CallcentreAgentGroup>;
    public getCallcenterAgentGroup(organisationId: number, agentGroupId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CallcentreAgentGroup>>;
    public getCallcenterAgentGroup(organisationId: number, agentGroupId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CallcentreAgentGroup>>;
    public getCallcenterAgentGroup(organisationId: number, agentGroupId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCallcenterAgentGroup.');
        }
        if (agentGroupId === null || agentGroupId === undefined) {
            throw new Error('Required parameter agentGroupId was null or undefined when calling getCallcenterAgentGroup.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<CallcentreAgentGroup>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcenter-groups/${encodeURIComponent(String(agentGroupId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get live status of campaign agent
     * used to get campaign status, lead connected, session id, etc, etc
     * @param organisationId 
     * @param campaignAgentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCallcenterLiveAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CallcentreLiveAgent>;
    public getCallcenterLiveAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CallcentreLiveAgent>>;
    public getCallcenterLiveAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CallcentreLiveAgent>>;
    public getCallcenterLiveAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCallcenterLiveAgent.');
        }
        if (campaignAgentId === null || campaignAgentId === undefined) {
            throw new Error('Required parameter campaignAgentId was null or undefined when calling getCallcenterLiveAgent.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<CallcentreLiveAgent>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcenter-agents/${encodeURIComponent(String(campaignAgentId))}/live-status`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Campaign
     * This API will return a Campaign by id
     * @param organisationId 
     * @param campaignId 
     * @param includeStats 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaign(organisationId: number, campaignId: string, includeStats?: boolean, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Campaign>;
    public getCampaign(organisationId: number, campaignId: string, includeStats?: boolean, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Campaign>>;
    public getCampaign(organisationId: number, campaignId: string, includeStats?: boolean, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Campaign>>;
    public getCampaign(organisationId: number, campaignId: string, includeStats?: boolean, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCampaign.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getCampaign.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeStats !== undefined && includeStats !== null) {
            queryParameters = queryParameters.set('includeStats', <any>includeStats);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Campaign>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Campaign Agent
     * This API will return an agent by UUID
     * @param organisationId 
     * @param campaignAgentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CallcentreAgent>;
    public getCampaignAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CallcentreAgent>>;
    public getCampaignAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CallcentreAgent>>;
    public getCampaignAgent(organisationId: number, campaignAgentId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCampaignAgent.');
        }
        if (campaignAgentId === null || campaignAgentId === undefined) {
            throw new Error('Required parameter campaignAgentId was null or undefined when calling getCampaignAgent.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<CallcentreAgent>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcenter-agents/${encodeURIComponent(String(campaignAgentId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of leads related to this campaign
     * This API will return leads according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 Leads.
     * @param organisationId 
     * @param campaignId 
     * @param agentExtension 
     * @param limit 
     * @param offset 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignCallbackLeads(organisationId: number, campaignId: string, agentExtension?: string, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<CampaignLeadCallback>>;
    public getCampaignCallbackLeads(organisationId: number, campaignId: string, agentExtension?: string, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<CampaignLeadCallback>>>;
    public getCampaignCallbackLeads(organisationId: number, campaignId: string, agentExtension?: string, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<CampaignLeadCallback>>>;
    public getCampaignCallbackLeads(organisationId: number, campaignId: string, agentExtension?: string, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCampaignCallbackLeads.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getCampaignCallbackLeads.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agentExtension !== undefined && agentExtension !== null) {
            queryParameters = queryParameters.set('agentExtension', <any>agentExtension);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<CampaignLeadCallback>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/callback-leads`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of leads related to this campaign
     * This API will return leads according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 Leads.
     * @param organisationId 
     * @param campaignId 
     * @param agentExtension 
     * @param leadDialable 
     * @param leadType 
     * @param limit 
     * @param offset 
     * @param setStatus 
     * @param status 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignLeads(organisationId: number, campaignId: string, agentExtension?: string, leadDialable?: boolean, leadType?: string, limit?: number, offset?: number, setStatus?: string, status?: string, userId?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<CampaignLead>>;
    public getCampaignLeads(organisationId: number, campaignId: string, agentExtension?: string, leadDialable?: boolean, leadType?: string, limit?: number, offset?: number, setStatus?: string, status?: string, userId?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<CampaignLead>>>;
    public getCampaignLeads(organisationId: number, campaignId: string, agentExtension?: string, leadDialable?: boolean, leadType?: string, limit?: number, offset?: number, setStatus?: string, status?: string, userId?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<CampaignLead>>>;
    public getCampaignLeads(organisationId: number, campaignId: string, agentExtension?: string, leadDialable?: boolean, leadType?: string, limit?: number, offset?: number, setStatus?: string, status?: string, userId?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCampaignLeads.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getCampaignLeads.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agentExtension !== undefined && agentExtension !== null) {
            queryParameters = queryParameters.set('agentExtension', <any>agentExtension);
        }
        if (leadDialable !== undefined && leadDialable !== null) {
            queryParameters = queryParameters.set('leadDialable', <any>leadDialable);
        }
        if (leadType !== undefined && leadType !== null) {
            queryParameters = queryParameters.set('leadType', <any>leadType);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (setStatus !== undefined && setStatus !== null) {
            queryParameters = queryParameters.set('setStatus', <any>setStatus);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<CampaignLead>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/leads`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get CampaignList
     * This API will return a CampaignList by id
     * @param organisationId 
     * @param campaignListId 
     * @param includeLeads 
     * @param includeStats 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignList(organisationId: number, campaignListId: string, includeLeads?: boolean, includeStats?: boolean, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignList>;
    public getCampaignList(organisationId: number, campaignListId: string, includeLeads?: boolean, includeStats?: boolean, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignList>>;
    public getCampaignList(organisationId: number, campaignListId: string, includeLeads?: boolean, includeStats?: boolean, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignList>>;
    public getCampaignList(organisationId: number, campaignListId: string, includeLeads?: boolean, includeStats?: boolean, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCampaignList.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling getCampaignList.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeLeads !== undefined && includeLeads !== null) {
            queryParameters = queryParameters.set('includeLeads', <any>includeLeads);
        }
        if (includeStats !== undefined && includeStats !== null) {
            queryParameters = queryParameters.set('includeStats', <any>includeStats);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<CampaignList>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Campaign Lead
     * This API will return a lead by UUID
     * @param organisationId 
     * @param campaignListId 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignListLead(organisationId: number, campaignListId: string, leadId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignLead>;
    public getCampaignListLead(organisationId: number, campaignListId: string, leadId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignLead>>;
    public getCampaignListLead(organisationId: number, campaignListId: string, leadId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignLead>>;
    public getCampaignListLead(organisationId: number, campaignListId: string, leadId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCampaignListLead.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling getCampaignListLead.');
        }
        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling getCampaignListLead.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<CampaignLead>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}/leads/${encodeURIComponent(String(leadId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all leads for a list
     * This API will return a list of all leads for a list according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 CampaignLists.
     * @param organisationId 
     * @param campaignListId 
     * @param limit 
     * @param offset 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignListLeads(organisationId: number, campaignListId: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<CampaignLead>>;
    public getCampaignListLeads(organisationId: number, campaignListId: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<CampaignLead>>>;
    public getCampaignListLeads(organisationId: number, campaignListId: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<CampaignLead>>>;
    public getCampaignListLeads(organisationId: number, campaignListId: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCampaignListLeads.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling getCampaignListLeads.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<CampaignLead>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}/leads`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all leads for a list
     * This API will return a list of all leads (xlsx) for a list according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 CampaignLists.
     * @param organisationId 
     * @param campaignListId 
     * @param limit 
     * @param offset 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignListLeadsXls(organisationId: number, campaignListId: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public getCampaignListLeadsXls(organisationId: number, campaignListId: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public getCampaignListLeadsXls(organisationId: number, campaignListId: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public getCampaignListLeadsXls(organisationId: number, campaignListId: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCampaignListLeadsXls.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling getCampaignListLeadsXls.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}/leads/xls`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list stats
     * get list stats.
     * @param organisationId 
     * @param campaignListId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignListStats(organisationId: number, campaignListId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignListStats>;
    public getCampaignListStats(organisationId: number, campaignListId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignListStats>>;
    public getCampaignListStats(organisationId: number, campaignListId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignListStats>>;
    public getCampaignListStats(organisationId: number, campaignListId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCampaignListStats.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling getCampaignListStats.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<CampaignListStats>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}/stats`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get campaign stats
     * get campaign stats.
     * @param organisationId 
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignStats(organisationId: number, campaignId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignStats>;
    public getCampaignStats(organisationId: number, campaignId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignStats>>;
    public getCampaignStats(organisationId: number, campaignId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignStats>>;
    public getCampaignStats(organisationId: number, campaignId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getCampaignStats.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getCampaignStats.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<CampaignStats>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/stats`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get configuration
     * 
     * @param uuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfiguration(uuid: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public getConfiguration(uuid: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public getConfiguration(uuid: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public getConfiguration(uuid: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getConfiguration.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<any>(`${this.basePath}/rest/v1/configurations/${encodeURIComponent(String(uuid))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a domain setting by id
     * This API will return a setting by setting Id.
     * @param organisationId 
     * @param domainSettingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDomainSetting(organisationId: number, domainSettingId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<DomainSetting>;
    public getDomainSetting(organisationId: number, domainSettingId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<DomainSetting>>;
    public getDomainSetting(organisationId: number, domainSettingId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<DomainSetting>>;
    public getDomainSetting(organisationId: number, domainSettingId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getDomainSetting.');
        }
        if (domainSettingId === null || domainSettingId === undefined) {
            throw new Error('Required parameter domainSettingId was null or undefined when calling getDomainSetting.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<DomainSetting>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/settings/${encodeURIComponent(String(domainSettingId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of domain settings by cat/subcat and name
     * This API used to get domain settings.
     * @param organisationId 
     * @param category The domain setting category.
     * @param limit Max number of results to return. Defaults to 50.
     * @param name The domain setting name.
     * @param offset Offset for pagination. An offset of 10 would return from index 10, i.e. skipping 0 to 9 (first 10 items are skipped).
     * @param subCategory The domain setting sub category.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDomainSettings(organisationId: number, category?: string, limit?: number, name?: string, offset?: number, subCategory?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<DomainSetting>>;
    public getDomainSettings(organisationId: number, category?: string, limit?: number, name?: string, offset?: number, subCategory?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<DomainSetting>>>;
    public getDomainSettings(organisationId: number, category?: string, limit?: number, name?: string, offset?: number, subCategory?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<DomainSetting>>>;
    public getDomainSettings(organisationId: number, category?: string, limit?: number, name?: string, offset?: number, subCategory?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getDomainSettings.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (category !== undefined && category !== null) {
            queryParameters = queryParameters.set('category', <any>category);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (subCategory !== undefined && subCategory !== null) {
            queryParameters = queryParameters.set('subCategory', <any>subCategory);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<DomainSetting>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/settings`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get an Lead Status (disposition) by code
     * This API will return a lead status by Id.
     * @param organisationId 
     * @param leadStatusId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLeadStatus(organisationId: number, leadStatusId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<LeadStatus>;
    public getLeadStatus(organisationId: number, leadStatusId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<LeadStatus>>;
    public getLeadStatus(organisationId: number, leadStatusId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<LeadStatus>>;
    public getLeadStatus(organisationId: number, leadStatusId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getLeadStatus.');
        }
        if (leadStatusId === null || leadStatusId === undefined) {
            throw new Error('Required parameter leadStatusId was null or undefined when calling getLeadStatus.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<LeadStatus>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/lead-statuses/${encodeURIComponent(String(leadStatusId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get an Lead Status (disposition) dial rule by id
     * This API will return a lead status dial rule by Id.
     * @param organisationId 
     * @param campaignId 
     * @param leadStatusDialRuleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignDialStatusRule>;
    public getLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignDialStatusRule>>;
    public getLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignDialStatusRule>>;
    public getLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getLeadStatusDialRule.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getLeadStatusDialRule.');
        }
        if (leadStatusDialRuleId === null || leadStatusDialRuleId === undefined) {
            throw new Error('Required parameter leadStatusDialRuleId was null or undefined when calling getLeadStatusDialRule.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<CampaignDialStatusRule>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/leadstatus-dial-rules/${encodeURIComponent(String(leadStatusDialRuleId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of lead statuse dial rules
     * This API wil return a list of lead status dial rules.
     * @param organisationId 
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLeadStatusDialRules(organisationId: number, campaignId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<LeadStatus>>;
    public getLeadStatusDialRules(organisationId: number, campaignId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<LeadStatus>>>;
    public getLeadStatusDialRules(organisationId: number, campaignId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<LeadStatus>>>;
    public getLeadStatusDialRules(organisationId: number, campaignId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getLeadStatusDialRules.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling getLeadStatusDialRules.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<LeadStatus>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/leadstatus-dial-rules`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of lead statuses
     * This API used to get a  list of lead statuses.
     * @param organisationId 
     * @param agentSelectable The filter for agent selectable.
     * @param campaignId The campaign to filter by, if none provided all system statuses will be returned.
     * @param includeSystemStatuses The filter for system status.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLeadStatuses(organisationId: number, agentSelectable?: boolean, campaignId?: string, includeSystemStatuses?: boolean, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<LeadStatus>>;
    public getLeadStatuses(organisationId: number, agentSelectable?: boolean, campaignId?: string, includeSystemStatuses?: boolean, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<LeadStatus>>>;
    public getLeadStatuses(organisationId: number, agentSelectable?: boolean, campaignId?: string, includeSystemStatuses?: boolean, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<LeadStatus>>>;
    public getLeadStatuses(organisationId: number, agentSelectable?: boolean, campaignId?: string, includeSystemStatuses?: boolean, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getLeadStatuses.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agentSelectable !== undefined && agentSelectable !== null) {
            queryParameters = queryParameters.set('agentSelectable', <any>agentSelectable);
        }
        if (campaignId !== undefined && campaignId !== null) {
            queryParameters = queryParameters.set('campaignId', <any>campaignId);
        }
        if (includeSystemStatuses !== undefined && includeSystemStatuses !== null) {
            queryParameters = queryParameters.set('includeSystemStatuses', <any>includeSystemStatuses);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<LeadStatus>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/lead-statuses`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all agent groups for an organisation
     * This API will return a list of all agent groups for an organisation. If offset is 0 and limit is 10 then It will give initial 10 Agents.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationAgentGroups(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<CallcentreAgentGroup>>;
    public getOrganisationAgentGroups(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<CallcentreAgentGroup>>>;
    public getOrganisationAgentGroups(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<CallcentreAgentGroup>>>;
    public getOrganisationAgentGroups(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationAgentGroups.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<CallcentreAgentGroup>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcenter-groups`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get an Organisation by id
     * This API will return an organisation by organisation Id.
     * @param organisationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationById(organisationId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxOrganisation>;
    public getOrganisationById(organisationId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxOrganisation>>;
    public getOrganisationById(organisationId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxOrganisation>>;
    public getOrganisationById(organisationId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationById.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<PabxOrganisation>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all agents for an organisation
     * This API will return a list of all agents for an organisation. If offset is 0 and limit is 10 then It will give initial 10 Agents.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationCallcenterAgents(organisationId: number, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<CallcentreAgent>>;
    public getOrganisationCallcenterAgents(organisationId: number, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<CallcentreAgent>>>;
    public getOrganisationCallcenterAgents(organisationId: number, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<CallcentreAgent>>>;
    public getOrganisationCallcenterAgents(organisationId: number, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationCallcenterAgents.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<CallcentreAgent>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcenter-agents`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a lead for an organisation
     * This API will return avsingle lead by uuid.
     * @param organisationId 
     * @param leadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationCampaignLead(organisationId: number, leadId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignLead>;
    public getOrganisationCampaignLead(organisationId: number, leadId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignLead>>;
    public getOrganisationCampaignLead(organisationId: number, leadId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignLead>>;
    public getOrganisationCampaignLead(organisationId: number, leadId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationCampaignLead.');
        }
        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling getOrganisationCampaignLead.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<CampaignLead>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/organisation-lead/${encodeURIComponent(String(leadId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of organisations matching by name
     * This API used to get a organisation by name with wildcards.
     * @param name The organisation name. Use % as a wildcard. E.g. %coke%. Not case sensitive.
     * @param limit Max number of results to return. Defaults to 50.
     * @param offset Offset for pagination. An offset of 10 would return from index 10, i.e. skipping 0 @Inject     PabxManager pabxManager;     @Inject     OrganisationResource organisationResource; to 9 (first 10 items are skipped).
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisations(name: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<PabxOrganisation>>;
    public getOrganisations(name: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<PabxOrganisation>>>;
    public getOrganisations(name: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<PabxOrganisation>>>;
    public getOrganisations(name: string, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling getOrganisations.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<PabxOrganisation>>(`${this.basePath}/rest/v1/pabx-organisations`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Cakk Block
     * This API will return the Call Block detail by id
     * @param organisationId 
     * @param callBlockId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsCallBlock(organisationId: number, callBlockId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CallBlock>;
    public getOrganisationsCallBlock(organisationId: number, callBlockId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CallBlock>>;
    public getOrganisationsCallBlock(organisationId: number, callBlockId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CallBlock>>;
    public getOrganisationsCallBlock(organisationId: number, callBlockId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsCallBlock.');
        }
        if (callBlockId === null || callBlockId === undefined) {
            throw new Error('Required parameter callBlockId was null or undefined when calling getOrganisationsCallBlock.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<CallBlock>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/call-blocking/${encodeURIComponent(String(callBlockId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the Call Blocks
     * This API will return Call Blocks according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 Call blocks.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsCallBlocks(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<CallBlock>>;
    public getOrganisationsCallBlocks(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<CallBlock>>>;
    public getOrganisationsCallBlocks(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<CallBlock>>>;
    public getOrganisationsCallBlocks(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsCallBlocks.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<CallBlock>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/call-blocking`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the Call Centres
     * This API will return Call Centres according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 Call Centres.
     * @param organisationId 
     * @param callCentreId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsCallCentre(organisationId: number, callCentreId: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Callcentre>>;
    public getOrganisationsCallCentre(organisationId: number, callCentreId: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Callcentre>>>;
    public getOrganisationsCallCentre(organisationId: number, callCentreId: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Callcentre>>>;
    public getOrganisationsCallCentre(organisationId: number, callCentreId: string, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsCallCentre.');
        }
        if (callCentreId === null || callCentreId === undefined) {
            throw new Error('Required parameter callCentreId was null or undefined when calling getOrganisationsCallCentre.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Callcentre>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcentres/${encodeURIComponent(String(callCentreId))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the Call Centres
     * This API will return Call Centres according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 Call Centres.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsCallCentres(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Callcentre>>;
    public getOrganisationsCallCentres(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Callcentre>>>;
    public getOrganisationsCallCentres(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Callcentre>>>;
    public getOrganisationsCallCentres(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsCallCentres.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Callcentre>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcentres`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the Conferences
     * This API will return Conferences according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 Conferences.
     * @param organisationId 
     * @param conferenceId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsCallConference(organisationId: number, conferenceId: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Conference>>;
    public getOrganisationsCallConference(organisationId: number, conferenceId: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Conference>>>;
    public getOrganisationsCallConference(organisationId: number, conferenceId: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Conference>>>;
    public getOrganisationsCallConference(organisationId: number, conferenceId: string, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsCallConference.');
        }
        if (conferenceId === null || conferenceId === undefined) {
            throw new Error('Required parameter conferenceId was null or undefined when calling getOrganisationsCallConference.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Conference>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/conferences/${encodeURIComponent(String(conferenceId))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the CampaignLists for a domain / user
     * This API will return CampaignLists according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 CampaignLists.
     * @param organisationId 
     * @param includeLeads 
     * @param includeStats 
     * @param limit 
     * @param offset 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsCampaignLists(organisationId: number, includeLeads?: boolean, includeStats?: boolean, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<CampaignList>>;
    public getOrganisationsCampaignLists(organisationId: number, includeLeads?: boolean, includeStats?: boolean, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<CampaignList>>>;
    public getOrganisationsCampaignLists(organisationId: number, includeLeads?: boolean, includeStats?: boolean, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<CampaignList>>>;
    public getOrganisationsCampaignLists(organisationId: number, includeLeads?: boolean, includeStats?: boolean, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsCampaignLists.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeLeads !== undefined && includeLeads !== null) {
            queryParameters = queryParameters.set('includeLeads', <any>includeLeads);
        }
        if (includeStats !== undefined && includeStats !== null) {
            queryParameters = queryParameters.set('includeStats', <any>includeStats);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<CampaignList>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the Campaigns for a domain / user
     * This API will return Campaigns according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 Campaigns.
     * @param organisationId 
     * @param agentGroupId 
     * @param includeStats 
     * @param limit 
     * @param offset 
     * @param onlyActiveInSchedule 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsCampaigns(organisationId: number, agentGroupId?: string, includeStats?: boolean, limit?: number, offset?: number, onlyActiveInSchedule?: boolean, userId?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Campaign>>;
    public getOrganisationsCampaigns(organisationId: number, agentGroupId?: string, includeStats?: boolean, limit?: number, offset?: number, onlyActiveInSchedule?: boolean, userId?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Campaign>>>;
    public getOrganisationsCampaigns(organisationId: number, agentGroupId?: string, includeStats?: boolean, limit?: number, offset?: number, onlyActiveInSchedule?: boolean, userId?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Campaign>>>;
    public getOrganisationsCampaigns(organisationId: number, agentGroupId?: string, includeStats?: boolean, limit?: number, offset?: number, onlyActiveInSchedule?: boolean, userId?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsCampaigns.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agentGroupId !== undefined && agentGroupId !== null) {
            queryParameters = queryParameters.set('agentGroupId', <any>agentGroupId);
        }
        if (includeStats !== undefined && includeStats !== null) {
            queryParameters = queryParameters.set('includeStats', <any>includeStats);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (onlyActiveInSchedule !== undefined && onlyActiveInSchedule !== null) {
            queryParameters = queryParameters.set('onlyActiveInSchedule', <any>onlyActiveInSchedule);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Campaign>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the conferences
     * This API will return conferences according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 conferences.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsConferences(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Conference>>;
    public getOrganisationsConferences(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Conference>>>;
    public getOrganisationsConferences(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Conference>>>;
    public getOrganisationsConferences(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsConferences.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Conference>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/conferences`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Contact
     * This API will return a contact by id
     * @param organisationId 
     * @param contactId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsContact(organisationId: number, contactId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Contact>;
    public getOrganisationsContact(organisationId: number, contactId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Contact>>;
    public getOrganisationsContact(organisationId: number, contactId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Contact>>;
    public getOrganisationsContact(organisationId: number, contactId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsContact.');
        }
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling getOrganisationsContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Contact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/contacts/${encodeURIComponent(String(contactId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the Contacts for a domain / user
     * This API will return Contacts according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 Contacts.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsContacts(organisationId: number, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Contact>>;
    public getOrganisationsContacts(organisationId: number, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Contact>>>;
    public getOrganisationsContacts(organisationId: number, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Contact>>>;
    public getOrganisationsContacts(organisationId: number, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsContacts.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Contact>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/contacts`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the DialPlan by id
     * This API will return a DialPlan for the provided id.
     * @param organisationId 
     * @param dialPlanId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsDialPlan(organisationId: number, dialPlanId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<DialPlan>;
    public getOrganisationsDialPlan(organisationId: number, dialPlanId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<DialPlan>>;
    public getOrganisationsDialPlan(organisationId: number, dialPlanId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<DialPlan>>;
    public getOrganisationsDialPlan(organisationId: number, dialPlanId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsDialPlan.');
        }
        if (dialPlanId === null || dialPlanId === undefined) {
            throw new Error('Required parameter dialPlanId was null or undefined when calling getOrganisationsDialPlan.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<DialPlan>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/dial-plans/${encodeURIComponent(String(dialPlanId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of the DialPlans
     * This API will return all DialPlans according to the limit and offset. If offset is 0 and limit is 10 then It will give initial 10 users.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsDialPlans(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<DialPlan>;
    public getOrganisationsDialPlans(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<DialPlan>>;
    public getOrganisationsDialPlans(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<DialPlan>>;
    public getOrganisationsDialPlans(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsDialPlans.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<DialPlan>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/dial-plans`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get IVR Menu detail
     * This API will return the IVR Menu detail by id.
     * @param organisationId 
     * @param menuId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsIvrMenu(organisationId: number, menuId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<IvrMenu>;
    public getOrganisationsIvrMenu(organisationId: number, menuId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<IvrMenu>>;
    public getOrganisationsIvrMenu(organisationId: number, menuId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<IvrMenu>>;
    public getOrganisationsIvrMenu(organisationId: number, menuId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsIvrMenu.');
        }
        if (menuId === null || menuId === undefined) {
            throw new Error('Required parameter menuId was null or undefined when calling getOrganisationsIvrMenu.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<IvrMenu>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/ivr-menus/${encodeURIComponent(String(menuId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the Users
     * This API will return users according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 users.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsIvrMenus(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<IvrMenu>>;
    public getOrganisationsIvrMenus(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<IvrMenu>>>;
    public getOrganisationsIvrMenus(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<IvrMenu>>>;
    public getOrganisationsIvrMenus(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsIvrMenus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<IvrMenu>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/ivr-menus`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of the Organisations PabxContacts
     * This API will return all PabxContacts for the user/organisation according to the limit and offset. If offset is 0 and limit is 10 then It will give initial 10 users.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param userIds Comma delimited list of userIds in the org to get Pabx Contacts for or null to get all
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsPabxContacts(organisationId: number, limit?: number, offset?: number, userIds?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<PabxContact>>;
    public getOrganisationsPabxContacts(organisationId: number, limit?: number, offset?: number, userIds?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<PabxContact>>>;
    public getOrganisationsPabxContacts(organisationId: number, limit?: number, offset?: number, userIds?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<PabxContact>>>;
    public getOrganisationsPabxContacts(organisationId: number, limit?: number, offset?: number, userIds?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsPabxContacts.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (userIds !== undefined && userIds !== null) {
            queryParameters = queryParameters.set('userIds', <any>userIds);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<PabxContact>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the pinnumbers for a domain
     * This API will return pin numbers according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 pinnumbers.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsPinNumbers(organisationId: number, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<PinNumber>>;
    public getOrganisationsPinNumbers(organisationId: number, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<PinNumber>>>;
    public getOrganisationsPinNumbers(organisationId: number, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<PinNumber>>>;
    public getOrganisationsPinNumbers(organisationId: number, limit?: number, offset?: number, userId?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsPinNumbers.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<PinNumber>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pin-numbers`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Pin Number
     * This API will return a pin number by uuid
     * @param organisationId 
     * @param pinNumberId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsPinnumber(organisationId: number, pinNumberId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PinNumber>;
    public getOrganisationsPinnumber(organisationId: number, pinNumberId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PinNumber>>;
    public getOrganisationsPinnumber(organisationId: number, pinNumberId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PinNumber>>;
    public getOrganisationsPinnumber(organisationId: number, pinNumberId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsPinnumber.');
        }
        if (pinNumberId === null || pinNumberId === undefined) {
            throw new Error('Required parameter pinNumberId was null or undefined when calling getOrganisationsPinnumber.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<PinNumber>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pin-numbers/${encodeURIComponent(String(pinNumberId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a PstnContact for the Organisation
     * This API will give PstnContact detail by id.
     * @param organisationId 
     * @param e164 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsPstnContact(organisationId: number, e164: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PstnContact>;
    public getOrganisationsPstnContact(organisationId: number, e164: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PstnContact>>;
    public getOrganisationsPstnContact(organisationId: number, e164: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PstnContact>>;
    public getOrganisationsPstnContact(organisationId: number, e164: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsPstnContact.');
        }
        if (e164 === null || e164 === undefined) {
            throw new Error('Required parameter e164 was null or undefined when calling getOrganisationsPstnContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<PstnContact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pstn-contacts/${encodeURIComponent(String(e164))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all PstnContacts for an Organisation
     * This API will give all PstnContacts according to limit, offset and organisation id. If offset is 0 and limit is 10 then It will give initial 10 users.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsPstnContacts(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<PstnContact>>;
    public getOrganisationsPstnContacts(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<PstnContact>>>;
    public getOrganisationsPstnContacts(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<PstnContact>>>;
    public getOrganisationsPstnContacts(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsPstnContacts.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<PstnContact>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pstn-contacts`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Ring Group
     * This API will return the Ring Group detail by id.
     * @param organisationId 
     * @param ringGroupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsRingGroup(organisationId: number, ringGroupId: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<RingGroup>;
    public getOrganisationsRingGroup(organisationId: number, ringGroupId: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<RingGroup>>;
    public getOrganisationsRingGroup(organisationId: number, ringGroupId: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<RingGroup>>;
    public getOrganisationsRingGroup(organisationId: number, ringGroupId: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsRingGroup.');
        }
        if (ringGroupId === null || ringGroupId === undefined) {
            throw new Error('Required parameter ringGroupId was null or undefined when calling getOrganisationsRingGroup.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<RingGroup>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/ring-groups/${encodeURIComponent(String(ringGroupId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the Ring Groups
     * This API will return Ring Groups according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 Ring Groups.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsRingGroups(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<RingGroup>>;
    public getOrganisationsRingGroups(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<RingGroup>>>;
    public getOrganisationsRingGroups(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<RingGroup>>>;
    public getOrganisationsRingGroups(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsRingGroups.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<RingGroup>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/ring-groups`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the Routeable Apps
     * This API will return Routeable Apps according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 Routeable Apps.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsRoutableApps(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<RouteableApp>>;
    public getOrganisationsRoutableApps(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<RouteableApp>>>;
    public getOrganisationsRoutableApps(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<RouteableApp>>>;
    public getOrganisationsRoutableApps(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsRoutableApps.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<RouteableApp>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/routeable-apps`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Schedule for the Organisation
     * This API will give Schedule detail by name.
     * @param organisationId 
     * @param scheduleUuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsSchedule(organisationId: number, scheduleUuid: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<TimeSchedule>;
    public getOrganisationsSchedule(organisationId: number, scheduleUuid: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<TimeSchedule>>;
    public getOrganisationsSchedule(organisationId: number, scheduleUuid: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<TimeSchedule>>;
    public getOrganisationsSchedule(organisationId: number, scheduleUuid: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsSchedule.');
        }
        if (scheduleUuid === null || scheduleUuid === undefined) {
            throw new Error('Required parameter scheduleUuid was null or undefined when calling getOrganisationsSchedule.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<TimeSchedule>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/schedules/${encodeURIComponent(String(scheduleUuid))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Schedules for an Organisation
     * This API will give all Schedules according to limit, offset and organisation id. If offset is 0 and limit is 10 then It will give initial 10 users.
     * @param organisationId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrganisationsTimeSchedules(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<TimeSchedule>>;
    public getOrganisationsTimeSchedules(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<TimeSchedule>>>;
    public getOrganisationsTimeSchedules(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<TimeSchedule>>>;
    public getOrganisationsTimeSchedules(organisationId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getOrganisationsTimeSchedules.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<TimeSchedule>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/schedules`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the PabxContact by extension or uuid for the organisation
     * This API will return a PabxContact for the provided extension/uuid and orgId.
     * @param organisationId 
     * @param extension 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPabxContact(organisationId: number, extension: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxContact>;
    public getPabxContact(organisationId: number, extension: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxContact>>;
    public getPabxContact(organisationId: number, extension: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxContact>>;
    public getPabxContact(organisationId: number, extension: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getPabxContact.');
        }
        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getPabxContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<PabxContact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(extension))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get pabx contact registration stats
     * This API will return the stats for a PabxContact
     * @param organisationId 
     * @param extension 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPabxContactStats(organisationId: number, extension: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxContactStats>;
    public getPabxContactStats(organisationId: number, extension: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxContactStats>>;
    public getPabxContactStats(organisationId: number, extension: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxContactStats>>;
    public getPabxContactStats(organisationId: number, extension: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getPabxContactStats.');
        }
        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getPabxContactStats.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<PabxContactStats>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(extension))}/reg-stats`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get pabx contact registration stats
     * This API will return the stats for a PabxContact
     * @param organisationId 
     * @param uuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPabxContactStats_3(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxContactStats>;
    public getPabxContactStats_3(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxContactStats>>;
    public getPabxContactStats_3(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxContactStats>>;
    public getPabxContactStats_3(organisationId: number, uuid: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getPabxContactStats_3.');
        }
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getPabxContactStats_3.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<PabxContactStats>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(uuid))}/reg-stats`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the PabxContact by extension or uuid for the organisation
     * This API will return a PabxContact for the provided extension/uuid and orgId.
     * @param organisationId 
     * @param uuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPabxContact_4(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxContact>;
    public getPabxContact_4(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxContact>>;
    public getPabxContact_4(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxContact>>;
    public getPabxContact_4(organisationId: number, uuid: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling getPabxContact_4.');
        }
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getPabxContact_4.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<PabxContact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(uuid))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of all the numbers
     * This API will return numbers according to limit and offset. If offset is 0 and limit is 10 then It will give initial 10 available numbers. The match field uses a % as a wildcard e.g. %123% would return all available numbers with 123 in them. Status is A for allocated and F for free
     * @param limit 
     * @param match 
     * @param offset 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPstnContacts(limit?: number, match?: string, offset?: number, status?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<PstnContact>>;
    public getPstnContacts(limit?: number, match?: string, offset?: number, status?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<PstnContact>>>;
    public getPstnContacts(limit?: number, match?: string, offset?: number, status?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<PstnContact>>>;
    public getPstnContacts(limit?: number, match?: string, offset?: number, status?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (match !== undefined && match !== null) {
            queryParameters = queryParameters.set('match', <any>match);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<PstnContact>>(`${this.basePath}/rest/v1/numbers`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initiate a call (click to dial)
     * This API will initiate a new call.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initiateCall(organisationId: number, body?: CallSetup, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CallSetup>;
    public initiateCall(organisationId: number, body?: CallSetup, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CallSetup>>;
    public initiateCall(organisationId: number, body?: CallSetup, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CallSetup>>;
    public initiateCall(organisationId: number, body?: CallSetup, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling initiateCall.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<CallSetup>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/calls`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Initiate an eavesdrop
     * This API will initiate a new eavesdrop.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initiateEavesDrop(organisationId: number, body?: EavesDropSetup, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<EavesDropSetup>;
    public initiateEavesDrop(organisationId: number, body?: EavesDropSetup, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<EavesDropSetup>>;
    public initiateEavesDrop(organisationId: number, body?: EavesDropSetup, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<EavesDropSetup>>;
    public initiateEavesDrop(organisationId: number, body?: EavesDropSetup, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling initiateEavesDrop.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<EavesDropSetup>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/eaves-drop`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new number
     * This API will add a new number.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postNumberJson(body?: PstnContact, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PstnContact>;
    public postNumberJson(body?: PstnContact, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PstnContact>>;
    public postNumberJson(body?: PstnContact, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PstnContact>>;
    public postNumberJson(body?: PstnContact, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<PstnContact>(`${this.basePath}/rest/v1/numbers`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post an event to an agent, for example Pause, Resume
     * This API will process a campaign agent event / action.
     * @param organisationId 
     * @param campaignAgentId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processCampaignAgentEvent(organisationId: number, campaignAgentId: string, body?: CampaignAgentEvent, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignAgent>;
    public processCampaignAgentEvent(organisationId: number, campaignAgentId: string, body?: CampaignAgentEvent, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignAgent>>;
    public processCampaignAgentEvent(organisationId: number, campaignAgentId: string, body?: CampaignAgentEvent, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignAgent>>;
    public processCampaignAgentEvent(organisationId: number, campaignAgentId: string, body?: CampaignAgentEvent, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling processCampaignAgentEvent.');
        }
        if (campaignAgentId === null || campaignAgentId === undefined) {
            throw new Error('Required parameter campaignAgentId was null or undefined when calling processCampaignAgentEvent.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<CampaignAgent>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcenter-agents/${encodeURIComponent(String(campaignAgentId))}/events`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post an event to a campaign, for example Pause, Resume
     * This API will process a campaign event / action.
     * @param organisationId 
     * @param campaignId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processCampaignEvent(organisationId: number, campaignId: string, body?: CampaignEvent, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Campaign>;
    public processCampaignEvent(organisationId: number, campaignId: string, body?: CampaignEvent, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Campaign>>;
    public processCampaignEvent(organisationId: number, campaignId: string, body?: CampaignEvent, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Campaign>>;
    public processCampaignEvent(organisationId: number, campaignId: string, body?: CampaignEvent, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling processCampaignEvent.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling processCampaignEvent.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Campaign>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/events`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Process a campaign lead event
     * This API will process campaign lead events.
     * @param organisationId 
     * @param campaignListId 
     * @param leadId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processCampaignLeadEvent(organisationId: number, campaignListId: string, leadId: string, body?: CampaignLeadEvent, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignLeadEvent>;
    public processCampaignLeadEvent(organisationId: number, campaignListId: string, leadId: string, body?: CampaignLeadEvent, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignLeadEvent>>;
    public processCampaignLeadEvent(organisationId: number, campaignListId: string, leadId: string, body?: CampaignLeadEvent, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignLeadEvent>>;
    public processCampaignLeadEvent(organisationId: number, campaignListId: string, leadId: string, body?: CampaignLeadEvent, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling processCampaignLeadEvent.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling processCampaignLeadEvent.');
        }
        if (leadId === null || leadId === undefined) {
            throw new Error('Required parameter leadId was null or undefined when calling processCampaignLeadEvent.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<CampaignLeadEvent>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}/leads/${encodeURIComponent(String(leadId))}/events`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post an event to a campaign list, for example Pause, Resume
     * This API will process a campaign list event / action.
     * @param organisationId 
     * @param campaignListId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processCampaignListEvent(organisationId: number, campaignListId: string, body?: CampaignListEvent, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignList>;
    public processCampaignListEvent(organisationId: number, campaignListId: string, body?: CampaignListEvent, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignList>>;
    public processCampaignListEvent(organisationId: number, campaignListId: string, body?: CampaignListEvent, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignList>>;
    public processCampaignListEvent(organisationId: number, campaignListId: string, body?: CampaignListEvent, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling processCampaignListEvent.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling processCampaignListEvent.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<CampaignList>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}/events`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Authenticate access to a channel via pusher.
     * This API will check auth to a pusher private channel.
     * @param organisationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pusherAuth(organisationId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PusherAuthResponseData>;
    public pusherAuth(organisationId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PusherAuthResponseData>>;
    public pusherAuth(organisationId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PusherAuthResponseData>>;
    public pusherAuth(organisationId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling pusherAuth.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<PusherAuthResponseData>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pusher-auth`,
            null,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resend configuration email
     * This API will resend the configuration email for a Pabx contact
     * @param organisationId 
     * @param extension 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendConfigEmail(organisationId: number, extension: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxContact>;
    public resendConfigEmail(organisationId: number, extension: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxContact>>;
    public resendConfigEmail(organisationId: number, extension: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxContact>>;
    public resendConfigEmail(organisationId: number, extension: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling resendConfigEmail.');
        }
        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling resendConfigEmail.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<PabxContact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(extension))}/config-instructions`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resend configuration email
     * This API will resend the configuration email for a Pabx contact
     * @param organisationId 
     * @param uuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendConfigEmail_5(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxContact>;
    public resendConfigEmail_5(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxContact>>;
    public resendConfigEmail_5(organisationId: number, uuid: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxContact>>;
    public resendConfigEmail_5(organisationId: number, uuid: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling resendConfigEmail_5.');
        }
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling resendConfigEmail_5.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<PabxContact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(uuid))}/config-instructions`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Say that the number is about to be signed up
     * Will return error if number has already entered signup.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setNumberEnteredSignup(body?: PstnContact, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public setNumberEnteredSignup(body?: PstnContact, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public setNumberEnteredSignup(body?: PstnContact, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public setNumberEnteredSignup(body?: PstnContact, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/numbers/in-signup`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the PabxContacts status
     * This API will update a PabxContacts status
     * @param organisationId 
     * @param extension 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPabxContactStatus(organisationId: number, extension: number, body?: PabxContactStatus, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxContact>;
    public setPabxContactStatus(organisationId: number, extension: number, body?: PabxContactStatus, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxContact>>;
    public setPabxContactStatus(organisationId: number, extension: number, body?: PabxContactStatus, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxContact>>;
    public setPabxContactStatus(organisationId: number, extension: number, body?: PabxContactStatus, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling setPabxContactStatus.');
        }
        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling setPabxContactStatus.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<PabxContact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(extension))}/current-status`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the PabxContacts status
     * This API will update a PabxContacts status
     * @param organisationId 
     * @param uuid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPabxContactStatus_6(organisationId: number, uuid: string, body?: PabxContactStatus, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxContact>;
    public setPabxContactStatus_6(organisationId: number, uuid: string, body?: PabxContactStatus, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxContact>>;
    public setPabxContactStatus_6(organisationId: number, uuid: string, body?: PabxContactStatus, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxContact>>;
    public setPabxContactStatus_6(organisationId: number, uuid: string, body?: PabxContactStatus, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling setPabxContactStatus_6.');
        }
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling setPabxContactStatus_6.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<PabxContact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(uuid))}/current-status`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the AudioFiles detail for the Organisation
     * This API will update AudioFiles detail.
     * @param organisationId 
     * @param uuid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAudioFile(organisationId: number, uuid: string, body?: AudioFile, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<AudioFile>;
    public updateAudioFile(organisationId: number, uuid: string, body?: AudioFile, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<AudioFile>>;
    public updateAudioFile(organisationId: number, uuid: string, body?: AudioFile, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<AudioFile>>;
    public updateAudioFile(organisationId: number, uuid: string, body?: AudioFile, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateAudioFile.');
        }
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling updateAudioFile.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<AudioFile>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/audio-files/${encodeURIComponent(String(uuid))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Agent group
     * This API will update an agent group
     * @param organisationId 
     * @param agentGroupId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCallcenterAgentGroup(organisationId: number, agentGroupId: string, body?: CallcentreAgentGroup, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CallcentreAgentGroup>;
    public updateCallcenterAgentGroup(organisationId: number, agentGroupId: string, body?: CallcentreAgentGroup, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CallcentreAgentGroup>>;
    public updateCallcenterAgentGroup(organisationId: number, agentGroupId: string, body?: CallcentreAgentGroup, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CallcentreAgentGroup>>;
    public updateCallcenterAgentGroup(organisationId: number, agentGroupId: string, body?: CallcentreAgentGroup, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateCallcenterAgentGroup.');
        }
        if (agentGroupId === null || agentGroupId === undefined) {
            throw new Error('Required parameter agentGroupId was null or undefined when calling updateCallcenterAgentGroup.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<CallcentreAgentGroup>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcenter-groups/${encodeURIComponent(String(agentGroupId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Campaign detail
     * This API will update a Campaign.
     * @param organisationId 
     * @param campaignId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCampaign(organisationId: number, campaignId: string, body?: Campaign, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Campaign>;
    public updateCampaign(organisationId: number, campaignId: string, body?: Campaign, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Campaign>>;
    public updateCampaign(organisationId: number, campaignId: string, body?: Campaign, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Campaign>>;
    public updateCampaign(organisationId: number, campaignId: string, body?: Campaign, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateCampaign.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling updateCampaign.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<Campaign>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update CampaignList detail
     * This API will update a CampaignList.
     * @param organisationId 
     * @param campaignListId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCampaignList(organisationId: number, campaignListId: string, body?: CampaignList, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CampaignList>;
    public updateCampaignList(organisationId: number, campaignListId: string, body?: CampaignList, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CampaignList>>;
    public updateCampaignList(organisationId: number, campaignListId: string, body?: CampaignList, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CampaignList>>;
    public updateCampaignList(organisationId: number, campaignListId: string, body?: CampaignList, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateCampaignList.');
        }
        if (campaignListId === null || campaignListId === undefined) {
            throw new Error('Required parameter campaignListId was null or undefined when calling updateCampaignList.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<CampaignList>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaign-lists/${encodeURIComponent(String(campaignListId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Contact detail
     * This API will update a contact.
     * @param organisationId 
     * @param contactId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateContact(organisationId: number, contactId: string, body?: Contact, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Contact>;
    public updateContact(organisationId: number, contactId: string, body?: Contact, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Contact>>;
    public updateContact(organisationId: number, contactId: string, body?: Contact, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Contact>>;
    public updateContact(organisationId: number, contactId: string, body?: Contact, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateContact.');
        }
        if (contactId === null || contactId === undefined) {
            throw new Error('Required parameter contactId was null or undefined when calling updateContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<Contact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/contacts/${encodeURIComponent(String(contactId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update pinnumber
     * This API will update a pin number.
     * @param organisationId 
     * @param pinNumberId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateContact_7(organisationId: number, pinNumberId: string, body?: PinNumber, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PinNumber>;
    public updateContact_7(organisationId: number, pinNumberId: string, body?: PinNumber, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PinNumber>>;
    public updateContact_7(organisationId: number, pinNumberId: string, body?: PinNumber, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PinNumber>>;
    public updateContact_7(organisationId: number, pinNumberId: string, body?: PinNumber, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateContact_7.');
        }
        if (pinNumberId === null || pinNumberId === undefined) {
            throw new Error('Required parameter pinNumberId was null or undefined when calling updateContact_7.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<PinNumber>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pin-numbers/${encodeURIComponent(String(pinNumberId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a domain setting by id
     * This API will update a setting by setting Id and organisation id
     * @param organisationId 
     * @param domainSettingId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDomainSetting(organisationId: number, domainSettingId: string, body?: DomainSetting, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<DomainSetting>;
    public updateDomainSetting(organisationId: number, domainSettingId: string, body?: DomainSetting, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<DomainSetting>>;
    public updateDomainSetting(organisationId: number, domainSettingId: string, body?: DomainSetting, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<DomainSetting>>;
    public updateDomainSetting(organisationId: number, domainSettingId: string, body?: DomainSetting, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateDomainSetting.');
        }
        if (domainSettingId === null || domainSettingId === undefined) {
            throw new Error('Required parameter domainSettingId was null or undefined when calling updateDomainSetting.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<DomainSetting>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/settings/${encodeURIComponent(String(domainSettingId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update all existing extensions (moh).
     * This API will update all existing extensions with any domain settings.
     * @param organisationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateExistingExtensions(organisationId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public updateExistingExtensions(organisationId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public updateExistingExtensions(organisationId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public updateExistingExtensions(organisationId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateExistingExtensions.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/update-extensions-moh`,
            null,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a lead status by id
     * This API will update a lead status.
     * @param organisationId 
     * @param leadStatusId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLeadStatus(organisationId: number, leadStatusId: string, body?: LeadStatus, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<LeadStatus>;
    public updateLeadStatus(organisationId: number, leadStatusId: string, body?: LeadStatus, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<LeadStatus>>;
    public updateLeadStatus(organisationId: number, leadStatusId: string, body?: LeadStatus, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<LeadStatus>>;
    public updateLeadStatus(organisationId: number, leadStatusId: string, body?: LeadStatus, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateLeadStatus.');
        }
        if (leadStatusId === null || leadStatusId === undefined) {
            throw new Error('Required parameter leadStatusId was null or undefined when calling updateLeadStatus.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<LeadStatus>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/lead-statuses/${encodeURIComponent(String(leadStatusId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a lead status by id
     * This API will update a lead status.
     * @param organisationId 
     * @param campaignId 
     * @param leadStatusDialRuleId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, body?: CampaignDialStatusRule, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<LeadStatus>;
    public updateLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, body?: CampaignDialStatusRule, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<LeadStatus>>;
    public updateLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, body?: CampaignDialStatusRule, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<LeadStatus>>;
    public updateLeadStatusDialRule(organisationId: number, campaignId: string, leadStatusDialRuleId: string, body?: CampaignDialStatusRule, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateLeadStatusDialRule.');
        }
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling updateLeadStatusDialRule.');
        }
        if (leadStatusDialRuleId === null || leadStatusDialRuleId === undefined) {
            throw new Error('Required parameter leadStatusDialRuleId was null or undefined when calling updateLeadStatusDialRule.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<LeadStatus>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/campaigns/${encodeURIComponent(String(campaignId))}/leadstatus-dial-rules/${encodeURIComponent(String(leadStatusDialRuleId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an Organisation by id
     * This API will update an organisation by organisation Id and organisation object.
     * @param organisationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisation(organisationId: number, body?: PabxOrganisation, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxOrganisation>;
    public updateOrganisation(organisationId: number, body?: PabxOrganisation, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxOrganisation>>;
    public updateOrganisation(organisationId: number, body?: PabxOrganisation, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxOrganisation>>;
    public updateOrganisation(organisationId: number, body?: PabxOrganisation, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateOrganisation.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<PabxOrganisation>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Call Block detail
     * This API will update Call Block detail.
     * @param organisationId 
     * @param callBlockId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisationsCallBlock(organisationId: number, callBlockId: string, body?: CallBlock, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<CallBlock>;
    public updateOrganisationsCallBlock(organisationId: number, callBlockId: string, body?: CallBlock, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<CallBlock>>;
    public updateOrganisationsCallBlock(organisationId: number, callBlockId: string, body?: CallBlock, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<CallBlock>>;
    public updateOrganisationsCallBlock(organisationId: number, callBlockId: string, body?: CallBlock, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateOrganisationsCallBlock.');
        }
        if (callBlockId === null || callBlockId === undefined) {
            throw new Error('Required parameter callBlockId was null or undefined when calling updateOrganisationsCallBlock.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<CallBlock>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/call-blocking/${encodeURIComponent(String(callBlockId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a Call Centre
     * This API will update a Call Centre.
     * @param organisationId 
     * @param callCentreId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisationsCallCentre(organisationId: number, callCentreId: string, body?: Callcentre, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Callcentre>;
    public updateOrganisationsCallCentre(organisationId: number, callCentreId: string, body?: Callcentre, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Callcentre>>;
    public updateOrganisationsCallCentre(organisationId: number, callCentreId: string, body?: Callcentre, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Callcentre>>;
    public updateOrganisationsCallCentre(organisationId: number, callCentreId: string, body?: Callcentre, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateOrganisationsCallCentre.');
        }
        if (callCentreId === null || callCentreId === undefined) {
            throw new Error('Required parameter callCentreId was null or undefined when calling updateOrganisationsCallCentre.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<Callcentre>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/callcentres/${encodeURIComponent(String(callCentreId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a Conference
     * This API will update a Conferences.
     * @param organisationId 
     * @param conferenceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisationsConference(organisationId: number, conferenceId: string, body?: Conference, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Conference>;
    public updateOrganisationsConference(organisationId: number, conferenceId: string, body?: Conference, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Conference>>;
    public updateOrganisationsConference(organisationId: number, conferenceId: string, body?: Conference, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Conference>>;
    public updateOrganisationsConference(organisationId: number, conferenceId: string, body?: Conference, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateOrganisationsConference.');
        }
        if (conferenceId === null || conferenceId === undefined) {
            throw new Error('Required parameter conferenceId was null or undefined when calling updateOrganisationsConference.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<Conference>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/conferences/${encodeURIComponent(String(conferenceId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update IVR Menu detail
     * This API will update IVR menu detail.
     * @param organisationId 
     * @param menuId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisationsIvrMenu(organisationId: number, menuId: string, body?: IvrMenu, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<IvrMenu>;
    public updateOrganisationsIvrMenu(organisationId: number, menuId: string, body?: IvrMenu, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<IvrMenu>>;
    public updateOrganisationsIvrMenu(organisationId: number, menuId: string, body?: IvrMenu, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<IvrMenu>>;
    public updateOrganisationsIvrMenu(organisationId: number, menuId: string, body?: IvrMenu, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateOrganisationsIvrMenu.');
        }
        if (menuId === null || menuId === undefined) {
            throw new Error('Required parameter menuId was null or undefined when calling updateOrganisationsIvrMenu.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<IvrMenu>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/ivr-menus/${encodeURIComponent(String(menuId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the PstnContacts detail for the Organisation
     * This API will update PstnContacts detail.
     * @param organisationId 
     * @param e164 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisationsPstnContact(organisationId: number, e164: number, body?: PstnContact, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<PstnContact>>;
    public updateOrganisationsPstnContact(organisationId: number, e164: number, body?: PstnContact, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<PstnContact>>>;
    public updateOrganisationsPstnContact(organisationId: number, e164: number, body?: PstnContact, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<PstnContact>>>;
    public updateOrganisationsPstnContact(organisationId: number, e164: number, body?: PstnContact, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateOrganisationsPstnContact.');
        }
        if (e164 === null || e164 === undefined) {
            throw new Error('Required parameter e164 was null or undefined when calling updateOrganisationsPstnContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<Array<PstnContact>>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pstn-contacts/${encodeURIComponent(String(e164))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Ring Group detail
     * This API will update Ring Group detail.
     * @param organisationId 
     * @param ringGroupId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisationsRingGroup(organisationId: number, ringGroupId: string, body?: RingGroup, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<RingGroup>;
    public updateOrganisationsRingGroup(organisationId: number, ringGroupId: string, body?: RingGroup, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<RingGroup>>;
    public updateOrganisationsRingGroup(organisationId: number, ringGroupId: string, body?: RingGroup, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<RingGroup>>;
    public updateOrganisationsRingGroup(organisationId: number, ringGroupId: string, body?: RingGroup, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateOrganisationsRingGroup.');
        }
        if (ringGroupId === null || ringGroupId === undefined) {
            throw new Error('Required parameter ringGroupId was null or undefined when calling updateOrganisationsRingGroup.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<RingGroup>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/ring-groups/${encodeURIComponent(String(ringGroupId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the Schedules detail for the Organisation
     * This API will update Schedules detail.
     * @param organisationId 
     * @param scheduleUuid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrganisationsSchedule(organisationId: number, scheduleUuid: string, body?: TimeSchedule, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<TimeSchedule>;
    public updateOrganisationsSchedule(organisationId: number, scheduleUuid: string, body?: TimeSchedule, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<TimeSchedule>>;
    public updateOrganisationsSchedule(organisationId: number, scheduleUuid: string, body?: TimeSchedule, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<TimeSchedule>>;
    public updateOrganisationsSchedule(organisationId: number, scheduleUuid: string, body?: TimeSchedule, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updateOrganisationsSchedule.');
        }
        if (scheduleUuid === null || scheduleUuid === undefined) {
            throw new Error('Required parameter scheduleUuid was null or undefined when calling updateOrganisationsSchedule.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<TimeSchedule>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/schedules/${encodeURIComponent(String(scheduleUuid))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the PabxContact by extension for the User
     * This API will update a PabxContact.
     * @param organisationId 
     * @param extension 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePabxContact(organisationId: number, extension: number, body?: PabxContact, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxContact>;
    public updatePabxContact(organisationId: number, extension: number, body?: PabxContact, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxContact>>;
    public updatePabxContact(organisationId: number, extension: number, body?: PabxContact, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxContact>>;
    public updatePabxContact(organisationId: number, extension: number, body?: PabxContact, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updatePabxContact.');
        }
        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updatePabxContact.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<PabxContact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(extension))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the PabxContact by extension for the User
     * This API will update a PabxContact.
     * @param organisationId 
     * @param uuid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePabxContact_8(organisationId: number, uuid: string, body?: PabxContact, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PabxContact>;
    public updatePabxContact_8(organisationId: number, uuid: string, body?: PabxContact, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PabxContact>>;
    public updatePabxContact_8(organisationId: number, uuid: string, body?: PabxContact, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PabxContact>>;
    public updatePabxContact_8(organisationId: number, uuid: string, body?: PabxContact, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling updatePabxContact_8.');
        }
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling updatePabxContact_8.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<PabxContact>(`${this.basePath}/rest/v1/pabx-organisations/${encodeURIComponent(String(organisationId))}/pabx-contacts/${encodeURIComponent(String(uuid))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

 }
