// import { CampaignEvent, CallcentreAgent, Callcentre, RouteableApp, Organisation as PabxOrganisation, AudioFile, DomainSetting, CallSetup, Contact, ActiveCall, EavesDropSetup, CampaignList, Campaign, CampaignLead, LeadStatus, Conference, CampaignCallSetup, CampaignLeadDispositionInfo, CallcentreAgentGroup, CampaignListEvent, RingGroup } from "./services/service-modules/pabx-service";
import { ModalData } from "./models/modal-data.model";
// import { PabxContact, PstnContact, Organisation as UserOrganisation, Organisation } from "./services/service-modules/conductor-service";
import { ExtensionStatus } from "./models/extension-status.model";
import { UserOrganisationWithWallet } from './models/organisation-with-wallet.model';
import { CampaignEvent, CallcentreAgent, Callcentre, RouteableApp, Organisation as PabxOrganisation, AudioFile, DomainSetting, CallSetup, Contact, ActiveCall, EavesDropSetup, CampaignList, Campaign, CampaignLead, LeadStatus, Conference, CampaignCallSetup, CampaignLeadDispositionInfo, CallcentreAgentGroup, CampaignListEvent, RingGroup, PstnContact, PabxContact, Organisation } from './services/service-modules/buzzbox-services';

export class AppStateModel {
    token?: string;
    organisationId?: number;
    email?: string;
    name?: string;
    otp?: string;
    mobile?: string;
    userId?: number;
    walletIds?: number[] = [];
    routeableApps?: RouteableApp[];
    loggedInPabxContacts?: PabxContact[] = [];
    domainPabxContacts?: PabxContact[] = [];
    domainPstnContacts?: PstnContact[] = [];
    searchPstnContacts?: PstnContact[] = [];
    searchedOrganisations?: UserOrganisationWithWallet[] = [];
    payments?: any[] = [];
    roles?: string[] = [];
    callcentres?: Callcentre[] = [];
    callcentreAgents?: Map<String, CallcentreAgent> = new Map();
    campaignListsMap?: Map<String, CampaignList> = new Map();
    callcentreAgentGroups?: Map<String, CallcentreAgentGroup> = new Map();
    extensionStatus?: Map<String, ExtensionStatus> = new Map();
    organisation?: PabxOrganisation;
    audioFiles?: AudioFile[] = [];
    audioAlbums?: string[] = [];
    paymentModalData?: ModalData;
    webhookSetting?: DomainSetting;
    activeExtension?: string = "not set";
    agentActiveExtension?: string;
    activeCalls?: ActiveCall[] = [];
    contacts?: Contact[] = [];
    departments?: Callcentre[] = [];
    conferences?: Conference[] = [];
    departmentsDict?: Map<String, Callcentre> = new Map();
    conferencesDict?: Map<String, Conference> = new Map();
    campaignLists?: CampaignList[] = [];
    campaigns?: Campaign[] = [];
    agentCampaigns?: Campaign[] = [];
    agentActiveCampaign?: Campaign;
    activeLead?: CampaignLead = null;
    leadStatuses?: LeadStatus[];
    wsSessionId?: String;
    websocketConnected?: boolean;
    showHiddenMenus?: boolean = false;
    ringGroups?: RingGroup[] = [];
    ringGroupsDict?: Map<String, RingGroup> = new Map();
    campaignAgents?: Map<String, CallcentreAgent> = new Map();
}

export class Signin {
    static readonly type = '[Auth] Signin';
    constructor(public email: string, public password: string) { }
}

export class Signup {
    static readonly type = '[Auth] Signup';
    constructor(public regUserData: any) { }
}

export class RefreshAuthToken {
    static readonly type = '[Auth] RefreshToken';

    constructor(public currentToken: string) { }
}

export class Signout {
    static readonly type = '[Auth] Signout';
}

export class VerifyOtps {
    static readonly type = '[Auth] VerifyOtps';
    constructor(
        public email: string,
        public emailOtp: string, 
        public phone: string,
        public mobileOtp: string) { }
}

export class SendOtps {
    static readonly type = '[Auth] SendOtps';
    constructor(
        public email: string,
        public phone: string,
        ) { }
}


export class RefreshCallCentreData {
    static readonly type = "[Callcentre] RefreshData"
}

export class RefreshRouteableApps {
    static readonly type = "[Core] RefreshRouteableApps"
}

export class RefreshUsersPabxContacts {
    static readonly type = "[PabxContacts] RefreshUsersPabxContacts"
    constructor() { }
}

export class RefreshDomainPabxContacts {
    static readonly type = "[PabxContacts] RefreshDomainPabxContacts"
    constructor() { }
}

export class RefreshAudioData {
    static readonly type = "[Audio] RefreshAudioData"
    constructor(public metaOnly: boolean) { }

}

export class UpdateAudioAlbums {
    static readonly type = "[Audio] UpdateAudioAlbums"
    constructor(public albums: Array<string>) { }
}

export class InitiateModalPayment {
    static readonly type = "[Payment] InitiateModalPayment"
    constructor(public modalData: ModalData) { }
}

export class DeletePaymentModal {
    static readonly type = "[Payment] DeletePaymentModal"
}

export class UpdateWebHook {
    static readonly type = "[Advanced] UpdateWebHook"
    constructor(public webhookSetting: DomainSetting) { }
}

export class GetWebHook {
    static readonly type = "[Advanced] GetWebHook"
}

export class CreateWebHook {
    static readonly type = "[Advanced] CreateWebHook"
    constructor(public url: string) { }
}

export class ChannelStateEvent {
    static readonly type = "[Realtime] ChannelStateEvent"
    constructor(public data: any) { }
}

export class InitiateCall {
    static readonly type = "[Calls] InitiateCall"
    constructor(public data: CallSetup) { }
}

export class InitiateEavesDrop {
    static readonly type = "[realtime] InitiateEavesDrop"
    constructor(public data: EavesDropSetup) { }
}

export class UpdateActiveExtension {
    static readonly type = "[Realtime] UpdateActiveExtension"
    constructor(public extension: string) { }
}

export class GetActiveCalls {
    static readonly type = "[Realtime] GetActiveCalls"
    constructor() { }
}

export class GetContacts {
    static readonly type = "[Contact] GetContacts"
    constructor(public organisationId: number) { }
}

export class AddContact {
    static readonly type = "[Contact] AddContact"
    constructor(public contact: Contact) { }
}

export class UpdateContact {
    static readonly type = "[Contact] UpdateContact"
    constructor(public contact: Contact) { }
}

export class DeleteContact {
    static readonly type = "[Contact] deleteContact"
    constructor(public contactUuid: string) { }
}

export class GetPstnContactsForOrganisation {
    static readonly type = "[PstnContacts] getPstnContactsForOrganisation"
    constructor(public organisationId: number) { }
}

export class GetPstnContacts {
    static readonly type = "[PstnContacts] getPStnContacts"
    constructor(
        public status: string = null,
        public search: string = null,
        public offset: number = null,
        public limit: number = null,
        
    ) { }
}

export class ReserveSignupNumber {
    static readonly type = "[PstnContacts] ReserveSignupNumber"
    constructor(public pstnContact: PstnContact) { }
}

/* RING GROUP METHODS */
export class GetRingGroupsForOrganisation {
    static readonly type = "[Ringgroups] GetRingGroupsForOrganisation"
    constructor(public organisationId: number) { }
}
/* END RING GROUP METHODS */


/* DEPARTMENT METHODS */
export class GetDepartmentsForOrganisation {
    static readonly type = "[Departments] GetDepartmentsForOrganisation"
    constructor(public organisationId: number) { }
}

export class DeleteDepartment {
    static readonly type = "[Departments] DeleteDepartment"
    constructor(public organisationId: number, public callcentreId: string) { }
}

export class CreateDepartment {
    static readonly type = "[Departments] CreateDepartment"
    constructor(public organisationId: number, public data: Callcentre) { }
}

export class UpdateDepartment {
    static readonly type = "[Departments] UpdateDepartment"
    constructor(public organisationId: number, public data: Callcentre) { }
}

/* CALLCENTER CAMPAIGN STATS METHODS */
export class GetCallcenterCampaignStats {
    static readonly type = "[CampaignStats] GetCallcenterCampaignStats"
    constructor(public organisationId: number, public campaignId: string) { }
}

export class GetCallcenterCampaignListStats {
    static readonly type = "[CampaignListStats] GetCallcenterCampaignListStats"
    constructor(public organisationId: number, public campaignListId: string) { }
}

/* CALLCENTER AGENT GROUPS METHODS */
export class GetCallcenterAgentGroups {
    static readonly type = "[CallcentreAgentGroup] GetCallcenterAgentGroups"
    constructor(public organisationId: number) { }
}

export class DeleteCallcenterAgentGroups {
    static readonly type = "[CallcentreAgentGroup] DeleteCallcenterAgentGroups"
    constructor(public organisationId: number, public agentGroupId: string) { }
}

export class CreateCallcenterAgentGroups {
    static readonly type = "[CallcentreAgentGroup] CreateCallcenterAgentGroups"
    constructor(public organisationId: number, public data: CallcentreAgentGroup) { }
}

export class UpdateCallcenterAgentGroups {
    static readonly type = "[CallcentreAgentGroup] UpdateCallcenterAgentGroups"
    constructor(public organisationId: number, public data: CallcentreAgentGroup) { }
}




/* CONFERENCE METHODS */
export class GetConferencesForOrganisation {
    static readonly type = "[Conferences] GetConferencesForOrganisation"
    constructor(public organisationId: number) { }
}

export class DeleteConference {
    static readonly type = "[Conference] DeleteConference"
    constructor(public organisationId: number, public conferenceId: string) { }
}

export class CreateConference {
    static readonly type = "[Conferences] CreateConference"
    constructor(public organisationId: number, public data: Conference) { }
}

export class UpdateConference {
    static readonly type = "[Conferences] UpdateConference"
    constructor(public organisationId: number, public data: Conference) { }
}

/* CAMPAIGN AGENT METHODS */
export class GetCampaignAgentsForOrganisation {
    static readonly type = "[Campaign Agent] GetCampaignAgentsForOrganisation"
    constructor(public organisationId: number) { }
}

/* CAMPAIGN METHODS */
export class AddCampaignList {
    static readonly type = "[Campaigns] AddCampaignList"
    constructor(
        public campaignList: CampaignList,
        public campaignUuid: string) { }
}

export class GetCampaignListsForOrganisation {
    static readonly type = "[Campaigns] GetCampaignListsForOrganisation"
    constructor(public organistaionId: number) { }
}

// export class GetCampaignAgentGroupsForOrganisation {
//     static readonly type = "[Campaigns] GetCampaignAgentGroupsForOrganisation"
//     constructor(public organistaionId: number) { }
// }

export class GetCampaignList {
    static readonly type = "[Campaigns] GetCampaignList"
    constructor(public campaignListUuid: string) { }
}

export class DeleteCampaignList {
    static readonly type = "[Campaigns] DeleteCampaignList"
    constructor(public campaignListUuid: string) { }
}

export class UpdateCampaignList {
    static readonly type = "[Campaigns] UpdateCampaignList"
    constructor(public campaignList: CampaignList) { }
}

export class CreateCampaign {
    static readonly type = "[Campaigns] CreateCampaign"
    constructor(public organisationId: number, public campaign: Campaign) { }
}

export class GetCampaignsForOrganisation {
    static readonly type = "[Campaigns] GetCampaignsForOrganisation"
    constructor(public organistaionId: number) { }
}

export class GetCampaignsForOrganisationAndUser {
    static readonly type = "[Campaigns] GetCampaignsForOrganisationAndUser"
    constructor(
        public organistaionId: number, 
        public userId: number,
        public onlyActiveInSchedule: boolean) { }
}

export class GetCampaign {
    static readonly type = "[Campaigns] GetCampaign"
    constructor(public campaignUuid: string) { }
}

export class UpdateCampaign {
    static readonly type = "[Campaigns] UpdateCampaign"
    constructor(public campaign: Campaign) { }
}

export class UpdateCampaignState {
    static readonly type = "[Campaigns] UpdateCampaignState"
    constructor(public campaignUuid: string, public event: CampaignEvent) { }
}

export class UpdateCampaignListState {
    static readonly type = "[Campaigns] UpdateCampaignListState"
    constructor(public campaignListUuid: string, public event: CampaignListEvent) { }
}

export class DeleteCampaign {
    static readonly type = "[Campaigns] DeleteCampaign"
    constructor(
        public campaignUuid: string) { }
}

export class agentLoginToCampaign {
    static readonly type = "[Campaigns] agentLoginToCampaign"
    constructor(
        public campaign: Campaign,
        public organisationId: number, 
        public userId: number, 
        public extension: string,
        public password: string,
        ) { }
}

export class getNextCampaignLead {
    static readonly type = "[Campaigns] getNextCampaignLead"
    constructor(public campaignUuid: string, 
        public organisationId: number,
        public userId: number,
        public agentExtension: string,
        public status: string,
        public setStatus: string,
        public campaignType: string,
        ) { }
}

export class GetLeadStatuses {
    static readonly type = "[LeadStatus] GetLeadStatuses"
    constructor(public organisationId: number, public campaignId?: string, public agentSelectable?: boolean, public includeSystemStatuses?) { }
}

export class DeleteLeadStatus {
    static readonly type = "[LeadStatus] DeleteLeadStatus"
    constructor(public organisationId: number, public leadStatusUuid: string, public absolutelySure?: boolean) { }
}

export class UpdateLeadStatus {
    static readonly type = "[LeadStatus] UpdateLeadStatus"
    constructor(public organisationId: number, public leadStatus: LeadStatus) { }
}

export class CreateLeadStatus {
    static readonly type = "[LeadStatus] CreateLeadStatus"
    constructor(public organisationId: number, public leadStatus: LeadStatus) { }
}

export class GetCampaignListLead {
    static readonly type = "[Campaigns] GetCampaignListLead"
    constructor(public campaignListUuid: string, public leadUuid: string, public organisationId: number) { }
}

export class SetAgentActiveCampaign {
    static readonly type = "[Campaigns] SetAgentActiveCampaign"
    constructor(public campaign: Campaign) { }
}


/************************************ */

/********** CAMPAIGN LEAD METHODS ***********************/

export class DialCampaignLead {
    static readonly type = "[Campaigns] DialCampaignLead"
    constructor(
        public callsetup: CampaignCallSetup,
        public campaignUuid: string,
        public organisationId: number,
        public userId: number,
    ) { }
}

export class DispositionCampaignLead {
    static readonly type = "[Campaigns] DispositionCampaignLead"
    constructor(
        public dispositionInfo: CampaignLeadDispositionInfo,
        public campaignListUuid: string, 
        public leadUuid: string, 
        public organisationId: number
    ) { }
}

export class setActiveCampaignLead {
    static readonly type = "[Campaigns] setActiveCampaignLead"
    constructor(public lead: CampaignLead) { }
}

/************************************ */


/**********ADMIN ***********************/
export class SearchOrganisations {
    static readonly type = "[Admin] SearchOrganisations"
    constructor(
        public name: string = null, 
        public email: string = null,
        public offset: number = 0,
        public limit: number = 0) { }
}

export class UpdateUserOrganisation {
    static readonly type = "[Admin] UpdateUserOrganisation"
    constructor(
        public organisation: Organisation, 
    ) { }
}

export class DeactivateOrganisation {
    static readonly type = "[Admin] DeactivateOrganisation"
    constructor(
        public organisationId: number, 
        public accountId: number
        ) { }
}

export class ActivateOrganisation {
    static readonly type = "[Admin] ActivateOrganisation"
    constructor(
        public organisationId: number, 
        public accountId: number
    ) { }
}

export class BulkAddPstnNumbers {
    static readonly type = "[Admin] BulkAddPstnNumbers"
    constructor(
        public numbersToAdd: PstnContact[], 
    ) { }
}

/* WEBSOCKET */
export class WebSocketErrorMessage {
    static readonly type = "[WebSocket] Error"
    constructor(public errors: any) {}
}

export class EnableHiddenMenus {
    static readonly type = "[General] EnableHiddenMenus"
    constructor() {}
}
















