import { AsyncValidatorFn, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS, AsyncValidator } from "@angular/forms";
import { Observable, of } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { Directive } from "@angular/core";
import { Store } from "@ngxs/store";
import { PabxService, RouteableApp } from '../../services/service-modules/buzzbox-services';

export function existingExtensionValidator(store: Store, pabxService: PabxService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        return pabxService.getOrganisationsRoutableApps(store.selectSnapshot(state=>state.global.organisationId)).pipe(
            switchMap((routeableApps: RouteableApp[]) => {
                let extensions = [];
                routeableApps.forEach(app => {
                    extensions.push(app.extension);
                })
                // console.log('received routeable apps', extensions);
                // console.log('checking', control.value);
                return (!control.pristine && extensions && extensions.length > 0 && extensions.indexOf(control.value) > -1) ? of({ "extensionUsed": true }) : of(null);
            }),
            catchError( (err) => {return of({ "extensionUsed": true })})
            );
    };
}

@Directive({
    selector: '[extNumExists][formControlName],[extNumExists][formControl],[extNumExists][ngModel]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: ExistingExtensionValidatorDirective, multi: true }]
})
export class ExistingExtensionValidatorDirective implements AsyncValidator {
    constructor(private store: Store, private pabxService: PabxService) { }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return existingExtensionValidator(this.store, this.pabxService)(control);
    }
} 