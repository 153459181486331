import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '../../services/logger/logger.service';
import { ModalService } from '../../services/modal.service';
import { ModalData } from '../../models/modal-data.model';
import { Subscription } from 'rxjs';
import { getNamesFromFullName, getFullNameFromNames } from '../../utils/utils';
import { Store } from '@ngxs/store';
import { NbToastrService } from '@nebular/theme';
import { User, UserService } from '../../services/service-modules/buzzbox-services';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})

export class UserComponent implements OnInit {

  editForm: FormGroup;
  modalData: ModalData;
  user: User;
  userData: any;
  subscription: Subscription;
  userId: any;

  constructor(private fb: FormBuilder,
    private modalService: ModalService,
    private userService: UserService,
    private logger: LoggerService,
    private toasterService: NbToastrService,
    private store: Store,
  ) {

    this.subscription = this.store.subscribe(state => {
      this.userId = state.global.userId;
      this.createForm();
      this.setUserInfo(state.global.userId);
    })
    this.modalData = this.modalService.modalData;
    // if (this.modalData.isUpdate) {
    //   this.editData = this.modalData.data;
    // }
    
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalService.closeModal();
  }

  createForm() {
    this.editForm = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern('^\\S+ \\S+.*')])],
      email: [''],
      phone1: ['', Validators.compose([Validators.maxLength(12), Validators.pattern('^[\+]?[0-9]*$')])],
    });
  }

  setUserInfo(userId) {
    this.userService.getUser(userId).subscribe(
      user => {
        this.user = user;
        this.editForm.controls['name'].setValue(getFullNameFromNames(user.firstName, user.middleName, user.lastName));
        this.editForm.controls['email'].setValue(user.email1);
        this.editForm.controls['phone1'].setValue(user.phone1);
      }, error => {
        this.logger.error('Error while setting User Update from :  => ', error);
      }, () => {
        this.logger.info('complete');
      });
  }

  onSubmit(data) {
    this.logger.info('Update Profile Fields : ', data);
    this.userService.getUser(this.userId).subscribe((userResponse: User) => {
        const nameParts = getNamesFromFullName(data.name);
        userResponse.firstName = nameParts.firstName;
        userResponse.middleName = nameParts.middleName;
        userResponse.lastName = nameParts.lastName;
        userResponse.phone1 = data.phone1;
        this.userService.updateUser(userResponse.userId, userResponse).subscribe((updateUser: User) => {
          this.logger.info('User has been successfully updated.');
          this.dismiss();
          this.toasterService.success('profile updated successfully');
        }, updateError => {
          this.logger.error('Error while Updating user : ', updateError);
          this.toasterService.danger('Unable to update user profile, please try again.')
        })
      }, userError => {
        this.logger.error('Error while getting user : ', userError);
        this.toasterService.danger('Unable to update user profile, please try again.')
      })
  }
}
