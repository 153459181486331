import { State, Selector, StateContext, Action } from '@ngxs/store';
import { AppStateModel, RefreshCallCentreData, Signin, Signout, RefreshAuthToken, RefreshRouteableApps, RefreshAudioData, UpdateAudioAlbums, Signup, InitiateModalPayment, DeletePaymentModal, RefreshUsersPabxContacts, UpdateWebHook, GetWebHook, CreateWebHook, ChannelStateEvent, RefreshDomainPabxContacts, VerifyOtps, SendOtps, InitiateCall, UpdateActiveExtension, AddContact, UpdateContact, DeleteContact, GetContacts, GetActiveCalls, InitiateEavesDrop, ReserveSignupNumber, AddCampaignList, GetCampaignListsForOrganisation, GetCampaign, GetCampaignsForOrganisation, GetCampaignList, DeleteCampaignList, CreateCampaign, UpdateCampaign, DeleteCampaign, GetDepartmentsForOrganisation, GetPstnContacts, GetPstnContactsForOrganisation, SearchOrganisations, UpdateUserOrganisation, DeactivateOrganisation, ActivateOrganisation, BulkAddPstnNumbers, UpdateCampaignState, GetCampaignsForOrganisationAndUser, agentLoginToCampaign, getNextCampaignLead, GetLeadStatuses, DeleteDepartment, UpdateDepartment, CreateDepartment, GetConferencesForOrganisation, UpdateConference, CreateConference, DeleteConference, WebSocketErrorMessage, EnableHiddenMenus, DialCampaignLead, GetCampaignListLead, DispositionCampaignLead, GetCallcenterAgentGroups, DeleteCallcenterAgentGroups, UpdateCallcenterAgentGroups, CreateCallcenterAgentGroups, GetCallcenterCampaignStats, GetCallcenterCampaignListStats, SetAgentActiveCampaign, DeleteLeadStatus, CreateLeadStatus, UpdateLeadStatus, UpdateCampaignListState, GetRingGroupsForOrganisation, GetCampaignAgentsForOrganisation, setActiveCampaignLead } from './app-state-model';
// import { ActiveCall, PabxService, Callcentre, CallcentreAgent, RouteableApp, AudioFile, Organisation as PabxOrganisation, DomainSetting, CampaignList, Campaign, CampaignLead, LeadStatus, Conference, CampaignCallSetup, CallcentreAgentGroup, CampaignStats, CampaignListStats, CallcentreAgentLoginData, RingGroup } from './services/service-modules/pabx-service';
import { tap, concatMap, catchError, mergeMap, map, switchMap } from 'rxjs/operators';
// import { JwtAuthCredentials, Jwt, AuthenticationService, Organisation, PabxContact, PstnContact } from './services/service-modules/conductor-service';
import * as jwt_decode from "jwt-decode";
// import { UserService, UserRole, UserIdentity, User, UserPosition, Address } from './services/service-modules/user-service';
// import { WalletService, Wallet } from './services/service-modules/wallet-service';
import { of, forkJoin } from 'rxjs';
import { ExtensionStatus } from './models/extension-status.model';
import { LoggerService } from './services/logger/logger.service';
// import { PoSService, Account } from './services/service-modules/pos-service';
import { UserOrganisationWithWallet } from './models/organisation-with-wallet.model';
import { WebSocketDisconnected, ConnectWebSocket } from '@ngxs/websocket-plugin';
import { isNullOrUndefined } from 'util';
import { PoSService, ActiveCall, PabxService, Callcentre, CallcentreAgent, RouteableApp, AudioFile, Organisation as PabxOrganisation, DomainSetting, CampaignList, Campaign, CampaignLead, LeadStatus, Conference, CampaignCallSetup, CallcentreAgentGroup, CampaignStats, CampaignListStats, CallcentreAgentLoginData, RingGroup, UserService, AuthenticationService, User, UserPosition, Wallet, Jwt, UserIdentity, PstnContact, JwtAuthCredentials, UserRole, Address, WalletService, Account } from './services/service-modules/buzzbox-services';
import { ConductorOrganisation as Organisation } from './services/service-modules/buzzbox-services/model/conductorOrganisation';
import { ConductorPabxContact as PabxContact } from './services/service-modules/buzzbox-services/model/conductorPabxContact';
@State<AppStateModel>({
    name: 'global'
})

export class AppState {
    @Selector()
    static token(state: AppStateModel) {
        return state.token;
    }

    @Selector()
    static allCallcentres(state: AppStateModel) {
        return state.callcentres;
    }

    @Selector()
    static allCallcentreAgents(state: AppStateModel) {
        return state.callcentreAgents;
    }

    @Selector()
    static allCampaignAgents(state: AppStateModel) {
        return state.campaignAgents;
    }

    @Selector()
    static allCallcentreAgentGroups(state: AppStateModel) {
        return state.callcentreAgentGroups;
    }

    @Selector()
    static allLeadStatuses(state: AppStateModel) {
        return state.leadStatuses;
    }

    @Selector()
    static allCampaigns(state: AppStateModel) {
        return state.campaigns;
    }

    @Selector()
    static allCampaignLists(state: AppStateModel) {
        return state.campaignLists;
    }

    @Selector()
    static userDetails(state: AppStateModel) {
        return {
            name: state.name,
            email: state.email,
            mobile: state.mobile,
        };
    }

    @Selector()
    static allOrganisationsWallets(state: AppStateModel) {
        return state.walletIds;
    }

    @Selector()
    static currentOrganisationId(state: AppStateModel) {
        return state.organisationId;
    }

    @Selector()
    static organisationsPstnContacts(state: AppStateModel) {
        return state.domainPstnContacts;
    }

    @Selector()
    static roles(state: AppStateModel) {
        return state.roles;
    }

    @Selector()
    static allOrganisations(state: AppStateModel) {
        return state.searchedOrganisations;
    }

    constructor(
        private pabxService: PabxService,
        private userService: UserService,
        private walletService: WalletService,
        private authService: AuthenticationService,
        private logger: LoggerService,
        private posService: PoSService,
    ) {

    }

    @Action(Signin)
    Signin(
        ctx: StateContext<AppStateModel>,
        { email, password }: Signin
    ) {
        this.logger.info('signing in');
        let jwtRequest: JwtAuthCredentials = {
            identity: email,
            password: password,
        };

        const patchState = ctx.patchState;
        const state = ctx.getState();
        ctx.setState({});
        return this.authService.login(jwtRequest).pipe(
            tap((result: Jwt) => {
                let decoded: any = JwtUtils.getDecodedAccessToken(result.headerValue.split(' ')[1]);
                this.logger.info('decoded is ' + JSON.stringify(decoded) + '[' + result.headerValue.split(' ')[1] + ']');
                this.logger.info('adding roles: ', decoded.roles.split(','));
                this.logger.info('websocket session id is:', result.sessionId);
                patchState({
                    wsSessionId: result.sessionId,
                    token: result.headerValue,
                    roles: decoded.roles.split(',')
                });
            }),
            concatMap(() => {
                return this.userService.authenticateUser(<UserIdentity>jwtRequest)
            }),
            tap((result: User) => {
                patchState({
                    email: result.email1,
                    name: result.firstName,
                    mobile: result.phone1,
                    userId: result.userId,
                });
            }),
            concatMap((result: User) => {
                return this.userService.getUsersPositions(result.userId)
            }),
            tap(positions => {
                this.logger.info('setting org id to', positions[0].organisationId);
                patchState({
                    organisationId: positions[0].organisationId
                })
            }),
            concatMap((result: UserPosition[]) => {
                return this.pabxService.getOrganisationById(result[0].organisationId)
            }),
            tap((organisation: PabxOrganisation) => {
                patchState({
                    organisation: organisation
                })
            }),
            concatMap((organisation: PabxOrganisation) => {
                return this.walletService.getWallets(null, null, null, organisation.organisationId)
            }),
            tap((wallets: Wallet[]) => {
                let walletIds: number[] = [];
                wallets.forEach(element => {
                    walletIds.push(element.walletId);
                });
                patchState({
                    walletIds: walletIds
                })
                ctx.dispatch(new RefreshRouteableApps());
            }),
        );
    }

    @Action(WebSocketDisconnected)
    WebSocketDisconnected(
        ctx: StateContext<AppStateModel>
    ) {
        this.logger.info('Websocket has been disconnected');
        ctx.patchState({
            websocketConnected: false
        })
    }

    @Action(ConnectWebSocket)
    ConnectWebSocket(
        ctx: StateContext<AppStateModel>
    ) {
        this.logger.info('Websocket being connected');
        ctx.patchState({
            websocketConnected: true
        })
    }

    @Action(WebSocketErrorMessage)
    WebSocketErrorMessage(
        ctx: StateContext<AppStateModel>,
        { errors }: WebSocketErrorMessage
    ) {
        this.logger.info('received websocket error message on websocket channel', errors);
        if (errors[0].code === "UNK001") {
            ctx.patchState({
                wsSessionId: null
            })
        }
    }

    @Action(Signup)
    Signup(
        ctx: StateContext<AppStateModel>,
        { regUserData }: Signup
    ) {
        this.logger.info('signing up');
        const patchState = ctx.patchState;
        const state = ctx.getState();
        let organisationId: number;

        let companyName: string = regUserData.companyName;
        companyName = companyName.replace('&', 'and');
        regUserData.companyName = companyName;
        let org: Organisation = {
            companyNumber: '',
            email: regUserData.email,
            name: regUserData.companyName,
            promotionCode: regUserData.promotionCode,
            phone1: regUserData.phoneNumber,
            phone2: '' + regUserData.firstPstnNumber,
            organisationAddresses: [<Address>{
                addressType: 'home',
                code: regUserData.code,
                country: regUserData.country,
                state: regUserData.province,
                line1: regUserData.addressLine1,
                line2: regUserData.addressLine2,
                line3: regUserData.addressLine3,
            }]
        };

        let pstnContact: PstnContact = {
            e164: regUserData.firstPstnNumber,
        }

        let roles = [];
        roles.push('DomainAdmin');
        roles.push('Extension');

        let pabxContact: PabxContact = {
            extension: 200,
            name: regUserData.firstName + ' ' + regUserData.lastName,
            userId: regUserData.userId,
            organisationId: regUserData.organisationID,
            email: regUserData.email,
            phone: regUserData.phoneNumber,
            roles: roles,
        }
        return this.userService.createOrganisation(org).pipe(
            concatMap((res: Organisation) => {
                pstnContact.organisationId = res.organisationId;
                organisationId = res.organisationId;
                pabxContact.organisationId = organisationId;
                patchState({
                    organisationId: organisationId,
                })
                return this.userService.createUser(this.buildNewUser(organisationId, regUserData));
            }),
            concatMap((res: User) => {
                patchState({
                    userId: res.userId,
                })
                pabxContact.userId = res.userId;
                return ctx.dispatch(new Signin(regUserData.email, regUserData.password))
            }),
            concatMap(() => {
                return this.pabxService.createOrganisationPstnContact(organisationId, pstnContact);
            }),
            concatMap((res: PstnContact[]) => {
                return this.pabxService.createPabxContact(organisationId, pabxContact);
            }));
    }

    @Action(Signout)
    Signout({ setState, getState }: StateContext<AppStateModel>) {
        this.logger.info('signing out');
        const { token } = getState();
        return this.authService.logout().pipe(
            tap(_ => {
                setState({});
            }),
            catchError(x => {
                setState({});
                return of(null);
            })
        );
    }

    @Action(VerifyOtps)
    VerifyOtps({ setState, getState }: StateContext<AppStateModel>,
        { email, emailOtp, phone, mobileOtp }: VerifyOtps) {
        this.logger.info('verifying OTPs');
        // const { token } = getState();

        return this.userService.checkVerificationCode('' + email, emailOtp).pipe(
            mergeMap(() => {
                return this.userService.checkVerificationCode('' + phone, mobileOtp)
            })
        )
    }

    @Action(SendOtps)
    SendOtps({ setState, getState }: StateContext<AppStateModel>,
        { email, phone }: SendOtps) {
        this.logger.info('Sedning OTPs');

        return this.userService.sendVerificationCode([email, phone])
    }

    @Action(RefreshAuthToken)
    RefreshAuthToken(
        { patchState }: StateContext<AppStateModel>,
        { currentToken }: RefreshAuthToken
    ) {
        this.logger.info('Refreshing auth token using current: ', currentToken);
        let jwtRequest: JwtAuthCredentials = {};
        jwtRequest.jwt = currentToken
        return this.authService.renew(jwtRequest).pipe(
            tap(result => {
                patchState({
                    token: result.headerValue,
                })
            })
        );
    }

    @Action(RefreshCallCentreData)
    RefreshCallCentreData(
        ctx: StateContext<AppStateModel>,
    ) {
        const state = ctx.getState();
        // this.logger.info('refreshing callcentre data for organisation: ', state.organisationId);

        return this.pabxService.getOrganisationsCallCentres(state.organisationId).pipe(
            tap((callcentres: Callcentre[]) => {
                this.logger.info('received callcentres: ', callcentres);
                let agents: Map<String, CallcentreAgent> = new Map();
                callcentres.forEach((callcentre) => {
                    callcentre.agents.forEach((agent) => {
                        agents.set(agent.agentUuid, agent);
                    })
                })
                ctx.patchState({
                    callcentres: callcentres,
                    callcentreAgents: agents,
                })
            })
        )
    }

    @Action(RefreshRouteableApps)
    RefreshRouteableApps(
        ctx: StateContext<AppStateModel>,
    ) {
        const state = ctx.getState();
        return this.pabxService.getOrganisationsRoutableApps(state.organisationId).pipe(
            tap((routeableApps: RouteableApp[]) => {
                this.logger.info('received routeable apps: ', routeableApps);
                ctx.patchState({
                    routeableApps: routeableApps,
                })
            })
        )
    }

    @Action(RefreshAudioData)
    RefreshAudioData(
        ctx: StateContext<AppStateModel>,
        { metaOnly }: RefreshAudioData
    ) {
        const state = ctx.getState();
        return this.pabxService.getAudioFiles(state.organisationId, null, metaOnly).pipe(
            tap((audioFiles: AudioFile[]) => {
                this.logger.info('received audio files: ', audioFiles);
                ctx.patchState({
                    audioFiles: audioFiles,
                })
            })
        )
    }

    @Action(RefreshUsersPabxContacts)
    RefreshUsersPabxContacts(
        ctx: StateContext<AppStateModel>,
    ) {
        const state = ctx.getState();
        return this.pabxService.getOrganisationsPabxContacts(state.organisationId, null, null, '' + state.userId).pipe(
            tap((pabxContacts: PabxContact[]) => {
                this.logger.info('received users pabx contacts: ', pabxContacts);
                ctx.patchState({
                    loggedInPabxContacts: pabxContacts
                })
            })
        )
    }

    @Action(RefreshDomainPabxContacts)
    RefreshDomainPabxContacts(
        ctx: StateContext<AppStateModel>,
    ) {
        const state = ctx.getState();
        return this.pabxService.getOrganisationsPabxContacts(state.organisationId, null, null, null).pipe(
            tap((pabxContacts: PabxContact[]) => {
                this.logger.info('received domains pabx contacts: ', pabxContacts);
                ctx.patchState({
                    domainPabxContacts: pabxContacts
                })
            })
        )
    }

    @Action(UpdateAudioAlbums)
    UpdateAudioAlbums(
        ctx: StateContext<AppStateModel>, { albums }: UpdateAudioAlbums
    ) {
        const state = ctx.getState();

        ctx.patchState({
            audioAlbums: albums
        })

        return of(state.audioAlbums);
    }

    @Action(InitiateModalPayment)
    InitiateModalPayment(
        ctx: StateContext<AppStateModel>, { modalData: modalData }: InitiateModalPayment
    ) {
        const state = ctx.getState();
        ctx.patchState({
            paymentModalData: modalData
        })
        return of(state.paymentModalData);
    }

    @Action(DeletePaymentModal)
    DeletePaymentModal(
        ctx: StateContext<AppStateModel>
    ) {
        const state = ctx.getState();
        ctx.patchState({
            paymentModalData: null
        })
        return of(state.paymentModalData);
    }

    @Action(GetWebHook)
    GetWebHook(
        ctx: StateContext<AppStateModel>
    ) {
        const patchState = ctx.patchState;
        const state = ctx.getState();
        return this.pabxService.getDomainSettings(state.organisationId, 'global', 0, 'webhookurl', 0, 'advanced').pipe(
            tap((res: DomainSetting[]) => {
                if (res && res.length > 0) {
                    patchState({
                        webhookSetting: res[0]
                    })
                }
            })
        )
    }

    @Action(UpdateWebHook)
    UpdateWebHook(
        ctx: StateContext<AppStateModel>,
        { webhookSetting }: UpdateWebHook
    ) {
        const state = ctx.getState();
        const patchState = ctx.patchState;

        return this.pabxService.updateDomainSetting(state.organisationId, webhookSetting.domainSettingUuid, webhookSetting).pipe(
            tap((res: DomainSetting) => {
                patchState({
                    webhookSetting: res
                })
            })
        )
    }

    @Action(InitiateCall)
    InitiateCall(
        ctx: StateContext<AppStateModel>,
        { data }: InitiateCall
    ) {
        const state = ctx.getState();
        return this.pabxService.initiateCall(state.organisationId, data);
    }

    @Action(InitiateEavesDrop)
    InitiateEavesDrop(
        ctx: StateContext<AppStateModel>,
        { data }: InitiateEavesDrop
    ) {
        const state = ctx.getState();
        return this.pabxService.initiateEavesDrop(state.organisationId, data);
    }

    @Action(UpdateActiveExtension)
    UpdateActiveExtension(
        ctx: StateContext<AppStateModel>,
        { extension }: UpdateActiveExtension
    ) {
        const state = ctx.getState();
        ctx.patchState({
            activeExtension: extension
        });

        return of(state.activeExtension);
    }

    @Action(GetActiveCalls)
    GetActiveCalls(
        ctx: StateContext<AppStateModel>,
    ) {
        const state = ctx.getState();
        return this.pabxService.getActiveCalls(state.organisationId).pipe(
            tap((res: ActiveCall[]) => {
                ctx.patchState({
                    activeCalls: res
                });
            })
        );
    }

    @Action(ChannelStateEvent)
    ChannelStateEvent(
        ctx: StateContext<AppStateModel>,
        { data }: ChannelStateEvent
    ) {
        const state = ctx.getState();
        const patchState = ctx.patchState;

        return of(null).pipe(
            tap(() => {
                if (data.event === "CHANNEL_DESTROY") {
                    let dest = '';
                    let extension = '';
                    let extState = '';
                    data.eventAvps.forEach(avp => {
                        this.logger.info('looking at', avp);
                        if (avp.att === 'Caller-ANI')
                            extension = avp.val;
                    });
                    let mystatus: ExtensionStatus = {
                        status: 'Idle',
                        bridgedTo: '',
                        extension: extension,
                    }
                    this.logger.info('new one is', mystatus);
                    let newMap = new Map(state.extensionStatus);
                    newMap.set(extension, mystatus);
                    this.logger.info('new map is', newMap);
                    if (extension) {
                        patchState({
                            extensionStatus: newMap
                        }
                        )
                    }
                }
                if (data.event === "CHANNEL_CREATE") {
                    let dest = '';
                    let extension = '';
                    let extState = '';
                    data.eventAvps.forEach(avp => {
                        this.logger.info('looking at', avp);
                        if (avp.att === 'state') {
                            extState = avp.val;
                            this.logger.info('state is now', extState);
                        }
                        if (avp.att === 'Caller-ANI')
                            extension = avp.val;
                        if (avp.att === 'destination')
                            dest = avp.val;

                    });
                    let mystatus: ExtensionStatus = {
                        status: extState,
                        bridgedTo: dest,
                        extension: extension,
                    }
                    this.logger.info('new one is', mystatus);
                    let newMap = new Map(state.extensionStatus);
                    newMap.set(extension, mystatus);
                    this.logger.info('new map is', newMap);
                    if (extension) {
                        patchState({
                            extensionStatus: newMap
                        }
                        )
                    }
                }
            }));
    }

    @Action(CreateWebHook)
    CreateWebHook(
        ctx: StateContext<AppStateModel>,
        { url }: CreateWebHook
    ) {
        const state = ctx.getState();
        const patchState = ctx.patchState;

        let setting: DomainSetting = {
            organisationId: state.organisationId,
            domainSettingCategory: 'global',
            domainSettingSubcategory: 'advanced',
            domainSettingEnabled: 'true',
            domainSettingName: 'webhookurl',
            domainSettingDescription: 'Webhook Url',
            domainSettingValue: url,
        }
        return this.pabxService.createDomainSetting(state.organisationId, setting).pipe(
            tap((res: DomainSetting) => {
                patchState({
                    webhookSetting: res
                })
            })
        )
    }


    /* helpers */
    buildNewUser(organisationId: number, data: any): User {
        let newUser: User = {
            countryOfBirth: data.country,
            countryOfResidence: data.country,
            email1: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            nationalIdentityNumber: data.idNumber,
            passportCountry: data.country,
            passportNumber: data.idNumber,
            phone1: data.phoneNumber,
            phone2: '' + data.firstPstnNumber,
            userIdentities: [<UserIdentity>{
                identityType: 'email',
                identity: data.email,
                password: data.password,
            }],
            userDocuments: data.documents,
            userPositions: [<UserPosition>{
                position: 'Administrator',
                organisationId: organisationId
            }],
            userRoles: [<UserRole>{
                role: 'DomainAdmin'
            }],
        }

        return newUser;
    }

    @Action(GetContacts)
    GetContacts(
        ctx: StateContext<AppStateModel>,
        { organisationId }: GetContacts
    ) {
        const state = ctx.getState();

        return this.pabxService.getOrganisationsContacts(organisationId, 0).pipe(
            tap(res => {
                ctx.patchState({
                    contacts: res
                })
            })
        );
    }

    @Action(AddContact)
    AddContact(
        ctx: StateContext<AppStateModel>,
        { contact }: AddContact
    ) {
        const state = ctx.getState();

        return this.pabxService.createOrganisationContact(state.organisationId, contact);
    }

    @Action(UpdateContact)
    UpdateContact(
        ctx: StateContext<AppStateModel>,
        { contact }: UpdateContact
    ) {
        const state = ctx.getState();

        return this.pabxService.updateContact(state.organisationId, contact.uuid, contact);
    }

    @Action(DeleteContact)
    DeleteContact(
        ctx: StateContext<AppStateModel>,
        { contactUuid }: DeleteContact
    ) {
        const state = ctx.getState();

        return this.pabxService.deleteContact(state.organisationId, contactUuid);
    }

    @Action(GetPstnContactsForOrganisation)
    getPstnContacts(
        ctx: StateContext<AppStateModel>,
        { organisationId }: GetPstnContactsForOrganisation
    ) {
        const state = ctx.getState();

        return this.pabxService.getOrganisationsPstnContacts(organisationId).pipe(
            tap((res: PstnContact[]) => {
                ctx.patchState({
                    domainPstnContacts: res
                })
            })
        );
    }

    @Action(GetPstnContacts)
    GetPstnContacts(
        ctx: StateContext<AppStateModel>,
        { offset, limit, status, search }: GetPstnContacts
    ) {
        const state = ctx.getState();

        return this.pabxService.getPstnContacts(limit, search, offset, status).pipe(
            tap((res: PstnContact[]) => {
                ctx.patchState({
                    searchPstnContacts: res
                })
            })
        );
    }

    @Action(AddCampaignList)
    AddCampaignList(
        ctx: StateContext<AppStateModel>,
        { campaignList, campaignUuid }: AddCampaignList
    ) {
        const state = ctx.getState();

        return this.pabxService.createOrganisationCampaignList(state.organisationId, campaignList).pipe(
            concatMap((res: CampaignList) => {
                return this.pabxService.assignListToCampaign(state.organisationId, campaignUuid, res.uuid);
            }));
    }

    @Action(GetCampaignListsForOrganisation)
    GetCampaignListsForOrganisation(
        ctx: StateContext<AppStateModel>,
        { organistaionId }: GetCampaignListsForOrganisation
    ) {
        const state = ctx.getState();

        return this.pabxService.getOrganisationsCampaignLists(organistaionId, false, true, 0, 0).pipe(
            tap((res: CampaignList[]) => {
                ctx.patchState(
                    {
                        campaignLists: res
                    }
                )
                res.forEach((list: CampaignList) => {
                    state.campaignListsMap.set(list.uuid, list);
                })
            })
        );
    }


    @Action(GetCampaignsForOrganisation)
    GetCampaignsForOrganisation(
        ctx: StateContext<AppStateModel>,
        { organistaionId }: GetCampaignsForOrganisation
    ) {
        const state = ctx.getState();

        return this.pabxService.getOrganisationsCampaigns(organistaionId).pipe(
            tap((res: Campaign[]) => {
                ctx.patchState(
                    {
                        campaigns: res
                    }
                )
            })
        );
    }

    @Action(GetCampaignsForOrganisationAndUser)
    GetCampaignsForOrganisationAndUser(
        ctx: StateContext<AppStateModel>,
        { organistaionId, userId, onlyActiveInSchedule }: GetCampaignsForOrganisationAndUser
    ) {
        const state = ctx.getState();

        return this.pabxService.getOrganisationsCampaigns(organistaionId, null, null, null, null, onlyActiveInSchedule, userId).pipe(
            tap((res: Campaign[]) => {
                ctx.patchState(
                    {
                        agentCampaigns: res
                    }
                )
            })
        );
    }

    @Action(agentLoginToCampaign)
    agentLoginToCampaign(
        ctx: StateContext<AppStateModel>,
        { campaign, organisationId, userId, extension, password }: agentLoginToCampaign
    ) {
        const state = ctx.getState();
        let agent: CallcentreAgentLoginData = {
            organisationId: organisationId,
            pabxContact: '' + extension,
            pabxContactPassword: password,
            userId: userId,
            // contact: extension + '@' + state.organisation.sipDomain,
            //agentUuid: '6af0a235-4277-4f0f-b3fb-34da1ba81346'
        }
        ctx.patchState({
            agentActiveCampaign: null,
            activeLead: null,
        })
        return this.pabxService.agentLogin(organisationId, campaign.campaignUuid, agent).pipe(
            tap(() => {
                ctx.patchState({
                    agentActiveCampaign: campaign,
                    agentActiveExtension: extension,
                })
            })
        )
    }
    
    @Action(SetAgentActiveCampaign)
    SetAgentActiveCampaign(
        ctx: StateContext<AppStateModel>,
        { campaign }: SetAgentActiveCampaign
    ) {
        const state = ctx.getState();

        ctx.patchState({
            agentActiveCampaign: campaign
        })
    }

    @Action(setActiveCampaignLead)
    setActiveCampaignLead(
        ctx: StateContext<AppStateModel>,
        { lead }: setActiveCampaignLead
    ) {
        const state = ctx.getState();

        ctx.patchState({
            activeLead: lead
        })
    }

    @Action(GetCampaignList)
    GetCampaignList(
        ctx: StateContext<AppStateModel>,
        { campaignListUuid }: GetCampaignList
    ) {
        const state = ctx.getState();

        return this.pabxService.getCampaignList(state.organisationId, campaignListUuid).pipe(
            tap((res: CampaignList) => {
                //add to cache
                if (isNullOrUndefined(state.campaignListsMap)) {
                    state.campaignListsMap = new Map<String, CampaignList>();
                }
                state.campaignListsMap.set(res.uuid, res);
            })
        );
    }

    // @Action(UpdateCampaignList)
    // UpdateCampaignList(
    //     ctx: StateContext<AppStateModel>,
    //     { campaignList }: UpdateCampaignList
    // ) {
    //     const state = ctx.getState();

    //     return this.pabxService.updateCampaignList(campaignListUuid, state.organisationId, campaignList);
    // }

    @Action(DeleteCampaignList)
    DeleteCampaignList(
        ctx: StateContext<AppStateModel>,
        { campaignListUuid }: DeleteCampaignList
    ) {
        const state = ctx.getState();

        return this.pabxService.deleteCampaignList(state.organisationId, campaignListUuid).pipe(
            tap(() => { 
                ctx.dispatch(new GetCampaignListsForOrganisation(state.organisationId));
            })
        );
    }


    @Action(CreateCampaign)
    CreateCampaign(
        ctx: StateContext<AppStateModel>,
        { organisationId, campaign }: CreateCampaign
    ) {
        const state = ctx.getState();

        return this.pabxService.createCampaign(organisationId, campaign);
    }

    @Action(UpdateCampaign)
    UpdateCampaign(
        ctx: StateContext<AppStateModel>,
        { campaign }: UpdateCampaign
    ) {
        const state = ctx.getState();

        return this.pabxService.updateCampaign(state.organisationId, campaign.campaignUuid, campaign);
    }

    @Action(UpdateCampaignState)
    UpdateCampaignState(
        ctx: StateContext<AppStateModel>,
        { campaignUuid, event }: UpdateCampaignState
    ) {
        const state = ctx.getState();
        return this.pabxService.processCampaignEvent(
            state.organisationId, campaignUuid, event).pipe(
                tap(() => {
                    ctx.dispatch(new GetCampaignsForOrganisation(state.organisationId));
                })
            );
    }

    @Action(UpdateCampaignListState)
    UpdateCampaignListState(
        ctx: StateContext<AppStateModel>,
        { campaignListUuid, event }: UpdateCampaignListState
    ) {
        const state = ctx.getState();
        return this.pabxService.processCampaignListEvent(
            state.organisationId, campaignListUuid, event).pipe(
                tap(() => {
                    ctx.dispatch(new GetCampaignListsForOrganisation(state.organisationId));
                })
            );
    }

    @Action(GetCampaign)
    GetCampaign(
        ctx: StateContext<AppStateModel>,
        { campaignUuid }: GetCampaign
    ) {
        const state = ctx.getState();

        return this.pabxService.getCampaign(state.organisationId, campaignUuid);
    }

    @Action(DeleteCampaign)
    DeleteCampaign(
        ctx: StateContext<AppStateModel>,
        { campaignUuid }: DeleteCampaign
    ) {
        const state = ctx.getState();

        return this.pabxService.deleteCampaign(state.organisationId, campaignUuid).pipe(
            tap(() => {
                ctx.dispatch(new GetCampaignsForOrganisation(state.organisationId));
            })
        );
    }

    @Action(GetCampaignListLead)
    GetCampaignListLead(
        ctx: StateContext<AppStateModel>,
        { campaignListUuid, leadUuid, organisationId }: GetCampaignListLead
    ) {
        return this.pabxService.getCampaignListLead(organisationId, campaignListUuid, leadUuid).pipe(
            tap((lead: CampaignLead) => {
                ctx.patchState({
                    activeLead: lead
                })
            })
        );
    }

    @Action(getNextCampaignLead)
    getNextCampaignLead(
        ctx: StateContext<AppStateModel>,
        { campaignUuid, organisationId, userId, agentExtension, status, setStatus, campaignType }: getNextCampaignLead
    ) {
        const state = ctx.getState();
        console.log('getting lead with status ' + status + ' and setting with ' + setStatus);

        return this.pabxService.getCampaignLeads(organisationId, campaignUuid, agentExtension, true, campaignType, 1, null, setStatus, status, userId).pipe(
            tap((leads: CampaignLead[]) => {
                if (leads.length > 0) {
                    ctx.patchState({
                        activeLead: leads[0],
                    });
                } else {
                    ctx.patchState({
                        activeLead: null,
                    });
                }
            })
        );
    }

    @Action(GetLeadStatuses)
    GetLeadStatuses(
        ctx: StateContext<AppStateModel>,
        { organisationId, campaignId, agentSelectable, includeSystemStatuses}: GetLeadStatuses
    ) {
        const state = ctx.getState();

        return this.pabxService.getLeadStatuses(organisationId, agentSelectable, campaignId, includeSystemStatuses).pipe(
            tap((statuses: LeadStatus[]) => {
                ctx.patchState({
                    leadStatuses: statuses,
                });
            })
        );
    }

    @Action(DeleteLeadStatus)
    DeleteLeadStatus(
        ctx: StateContext<AppStateModel>,
        { organisationId, leadStatusUuid }: DeleteLeadStatus
    ) {
        const state = ctx.getState();

        return this.pabxService.deleteLeadStatus(organisationId, leadStatusUuid).pipe(
            tap(() => {
                ctx.dispatch(new GetLeadStatuses(organisationId));
            })
        );
    }

    @Action(CreateLeadStatus)
    CreateLeadStatus(
        ctx: StateContext<AppStateModel>,
        { organisationId, leadStatus }: CreateLeadStatus
    ) {
        const state = ctx.getState();

        return this.pabxService.createLeadStatus(organisationId, leadStatus).pipe(
            tap(() => {
                ctx.dispatch(new GetLeadStatuses(organisationId));
            })
        );
    }

    @Action(UpdateLeadStatus)
    UpdateLeadStatus(
        ctx: StateContext<AppStateModel>,
        { organisationId, leadStatus }: UpdateLeadStatus
    ) {
        const state = ctx.getState();

        return this.pabxService.updateLeadStatus(organisationId, leadStatus.uuid, leadStatus).pipe(
            tap(() => {
                ctx.dispatch(new GetLeadStatuses(organisationId));
            })
        );
    }

    @Action(DispositionCampaignLead)
    DispositionCampaignLead(
        ctx: StateContext<AppStateModel>,
        { leadUuid, campaignListUuid, dispositionInfo, organisationId }: DispositionCampaignLead
    ) {
        const state = ctx.getState();
        return this.pabxService.dispositionLead(organisationId, campaignListUuid, leadUuid, dispositionInfo);
    }

    @Action(DialCampaignLead)
    DialCampaignLead(
        ctx: StateContext<AppStateModel>,
        { callsetup, campaignUuid, organisationId, userId }: DialCampaignLead
    ) {
        const state = ctx.getState();

        return this.pabxService.dialLead(organisationId, campaignUuid, callsetup).pipe(
            tap((callSetup: CampaignCallSetup[]) => {
                // ctx.patchState({
                //     leadStatuses: statuses,
                // });
            })
        );
    }

    @Action(GetCampaignAgentsForOrganisation)
    GetCampaignAgentsForOrganisation(
        ctx: StateContext<AppStateModel>,
        { organisationId }: GetCampaignAgentsForOrganisation
    ) {
        return this.pabxService.getOrganisationCallcenterAgents(organisationId).pipe(
            tap((res: CallcentreAgent[]) => {
                ctx.patchState({
                    campaignAgents: new Map(res.map(i => [i.agentUuid, i])),
                });
                console.log('updating agents', ctx.getState().campaignAgents);
            })    
        )    
    }

    @Action(ReserveSignupNumber)
    ReserveSignupNumber(
        ctx: StateContext<AppStateModel>,
        { pstnContact }: ReserveSignupNumber
    ) {
        const state = ctx.getState();

        return this.pabxService.setNumberEnteredSignup(pstnContact);
    }

    @Action(GetDepartmentsForOrganisation)
    GetDepartmentsForOrganisation(
        ctx: StateContext<AppStateModel>,
        { organisationId }: GetDepartmentsForOrganisation
    ) {
        const state = ctx.getState();

        return this.pabxService.getOrganisationsCallCentres(organisationId).pipe(
            tap((res: Callcentre[]) => {
                let m: Map<String, Callcentre> = new Map();
                res.forEach(c => {
                    m.set(c.callcentreUuid, c);
                })
                ctx.patchState({
                    departments: res,
                    departmentsDict: m,
                })
            })
        );
    }

    @Action(GetRingGroupsForOrganisation)
    GetRingGroupsForOrganisation(
        ctx: StateContext<AppStateModel>,
        { organisationId }: GetRingGroupsForOrganisation
    ) {
        const state = ctx.getState();

        return this.pabxService.getOrganisationsRingGroups(organisationId).pipe(
            tap((res: RingGroup[]) => {
                let m: Map<String, RingGroup> = new Map();
                res.forEach(r => {
                    m.set(r.ringGroupUuid, r);
                })
                ctx.patchState({
                    ringGroups: res,
                    ringGroupsDict: m,
                })
            })
        );
    }

    @Action(DeleteDepartment)
    deleteDepartment(
        ctx: StateContext<AppStateModel>,
        { organisationId, callcentreId }: DeleteDepartment
    ) {
        const state = ctx.getState();

        return this.pabxService.deleteOrganisationsCallCentre(organisationId, callcentreId).pipe(
            tap((res: Callcentre[]) => {
                ctx.dispatch(new GetDepartmentsForOrganisation(organisationId))
            })
        );
    }

    @Action(UpdateDepartment)
    UpdateDepartment(
        ctx: StateContext<AppStateModel>,
        { organisationId, data }: UpdateDepartment
    ) {
        const state = ctx.getState();

        return this.pabxService.updateOrganisationsCallCentre(organisationId, data.callcentreUuid, data).pipe(
            tap((res: Callcentre) => {
                ctx.dispatch(new GetDepartmentsForOrganisation(organisationId))
            })
        );
    }

    @Action(CreateDepartment)
    CreateDepartment(
        ctx: StateContext<AppStateModel>,
        { organisationId, data }: CreateDepartment
    ) {
        const state = ctx.getState();

        return this.pabxService.createOrganisationCallcentre(organisationId, data).pipe(
            tap((res: Callcentre) => {
                ctx.dispatch(new GetDepartmentsForOrganisation(organisationId))
            })
        );
    }

    /*CALLCENTER CAMPAIGN STATS METHODS */
    @Action(GetCallcenterCampaignStats)
    GetCallcenterCampaignStats(
        ctx: StateContext<AppStateModel>,
        { organisationId, campaignId }: GetCallcenterCampaignStats
    ) {
        const state = ctx.getState();

        return this.pabxService.getCampaignStats(organisationId, campaignId).pipe(
            tap((stats: CampaignStats) => {
                this.logger.info('CampaignStats => ', stats);
            })
        );
    }

    @Action(GetCallcenterCampaignListStats)
    GetCallcenterCampaignListStats(
        ctx: StateContext<AppStateModel>,
        { organisationId, campaignListId }: GetCallcenterCampaignListStats
    ) {
        const state = ctx.getState();

        return this.pabxService.getCampaignListStats(organisationId, campaignListId).pipe(
            tap((stats: CampaignListStats) => {
                this.logger.info('CampaignListStats => ', stats);
            })
        );
    }

    /*AGENT GROUPS METHODS */
    @Action(GetCallcenterAgentGroups)
    GetCallcenterAgentGroups(
        ctx: StateContext<AppStateModel>,
        { organisationId }: GetCallcenterAgentGroups
    ) {
        const state = ctx.getState();

        return this.pabxService.getOrganisationAgentGroups(organisationId).pipe(
            tap((res: CallcentreAgentGroup[]) => {
                let m: Map<String, CallcentreAgentGroup> = new Map();
                res.forEach(c => {
                    m.set(c.uuid, c);
                })
                ctx.patchState({
                    callcentreAgentGroups: m,
                })
            })
        );
    }

    @Action(DeleteCallcenterAgentGroups)
    deleteCallcenterAgentGroups(
        ctx: StateContext<AppStateModel>,
        { organisationId, agentGroupId }: DeleteCallcenterAgentGroups
    ) {
        const state = ctx.getState();

        return this.pabxService.deleteCallcenterAgentGroup(organisationId, agentGroupId).pipe(
            tap((res: CallcentreAgentGroup[]) => {
                ctx.dispatch(new GetCallcenterAgentGroups(organisationId))
            })
        );
    }

    @Action(UpdateCallcenterAgentGroups)
    UpdateCallcenterAgentGroups(
        ctx: StateContext<AppStateModel>,
        { organisationId, data }: UpdateCallcenterAgentGroups
    ) {
        const state = ctx.getState();

        return this.pabxService.updateCallcenterAgentGroup(organisationId, data.uuid, data).pipe(
            tap((res: CallcentreAgentGroup)=> {
                ctx.dispatch(new GetCallcenterAgentGroups(organisationId))
            })
        );
    }

    @Action(CreateCallcenterAgentGroups)
    CreateCallcenterAgentGroups(
        ctx: StateContext<AppStateModel>,
        { organisationId, data }: CreateCallcenterAgentGroups
    ) {
        const state = ctx.getState();

        return this.pabxService.createCallcenterAgentGroup(organisationId, data).pipe(
            tap((res: CallcentreAgentGroup) => {
                ctx.dispatch(new GetCallcenterAgentGroups(organisationId))
            })
        );
    }

    /* CONFERENCE METHODS */
    @Action(GetConferencesForOrganisation)
    GetConferencesForOrganisation(
        ctx: StateContext<AppStateModel>,
        { organisationId }: GetConferencesForOrganisation
    ) {
        const state = ctx.getState();

        return this.pabxService.getOrganisationsConferences(organisationId).pipe(
            tap((res: Conference[]) => {
                let m: Map<String, Conference> = new Map();
                res.forEach(c => {
                    m.set(c.uuid, c);
                })
                ctx.patchState({
                    conferences: res,
                    conferencesDict: m,
                })
            })
        );
    }

    @Action(DeleteConference)
    DeleteConference(
        ctx: StateContext<AppStateModel>,
        { organisationId, conferenceId }: DeleteConference
    ) {
        const state = ctx.getState();

        return this.pabxService.deleteOrganisationsConference(organisationId, conferenceId).pipe(
            tap((res: Conference[]) => {
                ctx.dispatch(new GetConferencesForOrganisation(organisationId))
            })
        );
    }

    @Action(UpdateConference)
    UpdateConference(
        ctx: StateContext<AppStateModel>,
        { organisationId, data }: UpdateConference
    ) {
        const state = ctx.getState();
        return this.pabxService.updateOrganisationsConference(organisationId, data.uuid, data).pipe(
            tap((res: Conference) => {
                ctx.dispatch(new GetConferencesForOrganisation(organisationId))
            })
        );
    }

    @Action(CreateConference)
    CreateConference(
        ctx: StateContext<AppStateModel>,
        { organisationId, data }: CreateConference
    ) {
        const state = ctx.getState();

        return this.pabxService.createConference(organisationId, data).pipe(
            tap((res: Callcentre) => {
                ctx.dispatch(new GetConferencesForOrganisation(organisationId))
            })
        );
    }

    @Action(BulkAddPstnNumbers)
    BulkAddPstnNumbers(
        ctx: StateContext<AppStateModel>,
        { numbersToAdd }: BulkAddPstnNumbers
    ) {
        const state = ctx.getState();
        return this.pabxService.createPstnContacts(numbersToAdd)
    }


    @Action(SearchOrganisations)
    SearchOrganisations(
        ctx: StateContext<AppStateModel>,
        { name, email, offset, limit }: SearchOrganisations
    ) {
        const state = ctx.getState();
        return this.userService.getOrganisations(email, null, limit, name, offset).pipe(
            switchMap((orgs: Organisation[]) => {
                const obs = orgs.map(org => this.posService.getAccounts(null, null, org.organisationId));
                return forkJoin(obs).pipe(
                    map(accounts =>
                        accounts.map((account: Account[], i) => {
                            return {
                                ...orgs[i],
                                account: account[0],
                            }
                        })
                    )

                )
            }),
            tap((res: UserOrganisationWithWallet[]) => {
                res = res.sort((org1: Organisation, org2: Organisation) => {
                    if (org1.name > org2.name) {
                        return 1;
                    }
                    if (org1.name < org2.name) {
                        return -1;
                    }
                    return 0;
                })
                ctx.patchState({
                    searchedOrganisations: res
                })
            })
        )
    }

    @Action(UpdateUserOrganisation)
    UpdateUserOrganisation(
        ctx: StateContext<AppStateModel>,
        { organisation }: UpdateUserOrganisation
    ) {
        const state = ctx.getState();
        return this.userService.updateOrganisation(organisation.organisationId, organisation)
    }

    @Action(DeactivateOrganisation)
    DeactivateOrganisation(
        ctx: StateContext<AppStateModel>,
        { organisationId, accountId }: DeactivateOrganisation
    ) {
        const state = ctx.getState();
        return this.userService.getOrganisation(organisationId).pipe(
            concatMap((res: Organisation) => {
                res.status = 0;
                return this.userService.updateOrganisation(res.organisationId, res);
            }),
            mergeMap(() => {
                return this.posService.getAccount(accountId).pipe(
                    concatMap((acc: Account) => {
                        acc.status = 1;
                        return this.posService.updateAccount(accountId, acc);
                    })
                )
            }),
            tap(() => {
                ctx.dispatch(new SearchOrganisations());
            }),
        )
    }

    @Action(ActivateOrganisation)
    ActivateOrganisation(
        ctx: StateContext<AppStateModel>,
        { organisationId, accountId }: ActivateOrganisation
    ) {
        const state = ctx.getState();
        return this.userService.getOrganisation(organisationId).pipe(
            concatMap((res: Organisation) => {
                res.status = 1;
                return this.userService.updateOrganisation(res.organisationId, res);
            }),
            mergeMap(() => {
                return this.posService.getAccount(accountId).pipe(
                    concatMap((acc: Account) => {
                        acc.status = 0;
                        return this.posService.updateAccount(accountId, acc);
                    })
                )
            }),
            tap(() => {
                ctx.dispatch(new SearchOrganisations());
            })
        )
    }

    @Action(EnableHiddenMenus)
    UpdateUserEnableHiddenMenusOrganisation(
        ctx: StateContext<AppStateModel>,
    ) {
        const state = ctx.getState();
        ctx.patchState({
            showHiddenMenus: true
        })
    }
}

export class JwtUtils {
    static getDecodedAccessToken(token: string): string {
        try {
            return jwt_decode(token);
        }
        catch (Error) {
            return null;
        }
    }
}