/**
 * Jini.Guru Technologies : BuzzBox ReST API
 * This is a a ReSTful web service using JSON as the data transfer model. The service covers all the functionality used by BuzzBox itself as well as some intended for external parties to integrate.
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type WithdrawalStatus = 'CANCELLED' | 'PENDING' | 'SUCCESSFUL' | 'TIMEOUT' | 'VALIDATED';

export const WithdrawalStatus = {
    CANCELLED: 'CANCELLED' as WithdrawalStatus,
    PENDING: 'PENDING' as WithdrawalStatus,
    SUCCESSFUL: 'SUCCESSFUL' as WithdrawalStatus,
    TIMEOUT: 'TIMEOUT' as WithdrawalStatus,
    VALIDATED: 'VALIDATED' as WithdrawalStatus
};