import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, concatMap, tap } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';
import { LoggerService } from '../../../services/logger/logger.service';
import { Store, Select } from '@ngxs/store';
import { RefreshUsersPabxContacts, RefreshDomainPabxContacts, ChannelStateEvent, UpdateActiveExtension } from '../../../app-state-model';
import Pusher from 'pusher-js';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PabxContact } from '../../../services/service-modules/buzzbox-services';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Select(state => state.global.loggedInPabxContacts) loggedInusersPabxContacts$: Observable<PabxContact[]>;
  @Select(state => state.global.domainPabxContacts) domainPabxContacts$: Observable<PabxContact[]>;
  @Select(state => state.global.email) loggedInEmail$: Observable<String>;

  domainPabxContactsSub: Subscription;
  loggedInUsersPabxContactsSub: Subscription;
  loggedInEmailSub: Subscription;
  domainPabxContacts: PabxContact[];
  public myExtensions: PabxContact[] = [];
  public activeNumber = 'not set';
  orgId: number;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  private channelName: string;
  private pusherClient: Pusher;
  private channel: any;
  public extensionForm: FormGroup;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Edit profile' }, { title: 'Reset Password' }, { title: 'Log out' }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private logger: LoggerService,
    private store: Store,
    private formBuilder: FormBuilder,
  ) {
    this.logger.info('header constucted');
    this.extensionForm = this.formBuilder.group({
      'activeExtension': ['not set'],
    });
    // this.activeExtensionFC = new FormControl('not set');
  }

  ngOnInit() {
    this.logger.info('Header component inited');
    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);


      this.loggedInUsersPabxContactsSub = this.loggedInusersPabxContacts$.pipe(
        takeUntil(this.destroy$)
      ).subscribe((loggedInPabxContacts) => {
        if (loggedInPabxContacts) {
          this.myExtensions = loggedInPabxContacts;
          // if (this.activeNumber === 'not set') {
            // this.activeNumber = '' + this.myExtensions[0].extension; 
            // this.extensionForm.controls.activeExtension.patchValue(this.myExtensions[0].extension);
            this.logger.info('setting logged in number to', this.activeNumber);
          }
        // } else {
          // console.log('setting logged in number to NOTHING');
        // }
      });
      this.domainPabxContactsSub = this.domainPabxContacts$.pipe(
        takeUntil(this.destroy$)
      ).subscribe((domainPabxContacts) => {
        if (domainPabxContacts) {
          this.domainPabxContacts = domainPabxContacts;
        }
      }); 
      this.loggedInEmailSub = this.loggedInEmail$.pipe(
        takeUntil(this.destroy$)
      ).subscribe((email) => {
        this.user.name = email;
        this.user.picture = null;
      })
  
      this.store.select(state => state.global.organisationId).pipe(
        takeUntil(this.destroy$),
        tap((res: number) => {
          if (res && res > 0) {
            this.logger.info('we have an orgid...', res);
            this.orgId = res;
          }
        }),
        concatMap(() => {
          return this.store.select(state => state.global.organisation);
        })).subscribe((org) => {
          if (org && this.orgId && this.orgId > 0) {
            this.store.dispatch(new RefreshUsersPabxContacts);
            this.store.dispatch(new RefreshDomainPabxContacts);
          } 
  
          if (org && false) {
            //get puser working / setup
            this.channelName = 'private-' + org.sipDomain;
            this.orgId = this.store.selectSnapshot(state => state.global.organisationId);
            this.pusherClient = new Pusher('f31b70695828343e4a05', {
              authEndpoint: 'https://buzzboxcloud.com/portal/buzzbox-conductor/rest/v1/pabx-organisations/' + this.orgId + '/pusher-auth',
              auth: {
                headers: {
                  'Authorization': this.store.selectSnapshot(state => state.global.token)
                }
              },
              cluster: 'eu'
            });
            if (!this.channel) {
              this.channel = this.pusherClient.subscribe(this.channelName);
              this.channel.bind(
                'channel-event',
                (data: any) => {
                  this.logger.info('received data, ', data);
                  this.store.dispatch(new ChannelStateEvent(data)).subscribe();
                  // let map: any = this.avpsToMap(data.eventAvps);
                  // let newEvent = {
                  //   id: 0,
                  //   header: 'Event: ' + data.event + ' => ' + map['Channel-Name'],
                  //   body: map['stringDump'],
                  // }
                  // this.events.unshift(newEvent);
                  // if (this.events.length > 100) {
                  //   this.events = this.events.slice(0, 100);
                  // }
                }
              );
            }
          }
        });
  }

  ngOnDestroy() {
    this.logger.info('Header component destroyed');
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToHome() {
    
  }

  toggleTheme() {
    this.themeService.changeTheme(this.currentTheme);
  }

  toggleActiveExtension() {
    this.logger.info('current active extension is now', this.activeNumber);  
    this.store.dispatch(new UpdateActiveExtension(this.activeNumber));
    this.logger.info('current form value is', this.extensionForm.controls.activeExtension.value);  
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
