import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { tap ,  catchError } from 'rxjs/operators';
import { of as observableOf ,  Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { LoggerService } from '../services/logger/logger.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private logger: LoggerService,
    private store: Store
    ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.logger.info('Auth guard called');
      const token = !!this.store.selectSnapshot(
        ourState => ourState.global.token
      );
      const roles = this.store.selectSnapshot(
        ourState => ourState.global.roles
      );

      if (!token) {
        this.logger.info('No token: routing to login');
        this.router.navigate(['/auth/login'], { queryParams: route.queryParams});
        return false;
      }
      if (route.data.roles && !this.checkAccess(route.data.roles, roles)) {
        this.logger.info(`required roles [${route.data.roles}] not met: [${roles}] on route ${state.url}`);
        if (state.url.startsWith('/pages/dashboard')) {
          this.logger.info('routing to agent dashboard');
          this.router.navigate(['/agent-pages/callcentre/campaigns'], { queryParams: route.queryParams});
        } else {
          this.router.navigate(['/pages/noaccess'], { queryParams: route.queryParams});
        }
        this.logger.warn('no routing....', route.data.roles, roles);
        return false;
      } 
      this.logger.info('allowing access', route.data.roles, roles, state.url);
      
      return true;
  }

  checkAccess(requiredRoles: string[], currentRoles:string[]) : boolean {
    let intersection = currentRoles.filter(x => requiredRoles.includes(x));
    if (intersection.length > 0) {
      return true;
    }
    return false;
  }
}
