/**
 * Jini.Guru Technologies : BuzzBox ReST API
 * This is a a ReSTful web service using JSON as the data transfer model. The service covers all the functionality used by BuzzBox itself as well as some intended for external parties to integrate.
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ManualPaymentNotification } from '../model/manualPaymentNotification';
import { Payment } from '../model/payment';
import { PaymentNotificationEmail } from '../model/paymentNotificationEmail';
import { QrCode } from '../model/qrCode';
import { TokenData } from '../model/tokenData';
import { WalletPaymentNotification } from '../model/walletPaymentNotification';
import { Withdrawal } from '../model/withdrawal';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PaymentService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Initiate the Payment via payment gateway.
     * This API is responsible for actual payment via Credit/Debit card and/or Bank transfer by using payment details.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPayment(body?: Payment, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Payment>;
    public createPayment(body?: Payment, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Payment>>;
    public createPayment(body?: Payment, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Payment>>;
    public createPayment(body?: Payment, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Payment>(`${this.basePath}/rest/v1/payments`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post notification of a deposit.
     * E.g. from an email
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPaymentNotificationFromEmail(body?: PaymentNotificationEmail, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public createPaymentNotificationFromEmail(body?: PaymentNotificationEmail, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public createPaymentNotificationFromEmail(body?: PaymentNotificationEmail, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public createPaymentNotificationFromEmail(body?: PaymentNotificationEmail, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/payments/email-deposit-notifications`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post notification of a manual deposit.
     * E.g. where we were not notified automatically
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPaymentNotificationFromManualEntry(body?: ManualPaymentNotification, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Payment>;
    public createPaymentNotificationFromManualEntry(body?: ManualPaymentNotification, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Payment>>;
    public createPaymentNotificationFromManualEntry(body?: ManualPaymentNotification, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Payment>>;
    public createPaymentNotificationFromManualEntry(body?: ManualPaymentNotification, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Payment>(`${this.basePath}/rest/v1/payments/manual-deposit-notifications`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post notification of a payment by wallet.
     * E.g. Customer pays for something from their wallet balance
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPaymentNotificationFromWallet(body?: WalletPaymentNotification, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Payment>;
    public createPaymentNotificationFromWallet(body?: WalletPaymentNotification, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Payment>>;
    public createPaymentNotificationFromWallet(body?: WalletPaymentNotification, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Payment>>;
    public createPaymentNotificationFromWallet(body?: WalletPaymentNotification, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Payment>(`${this.basePath}/rest/v1/payments/wallet-payment-notifications`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createQrCode(body?: QrCode, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<QrCode>;
    public createQrCode(body?: QrCode, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<QrCode>>;
    public createQrCode(body?: QrCode, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<QrCode>>;
    public createQrCode(body?: QrCode, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<QrCode>(`${this.basePath}/rest/v1/qr-codes`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createWithdrawal(body?: Withdrawal, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Withdrawal>;
    public createWithdrawal(body?: Withdrawal, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Withdrawal>>;
    public createWithdrawal(body?: Withdrawal, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Withdrawal>>;
    public createWithdrawal(body?: Withdrawal, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Withdrawal>(`${this.basePath}/rest/v1/withdrawals`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param tokenKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteToken(tokenKey: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteToken(tokenKey: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteToken(tokenKey: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteToken(tokenKey: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (tokenKey === null || tokenKey === undefined) {
            throw new Error('Required parameter tokenKey was null or undefined when calling deleteToken.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/payment-tokens/${encodeURIComponent(String(tokenKey))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Payment Info.
     * Get a Payment info by providing gatewayTransactionId/paymentId
     * @param paymentIdentifier 
     * @param by 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayment(paymentIdentifier: string, by?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Payment>;
    public getPayment(paymentIdentifier: string, by?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Payment>>;
    public getPayment(paymentIdentifier: string, by?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Payment>>;
    public getPayment(paymentIdentifier: string, by?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (paymentIdentifier === null || paymentIdentifier === undefined) {
            throw new Error('Required parameter paymentIdentifier was null or undefined when calling getPayment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (by !== undefined && by !== null) {
            queryParameters = queryParameters.set('by', <any>by);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Payment>(`${this.basePath}/rest/v1/payments/${encodeURIComponent(String(paymentIdentifier))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a list of payments
     * This API will return all payments based on the request paramaters.
     * @param dateFromIncl fromDate, the date from where we want to get the payments inclusive.
     * @param dateToExcl toDate, the date upto when we want to get the payments exclusive
     * @param groupingId 
     * @param invoiceId 
     * @param limit 
     * @param namedQuery 
     * @param offset 
     * @param paymentType 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayments(dateFromIncl?: string, dateToExcl?: string, groupingId?: number, invoiceId?: string, limit?: number, namedQuery?: string, offset?: number, paymentType?: string, status?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Payment>>;
    public getPayments(dateFromIncl?: string, dateToExcl?: string, groupingId?: number, invoiceId?: string, limit?: number, namedQuery?: string, offset?: number, paymentType?: string, status?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Payment>>>;
    public getPayments(dateFromIncl?: string, dateToExcl?: string, groupingId?: number, invoiceId?: string, limit?: number, namedQuery?: string, offset?: number, paymentType?: string, status?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Payment>>>;
    public getPayments(dateFromIncl?: string, dateToExcl?: string, groupingId?: number, invoiceId?: string, limit?: number, namedQuery?: string, offset?: number, paymentType?: string, status?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFromIncl !== undefined && dateFromIncl !== null) {
            queryParameters = queryParameters.set('dateFromIncl', <any>dateFromIncl);
        }
        if (dateToExcl !== undefined && dateToExcl !== null) {
            queryParameters = queryParameters.set('dateToExcl', <any>dateToExcl);
        }
        if (groupingId !== undefined && groupingId !== null) {
            queryParameters = queryParameters.set('groupingId', <any>groupingId);
        }
        if (invoiceId !== undefined && invoiceId !== null) {
            queryParameters = queryParameters.set('invoiceId', <any>invoiceId);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (namedQuery !== undefined && namedQuery !== null) {
            queryParameters = queryParameters.set('namedQuery', <any>namedQuery);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (paymentType !== undefined && paymentType !== null) {
            queryParameters = queryParameters.set('paymentType', <any>paymentType);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Payment>>(`${this.basePath}/rest/v1/payments`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param qrCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQrCode(qrCode: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<QrCode>;
    public getQrCode(qrCode: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<QrCode>>;
    public getQrCode(qrCode: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<QrCode>>;
    public getQrCode(qrCode: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (qrCode === null || qrCode === undefined) {
            throw new Error('Required parameter qrCode was null or undefined when calling getQrCode.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<QrCode>(`${this.basePath}/rest/v1/qr-codes/${encodeURIComponent(String(qrCode))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param tokenKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getToken(tokenKey: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<TokenData>;
    public getToken(tokenKey: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<TokenData>>;
    public getToken(tokenKey: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<TokenData>>;
    public getToken(tokenKey: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (tokenKey === null || tokenKey === undefined) {
            throw new Error('Required parameter tokenKey was null or undefined when calling getToken.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<TokenData>(`${this.basePath}/rest/v1/payment-tokens/${encodeURIComponent(String(tokenKey))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param groupingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTokens(groupingId?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<TokenData>>;
    public getTokens(groupingId?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<TokenData>>>;
    public getTokens(groupingId?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<TokenData>>>;
    public getTokens(groupingId?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupingId !== undefined && groupingId !== null) {
            queryParameters = queryParameters.set('groupingId', <any>groupingId);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<TokenData>>(`${this.basePath}/rest/v1/payment-tokens`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param someId 
     * @param by 
     * @param someIdentifier 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWithdrawal(someId: string, by?: string, someIdentifier?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Withdrawal>;
    public getWithdrawal(someId: string, by?: string, someIdentifier?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Withdrawal>>;
    public getWithdrawal(someId: string, by?: string, someIdentifier?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Withdrawal>>;
    public getWithdrawal(someId: string, by?: string, someIdentifier?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (someId === null || someId === undefined) {
            throw new Error('Required parameter someId was null or undefined when calling getWithdrawal.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (by !== undefined && by !== null) {
            queryParameters = queryParameters.set('by', <any>by);
        }
        if (someIdentifier !== undefined && someIdentifier !== null) {
            queryParameters = queryParameters.set('someIdentifier', <any>someIdentifier);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Withdrawal>(`${this.basePath}/rest/v1/withdrawals/${encodeURIComponent(String(someId))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param groupingId 
     * @param info 
     * @param limit 
     * @param offset 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWithdrawals(groupingId?: number, info?: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Withdrawal>>;
    public getWithdrawals(groupingId?: number, info?: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Withdrawal>>>;
    public getWithdrawals(groupingId?: number, info?: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Withdrawal>>>;
    public getWithdrawals(groupingId?: number, info?: string, limit?: number, offset?: number, status?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupingId !== undefined && groupingId !== null) {
            queryParameters = queryParameters.set('groupingId', <any>groupingId);
        }
        if (info !== undefined && info !== null) {
            queryParameters = queryParameters.set('info', <any>info);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Withdrawal>>(`${this.basePath}/rest/v1/withdrawals`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initiateCreateToken(customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Payment>;
    public initiateCreateToken(customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Payment>>;
    public initiateCreateToken(customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Payment>>;
    public initiateCreateToken(customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Payment>(`${this.basePath}/rest/v1/payment-tokens`,
            null,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the payment details.
     * Update the payment details with updated model
     * @param paymentIdentifier 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePayment(paymentIdentifier: string, body?: Payment, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Payment>;
    public updatePayment(paymentIdentifier: string, body?: Payment, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Payment>>;
    public updatePayment(paymentIdentifier: string, body?: Payment, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Payment>>;
    public updatePayment(paymentIdentifier: string, body?: Payment, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (paymentIdentifier === null || paymentIdentifier === undefined) {
            throw new Error('Required parameter paymentIdentifier was null or undefined when calling updatePayment.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<Payment>(`${this.basePath}/rest/v1/payments/${encodeURIComponent(String(paymentIdentifier))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param tokenKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateToken(tokenKey: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<TokenData>;
    public updateToken(tokenKey: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<TokenData>>;
    public updateToken(tokenKey: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<TokenData>>;
    public updateToken(tokenKey: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (tokenKey === null || tokenKey === undefined) {
            throw new Error('Required parameter tokenKey was null or undefined when calling updateToken.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<TokenData>(`${this.basePath}/rest/v1/payment-tokens/${encodeURIComponent(String(tokenKey))}`,
            null,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param someId 
     * @param someIdentifier 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWithdrawal(someId: string, someIdentifier?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Withdrawal>;
    public updateWithdrawal(someId: string, someIdentifier?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Withdrawal>>;
    public updateWithdrawal(someId: string, someIdentifier?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Withdrawal>>;
    public updateWithdrawal(someId: string, someIdentifier?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (someId === null || someId === undefined) {
            throw new Error('Required parameter someId was null or undefined when calling updateWithdrawal.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (someIdentifier !== undefined && someIdentifier !== null) {
            queryParameters = queryParameters.set('someIdentifier', <any>someIdentifier);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<Withdrawal>(`${this.basePath}/rest/v1/withdrawals/${encodeURIComponent(String(someId))}`,
            null,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

 }
