export * from './authentication.service';
import { AuthenticationService } from './authentication.service';
export * from './buzzBoxConductor.service';
import { BuzzBoxConductorService } from './buzzBoxConductor.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './mobileRecharge.service';
import { MobileRechargeService } from './mobileRecharge.service';
export * from './numberPortability.service';
import { NumberPortabilityService } from './numberPortability.service';
export * from './pabx.service';
import { PabxService } from './pabx.service';
export * from './payment.service';
import { PaymentService } from './payment.service';
export * from './poS.service';
import { PoSService } from './poS.service';
export * from './property.service';
import { PropertyService } from './property.service';
export * from './reporting.service';
import { ReportingService } from './reporting.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './wallet.service';
import { WalletService } from './wallet.service';
export const APIS = [AuthenticationService, BuzzBoxConductorService, DefaultService, MobileRechargeService, NumberPortabilityService, PabxService, PaymentService, PoSService, PropertyService, ReportingService, UserService, WalletService, ];
