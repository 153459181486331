/**
 * Jini.Guru Technologies : BuzzBox ReST API
 * This is a a ReSTful web service using JSON as the data transfer model. The service covers all the functionality used by BuzzBox itself as well as some intended for external parties to integrate.
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExceptionData } from '../model/exceptionData';
import { PortingRequest } from '../model/portingRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class NumberPortabilityService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a NumberPortability
     * This API used to create a NumberPortability and NumberPortability object.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPortingRequest(body?: PortingRequest, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PortingRequest>;
    public createPortingRequest(body?: PortingRequest, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PortingRequest>>;
    public createPortingRequest(body?: PortingRequest, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PortingRequest>>;
    public createPortingRequest(body?: PortingRequest, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<PortingRequest>(`${this.basePath}/rest/v1/porting-requests`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a numberPortability
     * This API will delete the numberPortability by numberPortabilityId.
     * @param portingRequestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePortingRequest(portingRequestId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deletePortingRequest(portingRequestId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deletePortingRequest(portingRequestId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deletePortingRequest(portingRequestId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (portingRequestId === null || portingRequestId === undefined) {
            throw new Error('Required parameter portingRequestId was null or undefined when calling deletePortingRequest.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/porting-requests/${encodeURIComponent(String(portingRequestId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a numberPortability for a user
     * This API used to get a numberPortability by numberPortabilityId to make payment.
     * @param portingRequestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPortingRequest(portingRequestId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PortingRequest>;
    public getPortingRequest(portingRequestId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PortingRequest>>;
    public getPortingRequest(portingRequestId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PortingRequest>>;
    public getPortingRequest(portingRequestId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (portingRequestId === null || portingRequestId === undefined) {
            throw new Error('Required parameter portingRequestId was null or undefined when calling getPortingRequest.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<PortingRequest>(`${this.basePath}/rest/v1/porting-requests/${encodeURIComponent(String(portingRequestId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all numberPortabilies for a users
     * This API used to get all numberPortabilies by user Id with an optional range for pagination to make payment.
     * @param limit 
     * @param offset 
     * @param status 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPortingRequests(limit?: number, offset?: number, status?: string, userId?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<PortingRequest>>;
    public getPortingRequests(limit?: number, offset?: number, status?: string, userId?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<PortingRequest>>>;
    public getPortingRequests(limit?: number, offset?: number, status?: string, userId?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<PortingRequest>>>;
    public getPortingRequests(limit?: number, offset?: number, status?: string, userId?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<PortingRequest>>(`${this.basePath}/rest/v1/porting-requests`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a numberPortability
     * This API will update the numberPortability by userId, numberPortabilityId and numberPortability object.
     * @param portingRequestId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePortingRequest(portingRequestId: number, body?: PortingRequest, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PortingRequest>;
    public updatePortingRequest(portingRequestId: number, body?: PortingRequest, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PortingRequest>>;
    public updatePortingRequest(portingRequestId: number, body?: PortingRequest, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PortingRequest>>;
    public updatePortingRequest(portingRequestId: number, body?: PortingRequest, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (portingRequestId === null || portingRequestId === undefined) {
            throw new Error('Required parameter portingRequestId was null or undefined when calling updatePortingRequest.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<PortingRequest>(`${this.basePath}/rest/v1/porting-requests/${encodeURIComponent(String(portingRequestId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

 }
