import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '../logger/logger.service';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

interface DayStat {
  date: Date,
  inCount: number,
  outCount: number,
  onnetCount: number,
  cost: number,
}

@Injectable()
export class ReportService {

  voiceRecordInvokeEvent = new BehaviorSubject(null); 

  constructor(protected http: HttpClient,
    private logger: LoggerService) {
    this.logger.info('report service instantiated');
  }

  OnDestroy() {
    this.logger.info('destroying report service');
  }


  mtdStats: any = {};

  getMonthToDateStats(walletId: number): Observable<any> {
    var d = new Date();
    var days = d.getDate() - 1;
    return this.http.request<any>('GET', `${environment.baseApiUrl}/rest/v1/reports/voice-usage-per-day?n1=${walletId}&n2=${days}`)
  }
}