import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { LoggerService } from '../../../services/logger/logger.service';
import { AppState } from '../../../app-state';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <span class="created-by">Jini Guru <b><a href="https://buzzboxcloud.co.za" target="_blank">BuzzBox</a></b> 2020 <a *ngIf="isAdmin" [routerLink]="['/admin-pages/dashboard']">Admin</a></span>
  <div class="socials">
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
  `,
})

export class FooterComponent implements OnInit, OnDestroy {
  @Select(AppState.roles) roles$: Observable<string[]>; 
  public isAdmin: boolean = false;
  subscription: Subscription = null;
  stringRoles: string[] = [];
  
  constructor(
    private store: Store,
    private logger: LoggerService
  ) {
    this.logger.info("footer inited");
  }

  ngOnInit(): void {
    this.subscription = this.roles$.subscribe((res) => {
      if (res && res.length > 0) {
        this.stringRoles = res;
        if (this.stringRoles.indexOf('Admin') > -1) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      }
    })
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}