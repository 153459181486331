import { InjectionToken } from '@angular/core';

export const BASE_PATH = new InjectionToken<string>('basePath');
export const COLLECTION_FORMATS = {
    'csv': ',',
    'tsv': '   ',
    'ssv': ' ',
    'pipes': '|'
}

export const CUBEJS_CONFIGS = {
    'content_type': 'application/json',
    'request_url': 'http://localhost:4000/cubejs-api/v1/load',
    'auth_header': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MDg1NDg3MDEsImV4cCI6MTYwODYzNTEwMX0.-qapp3JU_jYdkxQiDNPrSnN-Bq7AYCgA6yxcC4tDr6I',
    "accept_type": 'application/json'
}
