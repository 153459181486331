import { AsyncValidatorFn, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS, AsyncValidator } from "@angular/forms";
import { Observable, of } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { Directive } from "@angular/core";
import { UserService } from '../services/service-modules/buzzbox-services';

export function existingEmailValidator(userService: UserService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        return userService.getUsers(null, null, null, control.value).pipe(
            switchMap(users => {
                let ret: any = {};
                ret.emailTaken = true;
                if (users && users.length > 0) {
                    if (!(users[0].status & 2)) {
                        ret.emailNotVerified = true;
                    }
                    if (!(users[0].status & 4)) {
                        ret.phoneNotVerfied = true;
                    }
                    return of(ret);
                } 
                return of(null);
            }),
            catchError( (err) => {return of({ "emailTaken": true })})
            );
    };
}

@Directive({
    selector: '[mobNumExists][formControlName],[mobNumExists][formControl],[mobNumExists][ngModel]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: ExistingEmailValidatorDirective, multi: true }]
})
export class ExistingEmailValidatorDirective implements AsyncValidator {
    constructor(private userService: UserService) { }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return existingEmailValidator(this.userService)(control);
    }
} 