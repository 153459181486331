/**
 * Jini.Guru Technologies : BuzzBox ReST API
 * This is a a ReSTful web service using JSON as the data transfer model. The service covers all the functionality used by BuzzBox itself as well as some intended for external parties to integrate.
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Event } from '../model/event';
import { ExceptionData } from '../model/exceptionData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ReportingService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Store an event on the backend for later analysis and reporting
     * This API will Add an event into the backend
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEvent(body?: Event, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public createEvent(body?: Event, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public createEvent(body?: Event, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public createEvent(body?: Event, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/events`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param reportId 
     * @param d1 
     * @param d2 
     * @param d3 
     * @param d4 
     * @param d5 
     * @param d6 
     * @param d7 
     * @param d8 
     * @param n1 
     * @param n2 
     * @param n3 
     * @param n4 
     * @param n5 
     * @param n6 
     * @param n7 
     * @param n8 
     * @param s1 
     * @param s2 
     * @param s3 
     * @param s4 
     * @param s5 
     * @param s6 
     * @param s7 
     * @param s8 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReport(reportId: string, d1?: string, d2?: string, d3?: string, d4?: string, d5?: string, d6?: string, d7?: string, d8?: string, n1?: number, n2?: number, n3?: number, n4?: number, n5?: number, n6?: number, n7?: number, n8?: number, s1?: string, s2?: string, s3?: string, s4?: string, s5?: string, s6?: string, s7?: string, s8?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public getReport(reportId: string, d1?: string, d2?: string, d3?: string, d4?: string, d5?: string, d6?: string, d7?: string, d8?: string, n1?: number, n2?: number, n3?: number, n4?: number, n5?: number, n6?: number, n7?: number, n8?: number, s1?: string, s2?: string, s3?: string, s4?: string, s5?: string, s6?: string, s7?: string, s8?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public getReport(reportId: string, d1?: string, d2?: string, d3?: string, d4?: string, d5?: string, d6?: string, d7?: string, d8?: string, n1?: number, n2?: number, n3?: number, n4?: number, n5?: number, n6?: number, n7?: number, n8?: number, s1?: string, s2?: string, s3?: string, s4?: string, s5?: string, s6?: string, s7?: string, s8?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public getReport(reportId: string, d1?: string, d2?: string, d3?: string, d4?: string, d5?: string, d6?: string, d7?: string, d8?: string, n1?: number, n2?: number, n3?: number, n4?: number, n5?: number, n6?: number, n7?: number, n8?: number, s1?: string, s2?: string, s3?: string, s4?: string, s5?: string, s6?: string, s7?: string, s8?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (reportId === null || reportId === undefined) {
            throw new Error('Required parameter reportId was null or undefined when calling getReport.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (d1 !== undefined && d1 !== null) {
            queryParameters = queryParameters.set('d1', <any>d1);
        }
        if (d2 !== undefined && d2 !== null) {
            queryParameters = queryParameters.set('d2', <any>d2);
        }
        if (d3 !== undefined && d3 !== null) {
            queryParameters = queryParameters.set('d3', <any>d3);
        }
        if (d4 !== undefined && d4 !== null) {
            queryParameters = queryParameters.set('d4', <any>d4);
        }
        if (d5 !== undefined && d5 !== null) {
            queryParameters = queryParameters.set('d5', <any>d5);
        }
        if (d6 !== undefined && d6 !== null) {
            queryParameters = queryParameters.set('d6', <any>d6);
        }
        if (d7 !== undefined && d7 !== null) {
            queryParameters = queryParameters.set('d7', <any>d7);
        }
        if (d8 !== undefined && d8 !== null) {
            queryParameters = queryParameters.set('d8', <any>d8);
        }
        if (n1 !== undefined && n1 !== null) {
            queryParameters = queryParameters.set('n1', <any>n1);
        }
        if (n2 !== undefined && n2 !== null) {
            queryParameters = queryParameters.set('n2', <any>n2);
        }
        if (n3 !== undefined && n3 !== null) {
            queryParameters = queryParameters.set('n3', <any>n3);
        }
        if (n4 !== undefined && n4 !== null) {
            queryParameters = queryParameters.set('n4', <any>n4);
        }
        if (n5 !== undefined && n5 !== null) {
            queryParameters = queryParameters.set('n5', <any>n5);
        }
        if (n6 !== undefined && n6 !== null) {
            queryParameters = queryParameters.set('n6', <any>n6);
        }
        if (n7 !== undefined && n7 !== null) {
            queryParameters = queryParameters.set('n7', <any>n7);
        }
        if (n8 !== undefined && n8 !== null) {
            queryParameters = queryParameters.set('n8', <any>n8);
        }
        if (s1 !== undefined && s1 !== null) {
            queryParameters = queryParameters.set('s1', <any>s1);
        }
        if (s2 !== undefined && s2 !== null) {
            queryParameters = queryParameters.set('s2', <any>s2);
        }
        if (s3 !== undefined && s3 !== null) {
            queryParameters = queryParameters.set('s3', <any>s3);
        }
        if (s4 !== undefined && s4 !== null) {
            queryParameters = queryParameters.set('s4', <any>s4);
        }
        if (s5 !== undefined && s5 !== null) {
            queryParameters = queryParameters.set('s5', <any>s5);
        }
        if (s6 !== undefined && s6 !== null) {
            queryParameters = queryParameters.set('s6', <any>s6);
        }
        if (s7 !== undefined && s7 !== null) {
            queryParameters = queryParameters.set('s7', <any>s7);
        }
        if (s8 !== undefined && s8 !== null) {
            queryParameters = queryParameters.set('s8', <any>s8);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<any>(`${this.basePath}/rest/v1/reports/${encodeURIComponent(String(reportId))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

 }
