export const environment = {
  production: false,
  debug: true,
  // baseApiUrl: 'https://buzzboxcloud.com/portal/buzzbox-conductor',
  baseApiUrl: 'https://buzzbox.test.jini.guru/buzzbox-conductor',
  //baseApiUrl: 'http://localhost:8081/buzzbox-conductor',
  urlsToIgnoreJwt: 'frontend\.|/login|/verification-resends',
  baseUrl: './',
  currency: 'R',
};
