import { Injectable } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalData} from '../models/modal-data.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ModalService {
  modalData: ModalData;
  getWalletUpdate: BehaviorSubject<boolean> = new BehaviorSubject(false); 
  constructor(private ngbModal: NgbModal) { }

  openModal(component: any , data: any , title?: string | 'Title Name', isUpdate?: boolean | false, modalSize?: any | "md"): ModalData {
    this.valueMapper(data , title , isUpdate);
    this.modalData.modalRef = this.ngbModal.open(component,
      {windowClass: 'user-modal-body' , container: 'nb-layout' , backdrop: 'static' , keyboard: false, size: modalSize});
    this.modalData.modalRef.componentInstance.modalHeader = 'Large Modal';
    return this.modalData;
  }

  closeModal(): void {
    this.modalData.modalRef.dismiss();
  }

  valueMapper(data , title , isUpdate): void {
    this.modalData = new ModalData();
    this.modalData.data = data;
    this.modalData.title = title;
    this.modalData.isUpdate = isUpdate;
  }
}
