import { Component, OnInit } from '@angular/core';
import { LoggerService } from '../../services/logger/logger.service';
import { ModalService } from '../../services/modal.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalData } from '../../models/modal-data.model';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { Signout } from '../../app-state-model';
import { Observable } from 'rxjs';
import { MustMatch } from '../../helpers/must-match.validator';
import { UserIdentity, UserIdentityPasswordChange, UserService } from '../../services/service-modules/buzzbox-services';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  public verificationForm: FormGroup;
  editForm: FormGroup;
  modalData: ModalData;
  isVerified = false;
  editData;
  submitted = false;
  errors: string[] = [];
  messages: string[] = [];

  constructor(private formBuilder: FormBuilder,
    protected router: Router,
    private modalService: ModalService,
    private userService: UserService,
    private logger: LoggerService,
    private toasterService: NbToastrService,
    private store: Store,
  ) {

    this.modalData = this.modalService.modalData;
    if (this.modalData.isUpdate) {
      this.editData = this.modalData.data;
    }
    this.createForm();
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalService.closeModal();
  }

  createForm() {
    this.resetPasswordForm = this.formBuilder.group({
      'email': ['', Validators.compose([Validators.required, Validators.maxLength(50),
      Validators.email])],
    });
  }

  initializeVerificationForm() {
    const password = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
    const confirmPassword = new FormControl('', Validators.compose([Validators.required]));
    this.verificationForm = this.formBuilder.group({
      'code': ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{6,6}$')])],
      'password': password,
      'confirmPassword': confirmPassword,
    }, { validator: MustMatch('password', 'confirmPassword')});
  }

  onResetPassword(data: any) {
    this.errors = this.messages = [];
    this.submitted = true;
    this.logger.info('data submitted => ', data);
    const userIdentity = {
      identity: data.email,
      identityType: 'email',
    } as UserIdentity;
    if (this.resetPasswordForm.valid) {
      this.passwordChangeInit(userIdentity).subscribe(
        userIdentity => {
          this.initializeVerificationForm();
          this.isVerified = true;
          this.submitted = false;
        }, error => {
          this.logger.error(error);
          this.submitted = false;
          this.errors.push('Please enter valid email address');
        });
    }
  }

  passwordChangeInit(userIdentity: UserIdentity): Observable<UserIdentity> {
    return this.userService.initiateUsersPasswordChange(userIdentity);
  }

  onVerificationPassword(data: any) {
    this.errors = this.messages = [];
    this.submitted = true;
    if (this.verificationForm.valid) {
      const userIdentityChangePasswordModel = {
        identity: this.resetPasswordForm.value['email'],
        password: data.password,
        hash: data.code,
      } as UserIdentityPasswordChange;

      this.userService.updateUsersPassword(userIdentityChangePasswordModel).subscribe(
        userIdentity => {
          this.submitted = false;
          setTimeout(() => {
            return this.router.navigateByUrl('/');
          }, 2000);
          this.toasterService.success('Password has been updated successfully, Login again with new password.')
        }, error => {
          this.submitted = false;
          this.errors.push('Error while updating password - ' + `${error.error[0].code} - ${error.error[0].description}`);
          this.toasterService.danger(`${error.error[0].code} - ${error.error[0].description}`, 'Failed to reset password');
          this.logger.error(error);
        }, () => {
          this.store.dispatch(new Signout).subscribe((res) => {
            this.logger.info(res);
              setTimeout(() => {
                this.router.navigateByUrl('/auth/login');
              }, 2000);
          });
          this.dismiss();
        });
    }
  }

  onSubmit(value) {
    this.logger.info('value => ', value);
  }

}
