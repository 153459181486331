import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss']
})
export class YesNoDialogComponent implements OnInit {
  @Input() title: string;
  @Input() question: string;
  
  constructor(protected ref: NbDialogRef<YesNoDialogComponent>) { }

  ngOnInit() {
  }

  cancel() {
    this.ref.close();
  }

  submit(name) {
    this.ref.close('yes');
  }

}
