/**
 * Jini.Guru Technologies : BuzzBox ReST API
 * This is a a ReSTful web service using JSON as the data transfer model. The service covers all the functionality used by BuzzBox itself as well as some intended for external parties to integrate.
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BillingData } from '../model/billingData';
import { Bundle } from '../model/bundle';
import { BundleProvisioningData } from '../model/bundleProvisioningData';
import { BundleSpec } from '../model/bundleSpec';
import { CardOnFileCreationCallback } from '../model/cardOnFileCreationCallback';
import { ExceptionData } from '../model/exceptionData';
import { PnpCallbackRequest } from '../model/pnpCallbackRequest';
import { PnpCallbackResponse } from '../model/pnpCallbackResponse';
import { Reservation } from '../model/reservation';
import { ScheduledTransfer } from '../model/scheduledTransfer';
import { TransactionState } from '../model/transactionState';
import { Wallet } from '../model/wallet';
import { WalletHistory } from '../model/walletHistory';
import { WalletHistoryDetail } from '../model/walletHistoryDetail';
import { WalletMapping } from '../model/walletMapping';
import { WalletSummary } from '../model/walletSummary';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cofCallback(body?: CardOnFileCreationCallback, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public cofCallback(body?: CardOnFileCreationCallback, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public cofCallback(body?: CardOnFileCreationCallback, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public cofCallback(body?: CardOnFileCreationCallback, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/jg-card/callbacks/card-on-file-creations`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param walletId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBundle(walletId: number, body?: BundleProvisioningData, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Bundle>>;
    public createBundle(walletId: number, body?: BundleProvisioningData, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Bundle>>>;
    public createBundle(walletId: number, body?: BundleProvisioningData, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Bundle>>>;
    public createBundle(walletId: number, body?: BundleProvisioningData, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling createBundle.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Array<Bundle>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/bundles`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a reservation
     * Create a reservation
     * @param walletId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createReservation(walletId: number, body?: Reservation, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Reservation>;
    public createReservation(walletId: number, body?: Reservation, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Reservation>>;
    public createReservation(walletId: number, body?: Reservation, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Reservation>>;
    public createReservation(walletId: number, body?: Reservation, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling createReservation.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<Reservation>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/reservations`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Do Wallet Mapping between wallet and service identifier
     * This API is responsible to bind specific wallet to service.
     * @param walletId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createWalletMapping(walletId: number, body?: WalletMapping, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<WalletMapping>;
    public createWalletMapping(walletId: number, body?: WalletMapping, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<WalletMapping>>;
    public createWalletMapping(walletId: number, body?: WalletMapping, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<WalletMapping>>;
    public createWalletMapping(walletId: number, body?: WalletMapping, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling createWalletMapping.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<WalletMapping>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/mappings`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param walletId 
     * @param bundleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBundle(walletId: number, bundleId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteBundle(walletId: number, bundleId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteBundle(walletId: number, bundleId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteBundle(walletId: number, bundleId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling deleteBundle.');
        }
        if (bundleId === null || bundleId === undefined) {
            throw new Error('Required parameter bundleId was null or undefined when calling deleteBundle.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/bundles/${encodeURIComponent(String(bundleId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param bundleSpecId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBundleSpec(bundleSpecId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteBundleSpec(bundleSpecId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteBundleSpec(bundleSpecId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteBundleSpec(bundleSpecId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (bundleSpecId === null || bundleSpecId === undefined) {
            throw new Error('Required parameter bundleSpecId was null or undefined when calling deleteBundleSpec.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/bundle-specs/${encodeURIComponent(String(bundleSpecId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the Reservation details.
     * Delete the reservation details by providing unique reservationId. 
     * @param walletId 
     * @param bundleId 
     * @param bundleReservationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReservation(walletId: number, bundleId: number, bundleReservationId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteReservation(walletId: number, bundleId: number, bundleReservationId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteReservation(walletId: number, bundleId: number, bundleReservationId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteReservation(walletId: number, bundleId: number, bundleReservationId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling deleteReservation.');
        }
        if (bundleId === null || bundleId === undefined) {
            throw new Error('Required parameter bundleId was null or undefined when calling deleteReservation.');
        }
        if (bundleReservationId === null || bundleReservationId === undefined) {
            throw new Error('Required parameter bundleReservationId was null or undefined when calling deleteReservation.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/bundles/${encodeURIComponent(String(bundleId))}/reservations/${encodeURIComponent(String(bundleReservationId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the Reservation details.
     * Delete the reservation details by providing unique reservationId. 
     * @param walletId 
     * @param walletReservationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReservation_1(walletId: number, walletReservationId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteReservation_1(walletId: number, walletReservationId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteReservation_1(walletId: number, walletReservationId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteReservation_1(walletId: number, walletReservationId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling deleteReservation_1.');
        }
        if (walletReservationId === null || walletReservationId === undefined) {
            throw new Error('Required parameter walletReservationId was null or undefined when calling deleteReservation_1.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/reservations/${encodeURIComponent(String(walletReservationId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the scheduled transfer.
     * Delete the scheduled transfer
     * @param walletId 
     * @param scheduledTransferId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteScheduledTransfer(walletId: number, scheduledTransferId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteScheduledTransfer(walletId: number, scheduledTransferId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteScheduledTransfer(walletId: number, scheduledTransferId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteScheduledTransfer(walletId: number, scheduledTransferId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling deleteScheduledTransfer.');
        }
        if (scheduledTransferId === null || scheduledTransferId === undefined) {
            throw new Error('Required parameter scheduledTransferId was null or undefined when calling deleteScheduledTransfer.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/scheduled-transfers/${encodeURIComponent(String(scheduledTransferId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the Wallet Account details.
     * Delete the Wallet account details by providing unique walletId from database. 
     * @param walletId 
     * @param absolutelySure 
     * @param cascade 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWallet(walletId: number, absolutelySure?: boolean, cascade?: boolean, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteWallet(walletId: number, absolutelySure?: boolean, cascade?: boolean, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteWallet(walletId: number, absolutelySure?: boolean, cascade?: boolean, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteWallet(walletId: number, absolutelySure?: boolean, cascade?: boolean, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling deleteWallet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (absolutelySure !== undefined && absolutelySure !== null) {
            queryParameters = queryParameters.set('absolutelySure', <any>absolutelySure);
        }
        if (cascade !== undefined && cascade !== null) {
            queryParameters = queryParameters.set('cascade', <any>cascade);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the WalletMapping details.
     * Delete the WalletMapping details by providing unique walletMappingId. 
     * @param walletId 
     * @param identifier 
     * @param identifierType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWalletMapping(walletId: number, identifier: string, identifierType: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public deleteWalletMapping(walletId: number, identifier: string, identifierType: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public deleteWalletMapping(walletId: number, identifier: string, identifierType: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public deleteWalletMapping(walletId: number, identifier: string, identifierType: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling deleteWalletMapping.');
        }
        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling deleteWalletMapping.');
        }
        if (identifierType === null || identifierType === undefined) {
            throw new Error('Required parameter identifierType was null or undefined when calling deleteWalletMapping.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.delete<any>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/mappings/${encodeURIComponent(String(identifierType))}/${encodeURIComponent(String(identifier))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param walletId 
     * @param bundleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBundle(walletId: number, bundleId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Bundle>;
    public getBundle(walletId: number, bundleId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Bundle>>;
    public getBundle(walletId: number, bundleId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Bundle>>;
    public getBundle(walletId: number, bundleId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getBundle.');
        }
        if (bundleId === null || bundleId === undefined) {
            throw new Error('Required parameter bundleId was null or undefined when calling getBundle.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Bundle>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/bundles/${encodeURIComponent(String(bundleId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param bundleSpecId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBundleSpec(bundleSpecId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<BundleSpec>;
    public getBundleSpec(bundleSpecId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<BundleSpec>>;
    public getBundleSpec(bundleSpecId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<BundleSpec>>;
    public getBundleSpec(bundleSpecId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (bundleSpecId === null || bundleSpecId === undefined) {
            throw new Error('Required parameter bundleSpecId was null or undefined when calling getBundleSpec.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<BundleSpec>(`${this.basePath}/rest/v1/bundle-specs/${encodeURIComponent(String(bundleSpecId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param walletId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBundles(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Bundle>>;
    public getBundles(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Bundle>>>;
    public getBundles(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Bundle>>>;
    public getBundles(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getBundles.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Bundle>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/bundles`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param walletId 
     * @param bundleId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBundlesReservations(walletId: number, bundleId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Reservation>>;
    public getBundlesReservations(walletId: number, bundleId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Reservation>>>;
    public getBundlesReservations(walletId: number, bundleId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Reservation>>>;
    public getBundlesReservations(walletId: number, bundleId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getBundlesReservations.');
        }
        if (bundleId === null || bundleId === undefined) {
            throw new Error('Required parameter bundleId was null or undefined when calling getBundlesReservations.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Reservation>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/bundles/${encodeURIComponent(String(bundleId))}/reservations`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Reservation Info.
     * Get a Reservation info by providing unique reservationId
     * @param walletId 
     * @param bundleId 
     * @param bundleReservationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReservation(walletId: number, bundleId: number, bundleReservationId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Reservation>;
    public getReservation(walletId: number, bundleId: number, bundleReservationId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Reservation>>;
    public getReservation(walletId: number, bundleId: number, bundleReservationId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Reservation>>;
    public getReservation(walletId: number, bundleId: number, bundleReservationId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getReservation.');
        }
        if (bundleId === null || bundleId === undefined) {
            throw new Error('Required parameter bundleId was null or undefined when calling getReservation.');
        }
        if (bundleReservationId === null || bundleReservationId === undefined) {
            throw new Error('Required parameter bundleReservationId was null or undefined when calling getReservation.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Reservation>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/bundles/${encodeURIComponent(String(bundleId))}/reservations/${encodeURIComponent(String(bundleReservationId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Reservation Info.
     * Get a Reservation info by providing unique reservationId
     * @param walletId 
     * @param walletReservationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReservation_2(walletId: number, walletReservationId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Reservation>;
    public getReservation_2(walletId: number, walletReservationId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Reservation>>;
    public getReservation_2(walletId: number, walletReservationId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Reservation>>;
    public getReservation_2(walletId: number, walletReservationId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getReservation_2.');
        }
        if (walletReservationId === null || walletReservationId === undefined) {
            throw new Error('Required parameter walletReservationId was null or undefined when calling getReservation_2.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Reservation>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/reservations/${encodeURIComponent(String(walletReservationId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a scheduled transfer.
     * Get a scheduled transfer by id.
     * @param walletId 
     * @param scheduledTransferId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScheduledTransfer(walletId: number, scheduledTransferId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<ScheduledTransfer>;
    public getScheduledTransfer(walletId: number, scheduledTransferId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<ScheduledTransfer>>;
    public getScheduledTransfer(walletId: number, scheduledTransferId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<ScheduledTransfer>>;
    public getScheduledTransfer(walletId: number, scheduledTransferId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getScheduledTransfer.');
        }
        if (scheduledTransferId === null || scheduledTransferId === undefined) {
            throw new Error('Required parameter scheduledTransferId was null or undefined when calling getScheduledTransfer.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<ScheduledTransfer>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/scheduled-transfers/${encodeURIComponent(String(scheduledTransferId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the usage data over a period of time for billing
     * Get the usage data over a period of time for billing
     * @param walletId 
     * @param dateFromIncl fromDate, the date from when we want to get the usage inclusive.
     * @param dateToExcl toDate, the date upto when we want to get the usage exclusive.
     * @param includePaymentCallbacks Whether payments callbacks must be generated and returned (pass false for a quote/price and true for a sale
     * @param invoiceId 
     * @param type type of usage to get billing data for, currently supports usage otherwise passes to listener.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsage(walletId: number, dateFromIncl: string, dateToExcl: string, includePaymentCallbacks: boolean, invoiceId?: number, type?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<BillingData>>;
    public getUsage(walletId: number, dateFromIncl: string, dateToExcl: string, includePaymentCallbacks: boolean, invoiceId?: number, type?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<BillingData>>>;
    public getUsage(walletId: number, dateFromIncl: string, dateToExcl: string, includePaymentCallbacks: boolean, invoiceId?: number, type?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<BillingData>>>;
    public getUsage(walletId: number, dateFromIncl: string, dateToExcl: string, includePaymentCallbacks: boolean, invoiceId?: number, type?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getUsage.');
        }
        if (dateFromIncl === null || dateFromIncl === undefined) {
            throw new Error('Required parameter dateFromIncl was null or undefined when calling getUsage.');
        }
        if (dateToExcl === null || dateToExcl === undefined) {
            throw new Error('Required parameter dateToExcl was null or undefined when calling getUsage.');
        }
        if (includePaymentCallbacks === null || includePaymentCallbacks === undefined) {
            throw new Error('Required parameter includePaymentCallbacks was null or undefined when calling getUsage.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFromIncl !== undefined && dateFromIncl !== null) {
            queryParameters = queryParameters.set('dateFromIncl', <any>dateFromIncl);
        }
        if (dateToExcl !== undefined && dateToExcl !== null) {
            queryParameters = queryParameters.set('dateToExcl', <any>dateToExcl);
        }
        if (includePaymentCallbacks !== undefined && includePaymentCallbacks !== null) {
            queryParameters = queryParameters.set('includePaymentCallbacks', <any>includePaymentCallbacks);
        }
        if (invoiceId !== undefined && invoiceId !== null) {
            queryParameters = queryParameters.set('invoiceId', <any>invoiceId);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<BillingData>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/history/bill`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch wallet account info based on provided currency
     * Get an Account Wallet info by providing unique walletId and currency, it will convert the wallet balance accordingly.
     * @param walletId 
     * @param currency 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWallet(walletId: number, currency?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Wallet>;
    public getWallet(walletId: number, currency?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Wallet>>;
    public getWallet(walletId: number, currency?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Wallet>>;
    public getWallet(walletId: number, currency?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWallet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (currency !== undefined && currency !== null) {
            queryParameters = queryParameters.set('currency', <any>currency);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Wallet>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of Wallet transaction history.
     * Get the list of Wallet transaction history based on provided fromDate and toDate with offset and limit if we want to fetch in chunks.
     * @param walletId 
     * @param ascending If sorted, sort in ascending order. Default is descending
     * @param dateFromIncl fromDate, the date from where we want to get the wallet history inclusive.
     * @param dateToExcl toDate, the date upto when we want to get the wallet history exclusive
     * @param filter Filter string to search in fields of the transaction to further filter results. Not case sensitive.
     * @param infoAtt Only return results where a info attribute matches this along with the val matching  infoVal
     * @param infoVal Used in conjuction with infoAtt
     * @param limit Max number of results to return. Defaults to 50.
     * @param namedQuery 
     * @param offset Offset for pagination. An offset of 10 would return from index 10, i.e. skipping 0 to 9 (first 10 items are skipped).
     * @param orderBy Column to order by if ordering is required. E.g. start_date or end_date
     * @param serviceCode Service code to filter by
     * @param serviceIdentifier Service identifier to filter by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWalletHistory(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, limit?: number, namedQuery?: string, offset?: number, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<WalletHistory>>;
    public getWalletHistory(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, limit?: number, namedQuery?: string, offset?: number, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<WalletHistory>>>;
    public getWalletHistory(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, limit?: number, namedQuery?: string, offset?: number, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<WalletHistory>>>;
    public getWalletHistory(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, limit?: number, namedQuery?: string, offset?: number, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWalletHistory.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ascending !== undefined && ascending !== null) {
            queryParameters = queryParameters.set('ascending', <any>ascending);
        }
        if (dateFromIncl !== undefined && dateFromIncl !== null) {
            queryParameters = queryParameters.set('dateFromIncl', <any>dateFromIncl);
        }
        if (dateToExcl !== undefined && dateToExcl !== null) {
            queryParameters = queryParameters.set('dateToExcl', <any>dateToExcl);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (infoAtt !== undefined && infoAtt !== null) {
            queryParameters = queryParameters.set('infoAtt', <any>infoAtt);
        }
        if (infoVal !== undefined && infoVal !== null) {
            queryParameters = queryParameters.set('infoVal', <any>infoVal);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (namedQuery !== undefined && namedQuery !== null) {
            queryParameters = queryParameters.set('namedQuery', <any>namedQuery);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (orderBy !== undefined && orderBy !== null) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }
        if (serviceCode !== undefined && serviceCode !== null) {
            queryParameters = queryParameters.set('serviceCode', <any>serviceCode);
        }
        if (serviceIdentifier !== undefined && serviceIdentifier !== null) {
            queryParameters = queryParameters.set('serviceIdentifier', <any>serviceIdentifier);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<WalletHistory>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/history`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of Wallet transaction history with a complex search.
     * Get the list of Wallet transaction history based on provided fromDate and toDate with offset and limit if we want to fetch in chunks.
     * @param walletId 
     * @param limit Max number of results to return. Defaults to 50.
     * @param offset Offset for pagination. An offset of 10 would return from index 10, i.e. skipping 0 to 9 (first 10 items are skipped).
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWalletHistoryByComplexSearch(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<WalletHistory>>;
    public getWalletHistoryByComplexSearch(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<WalletHistory>>>;
    public getWalletHistoryByComplexSearch(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<WalletHistory>>>;
    public getWalletHistoryByComplexSearch(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWalletHistoryByComplexSearch.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<WalletHistory>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/history/complex`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of Wallet transaction history in csv format.
     * Get the list of Wallet transaction history based on provided fromDate and toDate
     * @param walletId 
     * @param ascending If sorted, sort in ascending order. Default is descending
     * @param dateFromIncl fromDate, the date from where we want to get the wallet history inclusive.
     * @param dateToExcl toDate, the date upto when we want to get the wallet history exclusive
     * @param filter Filter string to search in fields of the transaction to further filter results. Not case sensitive.
     * @param infoAtt Only return results where a info attribute matches this along with the val matching  infoVal
     * @param infoVal Used in conjuction with infoAtt
     * @param orderBy Column to order by if ordering is required. E.g. start_date or end_date
     * @param serviceCode Service code to filter by
     * @param serviceIdentifier Service identifier to filter by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWalletHistoryCsv(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<WalletHistory>>;
    public getWalletHistoryCsv(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<WalletHistory>>>;
    public getWalletHistoryCsv(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<WalletHistory>>>;
    public getWalletHistoryCsv(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWalletHistoryCsv.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ascending !== undefined && ascending !== null) {
            queryParameters = queryParameters.set('ascending', <any>ascending);
        }
        if (dateFromIncl !== undefined && dateFromIncl !== null) {
            queryParameters = queryParameters.set('dateFromIncl', <any>dateFromIncl);
        }
        if (dateToExcl !== undefined && dateToExcl !== null) {
            queryParameters = queryParameters.set('dateToExcl', <any>dateToExcl);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (infoAtt !== undefined && infoAtt !== null) {
            queryParameters = queryParameters.set('infoAtt', <any>infoAtt);
        }
        if (infoVal !== undefined && infoVal !== null) {
            queryParameters = queryParameters.set('infoVal', <any>infoVal);
        }
        if (orderBy !== undefined && orderBy !== null) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }
        if (serviceCode !== undefined && serviceCode !== null) {
            queryParameters = queryParameters.set('serviceCode', <any>serviceCode);
        }
        if (serviceIdentifier !== undefined && serviceIdentifier !== null) {
            queryParameters = queryParameters.set('serviceIdentifier', <any>serviceIdentifier);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<WalletHistory>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/history/csv`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of WalletHistoryDetails.
     * Get the list of Wallet History Details based on provided walletHistoryId, fromDate and toDate with offset and limit if we want to fetch in chunks.
     * @param walletId 
     * @param walletHistoryId 
     * @param dateFromIncl fromDate, the data from where we want to get the wallet history.
     * @param dateToExcl toDate, the data upto we want to get the wallet histories.
     * @param limit Max number of results to return. Defaults to 50.
     * @param offset Offset for pagination. An offset of 10 would return from index 10, i.e. skipping 0 to 9 (first 10 items are skipped).
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWalletHistoryDetails(walletId: number, walletHistoryId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<WalletHistoryDetail>>;
    public getWalletHistoryDetails(walletId: number, walletHistoryId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<WalletHistoryDetail>>>;
    public getWalletHistoryDetails(walletId: number, walletHistoryId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<WalletHistoryDetail>>>;
    public getWalletHistoryDetails(walletId: number, walletHistoryId: number, dateFromIncl?: string, dateToExcl?: string, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWalletHistoryDetails.');
        }
        if (walletHistoryId === null || walletHistoryId === undefined) {
            throw new Error('Required parameter walletHistoryId was null or undefined when calling getWalletHistoryDetails.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFromIncl !== undefined && dateFromIncl !== null) {
            queryParameters = queryParameters.set('dateFromIncl', <any>dateFromIncl);
        }
        if (dateToExcl !== undefined && dateToExcl !== null) {
            queryParameters = queryParameters.set('dateToExcl', <any>dateToExcl);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<WalletHistoryDetail>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/history/${encodeURIComponent(String(walletHistoryId))}/details`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of Wallet transaction history in xlsx binary format.
     * Get the list of Wallet transaction history based on provided fromDate and toDate with offset and limit if we want to fetch in chunks.
     * @param walletId 
     * @param ascending If sorted, sort in ascending order. Default is descending
     * @param dateFromIncl fromDate, the date from where we want to get the wallet history inclusive.
     * @param dateToExcl toDate, the date upto when we want to get the wallet history exclusive
     * @param filter Filter string to search in fields of the transaction to further filter results. Not case sensitive.
     * @param infoAtt Only return results where a info attribute matches this along with the val matching  infoVal
     * @param infoVal Used in conjuction with infoAtt
     * @param orderBy Column to order by if ordering is required. E.g. start_date or end_date
     * @param serviceCode Service code to filter by
     * @param serviceIdentifier Service identifier to filter by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWalletHistoryXls(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<WalletHistory>>;
    public getWalletHistoryXls(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<WalletHistory>>>;
    public getWalletHistoryXls(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<WalletHistory>>>;
    public getWalletHistoryXls(walletId: number, ascending?: boolean, dateFromIncl?: string, dateToExcl?: string, filter?: string, infoAtt?: string, infoVal?: string, orderBy?: string, serviceCode?: string, serviceIdentifier?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWalletHistoryXls.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ascending !== undefined && ascending !== null) {
            queryParameters = queryParameters.set('ascending', <any>ascending);
        }
        if (dateFromIncl !== undefined && dateFromIncl !== null) {
            queryParameters = queryParameters.set('dateFromIncl', <any>dateFromIncl);
        }
        if (dateToExcl !== undefined && dateToExcl !== null) {
            queryParameters = queryParameters.set('dateToExcl', <any>dateToExcl);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }
        if (infoAtt !== undefined && infoAtt !== null) {
            queryParameters = queryParameters.set('infoAtt', <any>infoAtt);
        }
        if (infoVal !== undefined && infoVal !== null) {
            queryParameters = queryParameters.set('infoVal', <any>infoVal);
        }
        if (orderBy !== undefined && orderBy !== null) {
            queryParameters = queryParameters.set('orderBy', <any>orderBy);
        }
        if (serviceCode !== undefined && serviceCode !== null) {
            queryParameters = queryParameters.set('serviceCode', <any>serviceCode);
        }
        if (serviceIdentifier !== undefined && serviceIdentifier !== null) {
            queryParameters = queryParameters.set('serviceIdentifier', <any>serviceIdentifier);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<WalletHistory>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/history/xls`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a single Wallet transaction.
     * Get the Wallet transaction.
     * @param walletId 
     * @param walletHistoryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWalletHistory_3(walletId: number, walletHistoryId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<WalletHistory>;
    public getWalletHistory_3(walletId: number, walletHistoryId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<WalletHistory>>;
    public getWalletHistory_3(walletId: number, walletHistoryId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<WalletHistory>>;
    public getWalletHistory_3(walletId: number, walletHistoryId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWalletHistory_3.');
        }
        if (walletHistoryId === null || walletHistoryId === undefined) {
            throw new Error('Required parameter walletHistoryId was null or undefined when calling getWalletHistory_3.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<WalletHistory>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/history/${encodeURIComponent(String(walletHistoryId))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a WalletMapping Info.
     * Get a WalletMapping info by providing unique walletMappingId
     * @param walletId 
     * @param identifier 
     * @param identifierType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWalletMapping(walletId: number, identifier: string, identifierType: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<WalletMapping>;
    public getWalletMapping(walletId: number, identifier: string, identifierType: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<WalletMapping>>;
    public getWalletMapping(walletId: number, identifier: string, identifierType: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<WalletMapping>>;
    public getWalletMapping(walletId: number, identifier: string, identifierType: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWalletMapping.');
        }
        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling getWalletMapping.');
        }
        if (identifierType === null || identifierType === undefined) {
            throw new Error('Required parameter identifierType was null or undefined when calling getWalletMapping.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<WalletMapping>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/mappings/${encodeURIComponent(String(identifierType))}/${encodeURIComponent(String(identifier))}`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of WalletMapping.
     * Get the list of WalletMapping Details with offset and limit if we want to fetch in chunks.
     * @param walletId 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWalletMappings(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<WalletMapping>>;
    public getWalletMappings(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<WalletMapping>>>;
    public getWalletMappings(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<WalletMapping>>>;
    public getWalletMappings(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWalletMappings.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<WalletMapping>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/mappings`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a summary of the wallet usage over a time period.
     * Get a summary of the wallet usage over a time period. The typeIds are mapped to SQL queries in property wallet.summary.queries
     * @param walletId 
     * @param dateFromIncl fromDate, the data from where we want the summary
     * @param dateToExcl toDate, the data up to when we want the summary.
     * @param typeIds Comma delimited type id&#x27;s to return.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWalletSummary(walletId: number, dateFromIncl: string, dateToExcl: string, typeIds: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<WalletSummary>;
    public getWalletSummary(walletId: number, dateFromIncl: string, dateToExcl: string, typeIds: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<WalletSummary>>;
    public getWalletSummary(walletId: number, dateFromIncl: string, dateToExcl: string, typeIds: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<WalletSummary>>;
    public getWalletSummary(walletId: number, dateFromIncl: string, dateToExcl: string, typeIds: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWalletSummary.');
        }
        if (dateFromIncl === null || dateFromIncl === undefined) {
            throw new Error('Required parameter dateFromIncl was null or undefined when calling getWalletSummary.');
        }
        if (dateToExcl === null || dateToExcl === undefined) {
            throw new Error('Required parameter dateToExcl was null or undefined when calling getWalletSummary.');
        }
        if (typeIds === null || typeIds === undefined) {
            throw new Error('Required parameter typeIds was null or undefined when calling getWalletSummary.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFromIncl !== undefined && dateFromIncl !== null) {
            queryParameters = queryParameters.set('dateFromIncl', <any>dateFromIncl);
        }
        if (dateToExcl !== undefined && dateToExcl !== null) {
            queryParameters = queryParameters.set('dateToExcl', <any>dateToExcl);
        }
        if (typeIds !== undefined && typeIds !== null) {
            queryParameters = queryParameters.set('typeIds', <any>typeIds);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<WalletSummary>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/history/summary`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of Wallet Reservations by providing specific wallet.
     * Get the list of Reservations details by providing specific wallet in paginated manner.
     * @param walletId 
     * @param limit Max number of results to return. Defaults to 50.
     * @param offset Offset for pagination. An offset of 10 would return from index 10, i.e. skipping 0 to 9 (first 10 items are skipped).
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWalletsReservations(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<Reservation>>;
    public getWalletsReservations(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<Reservation>>>;
    public getWalletsReservations(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<Reservation>>>;
    public getWalletsReservations(walletId: number, limit?: number, offset?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWalletsReservations.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<Reservation>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/reservations`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of the wallets scheduled transfers
     * Get a list of the wallets scheduled transfers
     * @param walletId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWalletsScheduledTransfers(walletId: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Array<ScheduledTransfer>>;
    public getWalletsScheduledTransfers(walletId: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Array<ScheduledTransfer>>>;
    public getWalletsScheduledTransfers(walletId: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Array<ScheduledTransfer>>>;
    public getWalletsScheduledTransfers(walletId: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling getWalletsScheduledTransfers.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<Array<ScheduledTransfer>>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/scheduled-transfers`,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param code 
     * @param error 
     * @param errorDescription 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public handleRedirect(code?: string, error?: string, errorDescription?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public handleRedirect(code?: string, error?: string, errorDescription?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public handleRedirect(code?: string, error?: string, errorDescription?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public handleRedirect(code?: string, error?: string, errorDescription?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (error !== undefined && error !== null) {
            queryParameters = queryParameters.set('error', <any>error);
        }
        if (errorDescription !== undefined && errorDescription !== null) {
            queryParameters = queryParameters.set('error_description', <any>errorDescription);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<any>(`${this.basePath}/rest/v1/nedbank-apim-payments/handle/auth`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param resourcePath 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public handleSdkCallbackFromApp(id?: string, resourcePath?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public handleSdkCallbackFromApp(id?: string, resourcePath?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public handleSdkCallbackFromApp(id?: string, resourcePath?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public handleSdkCallbackFromApp(id?: string, resourcePath?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (resourcePath !== undefined && resourcePath !== null) {
            queryParameters = queryParameters.set('resourcePath', <any>resourcePath);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<any>(`${this.basePath}/rest/v1/peachmobilesdk/handle-callback`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param resourcePath 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationMobileSdk(id?: string, resourcePath?: string, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public notificationMobileSdk(id?: string, resourcePath?: string, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public notificationMobileSdk(id?: string, resourcePath?: string, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public notificationMobileSdk(id?: string, resourcePath?: string, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (resourcePath !== undefined && resourcePath !== null) {
            queryParameters = queryParameters.set('resourcePath', <any>resourcePath);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.get<any>(`${this.basePath}/rest/v1/peachmobilesdk/notify`,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentCallback(body?: TransactionState, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public paymentCallback(body?: TransactionState, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public paymentCallback(body?: TransactionState, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public paymentCallback(body?: TransactionState, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/jg-card/callbacks/payment-results`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param groupingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPaymentJson(body?: string, groupingId?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public postPaymentJson(body?: string, groupingId?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public postPaymentJson(body?: string, groupingId?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public postPaymentJson(body?: string, groupingId?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupingId !== undefined && groupingId !== null) {
            queryParameters = queryParameters.set('groupingId', <any>groupingId);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            '*/*'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/masterpass/callbacks`,
            body,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPaymentJson_4(body?: PnpCallbackRequest, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<PnpCallbackResponse>;
    public postPaymentJson_4(body?: PnpCallbackRequest, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<PnpCallbackResponse>>;
    public postPaymentJson_4(body?: PnpCallbackRequest, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<PnpCallbackResponse>>;
    public postPaymentJson_4(body?: PnpCallbackRequest, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<PnpCallbackResponse>(`${this.basePath}/rest/v1/pnp/callback`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transfer from wallet to wallet on a cron-like schedule
     * Do a transfer from one wallet to another based on a schedule
     * @param walletId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postScheduledTransferJson(walletId: number, body?: ScheduledTransfer, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<ScheduledTransfer>;
    public postScheduledTransferJson(walletId: number, body?: ScheduledTransfer, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<ScheduledTransfer>>;
    public postScheduledTransferJson(walletId: number, body?: ScheduledTransfer, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<ScheduledTransfer>>;
    public postScheduledTransferJson(walletId: number, body?: ScheduledTransfer, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling postScheduledTransferJson.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<ScheduledTransfer>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/scheduled-transfers`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param walletId 
     * @param bundleId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBundle(walletId: number, bundleId: number, body?: Bundle, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Bundle>;
    public updateBundle(walletId: number, bundleId: number, body?: Bundle, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Bundle>>;
    public updateBundle(walletId: number, bundleId: number, body?: Bundle, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Bundle>>;
    public updateBundle(walletId: number, bundleId: number, body?: Bundle, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling updateBundle.');
        }
        if (bundleId === null || bundleId === undefined) {
            throw new Error('Required parameter bundleId was null or undefined when calling updateBundle.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<Bundle>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}/bundles/${encodeURIComponent(String(bundleId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param bundleSpecId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBundleSpec(bundleSpecId: number, body?: BundleSpec, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<BundleSpec>;
    public updateBundleSpec(bundleSpecId: number, body?: BundleSpec, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<BundleSpec>>;
    public updateBundleSpec(bundleSpecId: number, body?: BundleSpec, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<BundleSpec>>;
    public updateBundleSpec(bundleSpecId: number, body?: BundleSpec, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (bundleSpecId === null || bundleSpecId === undefined) {
            throw new Error('Required parameter bundleSpecId was null or undefined when calling updateBundleSpec.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<BundleSpec>(`${this.basePath}/rest/v1/bundle-specs/${encodeURIComponent(String(bundleSpecId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param paymentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePayment(paymentId?: number, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<any>;
    public updatePayment(paymentId?: number, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<any>>;
    public updatePayment(paymentId?: number, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<any>>;
    public updatePayment(paymentId?: number, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (paymentId !== undefined && paymentId !== null) {
            queryParameters = queryParameters.set('paymentId', <any>paymentId);
        }


    if (customQueryParams && customQueryParams.length > 0) {
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.post<any>(`${this.basePath}/rest/v1/peach-instant-eft-callbacks`,
            null,
            {
                params: queryParameters,
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the wallet account details.
     * Update the Wallet account details by providing updated wallet model.
     * @param walletId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWallet(walletId: number, body?: Wallet, customQueryParams?: string, observe?: 'body', reportProgress?: boolean, acceptContentType?: string): Observable<Wallet>;
    public updateWallet(walletId: number, body?: Wallet, customQueryParams?: string, observe?: 'response', reportProgress?: boolean, acceptContentType?: string): Observable<HttpResponse<Wallet>>;
    public updateWallet(walletId: number, body?: Wallet, customQueryParams?: string, observe?: 'events', reportProgress?: boolean, acceptContentType?: string): Observable<HttpEvent<Wallet>>;
    public updateWallet(walletId: number, body?: Wallet, customQueryParams?: string, observe: any = 'body', reportProgress: boolean = false, acceptContentType?: string): Observable<any> {
        if (walletId === null || walletId === undefined) {
            throw new Error('Required parameter walletId was null or undefined when calling updateWallet.');
        }


    if (customQueryParams && customQueryParams.length > 0) {
            let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let addParams: string[] = customQueryParams.split('&'); 
        
        addParams.forEach(kvpair => {
            let kv: string[] = kvpair.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);

        });

        if (addParams && addParams.length == 0) {
            let kv: string[] = customQueryParams.split('=');
            queryParameters = queryParameters.set(kv[0], <any>kv[1]);   
        }
    }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        var responseContentType:any = 'json' as 'json';
        if (acceptContentType) {
                headers = headers.set('Accept', acceptContentType);
                if (acceptContentType === 'application/octet-stream') {
                    responseContentType = 'blob';
                }
                if (acceptContentType === 'text/plain') {
                    responseContentType = 'text';
                }
        }
        return this.httpClient.put<Wallet>(`${this.basePath}/rest/v1/wallets/${encodeURIComponent(String(walletId))}`,
            body,
            {
                responseType: responseContentType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

 }
